import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { FilterWrapper, Title } from 'components/style'
import { getPaginationAfterChange } from 'helpers/getPaginationAfterChange'
import debounce from 'lodash/debounce'
import { IGoogleLanding } from 'models/landings.google.model'
import {
  selectGoogleLandings,
  selectGoogleLandingsCount,
  selectGoogleLandingsSearchString,
} from 'modules/googleLandings/redux/selects'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { goTo } from 'route-history'
import {
  CARDS_LIST_DEFAULT_GRID_CONFIG,
  DEFAULT_PAGINATION_FILTERS,
  PAGE_SIZE_OPTIONS,
} from 'root-constants'
import { getPageNumberById } from 'helpers/getPageNumberById'
import { getItemsByPage } from 'helpers/getItemsByPage'
import {
  fetchGoogleLandingsAction,
  resetGoogleLandingsAction,
  setGoogleLandingsSearchStringAction,
} from '../redux/actions'
import { LandingEditModal } from './LandingEditModal'
import { LandingsCard } from './LandingsCard'
import { StyledAddButton, StyledListItem } from './style'

const { Pagination, Input, Row, List, Breadcrumb } = antd

const debouncedFetchGoogleLandings = debounce((dispatch) => {
  dispatch(fetchGoogleLandingsAction())
}, 500)

interface ILandingsContentProps {
  landingsContentLink: string
}

export const LandingsContent: React.FC<ILandingsContentProps> = ({
  landingsContentLink,
}) => {
  const dispatch = useDispatch()
  const googleLandings = useSelector(selectGoogleLandings)
  const googleLandingsCount = useSelector(selectGoogleLandingsCount)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_FILTERS)
  const searchString = useSelector(selectGoogleLandingsSearchString)
  const { landingId } = useParams<{ landingId?: string }>()

  const isModalShow = useMemo(
    () => Boolean(landingId && googleLandings.length > 0),
    [landingId, googleLandings.length],
  )

  useEffect(
    () => {
      debouncedFetchGoogleLandings(dispatch)
      debouncedFetchGoogleLandings.flush()

      return () => {
        dispatch(resetGoogleLandingsAction())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (!landingId || landingId === 'new') {
      return
    }

    setPagination({
      page: getPageNumberById({
        id: landingId,
        allPagesItems: googleLandings,
        limit: pagination.limit,
      }),
      limit: pagination.limit,
    })
  }, [googleLandings, landingId, pagination.limit])

  const currentPageLandings = useMemo(
    () =>
      getItemsByPage<IGoogleLanding>({
        page: pagination.page,
        allPagesItems: googleLandings,
        limit: pagination.limit,
      }),
    [googleLandings, pagination.limit, pagination.page],
  )

  const handlePaginationChange = useCallback(
    (updatedPage, updatedLimit) => {
      setPagination(
        getPaginationAfterChange({
          updatedPage,
          updatedLimit,
          currentLimit: pagination.limit,
        }),
      )
    },
    [pagination.limit],
  )

  const handleSearchChange = useCallback(
    (e) => {
      dispatch(setGoogleLandingsSearchStringAction(e.target.value.trim() || ''))
      setPagination({
        page: 1,
        limit: pagination.limit,
      })
      debouncedFetchGoogleLandings(dispatch)
    },
    [dispatch, pagination.limit],
  )

  return (
    <>
      <Title>
        <Breadcrumb>
          <Breadcrumb.Item>Landings</Breadcrumb.Item>
          <Breadcrumb.Item>Google Play Landings</Breadcrumb.Item>
        </Breadcrumb>
      </Title>
      <FilterWrapper>
        <Input
          allowClear
          placeholder="Search by Title"
          value={searchString}
          onChange={handleSearchChange}
        />
      </FilterWrapper>
      <List
        grid={CARDS_LIST_DEFAULT_GRID_CONFIG}
        dataSource={['', ...currentPageLandings]}
        renderItem={(landing: IGoogleLanding | string) => (
          <StyledListItem>
            {landing ? (
              <LandingsCard
                landing={landing as IGoogleLanding}
                handleEdit={(id) => goTo(`${landingsContentLink}/${id}`)}
              />
            ) : (
              <StyledAddButton
                type="dashed"
                icon={<MaterialIcon type="plus" />}
                onClick={() => goTo(`${landingsContentLink}/new`)}
              >
                Add
              </StyledAddButton>
            )}
          </StyledListItem>
        )}
      />

      <Row justify="center">
        <Pagination
          showSizeChanger
          total={googleLandingsCount}
          current={pagination.page}
          pageSize={pagination.limit}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onChange={handlePaginationChange}
        />
      </Row>

      <LandingEditModal
        isShown={isModalShow}
        handleCloseModal={() => {
          goTo(landingsContentLink)
        }}
        landingId={landingId}
        resetPaginationPage={() =>
          setPagination({ page: 1, limit: pagination.limit })
        }
      />
    </>
  )
}
