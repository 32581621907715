import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { NotFound } from 'pages'
import { ContentWrapper } from 'components/ContentWrapper'
import { ApplicationsWrapper, NewApplication, Application } from './components'

export const Applications: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          path={path}
          exact
          render={() => (
            <ContentWrapper>
              <ApplicationsWrapper />
            </ContentWrapper>
          )}
        />
        <Route
          path={`${path}/new`}
          render={() => (
            <ContentWrapper>
              <NewApplication />
            </ContentWrapper>
          )}
        />
        <Route path={`${path}/:applicationId`} component={Application} />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}
