export const CATEGORIES = [
  { key: 'books', value: 'Books' },
  { key: 'business', value: 'Business' },
  { key: 'education', value: 'Education' },
  { key: 'entertainment', value: 'Entertainment' },
  { key: 'finance', value: 'Finance' },
  { key: 'fooddrink', value: 'Food & Drink' },
  { key: 'games', value: 'Games' },
  { key: 'healthfitness', value: 'Health & Fitness' },
  { key: 'lifestyle', value: 'Lifestyle' },
  { key: 'kids', value: 'Kids' },
  { key: 'magazinesnewspapers', value: 'Magazines & Newspapers' },
  { key: 'medical', value: 'Medical' },
  { key: 'music', value: 'Music' },
  { key: 'navigation', value: 'Navigation' },
  { key: 'news', value: 'News' },
  { key: 'photovideo', value: 'Photo & Video' },
  { key: 'productivity', value: 'Productivity' },
  { key: 'reference', value: 'Reference' },
  { key: 'shopping', value: 'Shopping' },
  { key: 'socialnetworking', value: 'Social Networking' },
  { key: 'sports', value: 'Sports' },
  { key: 'travel', value: 'Travel' },
  { key: 'utilities', value: 'Utilities' },
  { key: 'weather', value: 'Weather' },
]
