import { IExtendedTextOption } from 'components/ExtendedText'
import { ILandingVariants } from 'models/common.model'

export const getPreviewLinksFromVariants = ({
  variants,
  previewLink,
  isLink = true,
}: {
  variants: ILandingVariants
  previewLink: string
  isLink?: boolean
}): IExtendedTextOption[] =>
  Object.entries(variants)
    .filter(([, value]) => value)
    .map(([key, { source }]) => {
      const value = source ? `${previewLink}?utm_source=${source}` : previewLink

      return {
        key,
        value,
        ...(isLink && { href: value }),
      }
    })
