import { Color } from '@gismart/ui.library/core/constants'
import { List } from 'components/List'
import { ExperimentStatus } from 'models/experiments.model'
import styled from 'styled-components'

export const StyledList = styled(List)`
  padding: 25px;
  background-color: ${Color.WHITE};

  & .ant-list-item:first-child {
    border-bottom: none;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`

export const STATUS_BADGE = {
  [ExperimentStatus.NEW]: {
    bgColor: Color.SUCCESS,
    text: 'New',
  },
  [ExperimentStatus.PUBLISHED]: {
    bgColor: Color.PRIMARY,
    text: 'Published',
  },
  [ExperimentStatus.FINISHED]: {
    bgColor: Color.DISABLED,
    text: 'Finished',
  },
}
