import React from 'react'
import { DndProvider, DndProviderProps } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

export const Provider: React.FC<Omit<
  DndProviderProps<any, any>,
  'backend'
>> = ({ children }) => (
  <DndProvider backend={HTML5Backend}>{children}</DndProvider>
)
