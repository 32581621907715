import { IEditLandingFormValues, ILanding } from 'models/landings.model'
import {
  DEFAULT_VARIANTS,
  IS_EQUAL_PRIORITY_FIELD_NAME,
  VARIANTS_FIELD_NAME,
} from 'root-constants'

export const getLandingFormValues = (
  landing?: ILanding,
): IEditLandingFormValues => ({
  [VARIANTS_FIELD_NAME]: landing?.variants || DEFAULT_VARIANTS,
  [IS_EQUAL_PRIORITY_FIELD_NAME]: landing?.isEqualPriority || false,
})
