import { IOption } from '@gismart/ui.library/core/components/Select'
import { TFieldForTest } from 'models/experiments.model'
import { IStatistics } from 'models/experiments.statistic.model'

export enum AppPhone {
  IPHONE_5 = 'iphone5',
  IPHONE_678 = 'iphone7',
  IPHONE_678PLUS = 'iphone7x',
  IPHONE_X = 'iphoneX',
}

export enum StatisticName {
  CUSTOM = 'custom',
  GOOGLE = 'google',
}

export enum AppLanguage {
  EN = 'en-US',
  ZH = 'zh-Hans',
}

export enum ExperimentMetaField {
  EXPERIMENT_TITLE = 'experimentTitle',
  FIELDS_FOR_VARIANT_TEST = 'fieldsForVariantTest',
  PARSED_APP_LANGUAGE = 'parsedAppLanguage',
}

export enum ExperimentAppField {
  TITLE = 'title',
  SUBTITLE = 'subTitle',
  DEVELOPER_NAME = 'developerName',
  IS_OFFERS_IN_APP_PURCHASES_SHOWN = 'offersInAppPurchases',
  PRICE = 'priceAmount',
  RATING = 'rating',
  REVIEWS_AMOUNT = 'reviewCount',
  ONE_STAR_COUNT = 'oneStarsCount',
  TWO_STAR_COUNT = 'twoStarsCount',
  THREE_STAR_COUNT = 'threeStarsCount',
  FOUR_STAR_COUNT = 'fourStarsCount',
  FIVE_STAR_COUNT = 'fiveStarsCount',
  STORE_POSITION = 'storePosition',
  DESCRIPTION = 'description',
  IS_UPDATE_SHOWN = 'showWhatsNew',
  UPDATE_DESCRIPTION = 'whatsNew',
  UPDATE_DATE = 'whatsNewDate',
  UPDATE_VERSION = 'whatsNewVersion',
  IS_OFFERS_APPLE_TV_APP_SHOWN = 'offersAppleTvApp',
  IS_OFFERS_APPLE_WATCH_APP_SHOWN = 'offersAppleWatchApp',
  IS_OFFERS_IMESSAGE_APP_SHOWN = 'offersIMessageApp',
  AGE_RATING = 'ageRating',
  ICON = 'icon',
  VIDEO_PREVIEWS = 'videoPreviews',
  SCREENSHOT_PREVIEWS = 'screenshots',
}

export const APP_PHONE_FIELDS_MAP: Array<{ label: string; value: AppPhone }> = [
  { label: 'iPhone 5, 5S, SE', value: AppPhone.IPHONE_5 },
  { label: 'iPhone 6, 7, 8', value: AppPhone.IPHONE_678 },
  { label: 'iPhone 6+, 7+, 8+', value: AppPhone.IPHONE_678PLUS },
  { label: 'iPhone X, XR, XS,XS Max', value: AppPhone.IPHONE_X },
]

export const TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP: Record<
  TFieldForTest,
  string
> = {
  [ExperimentAppField.TITLE]: 'Title',
  [ExperimentAppField.SUBTITLE]: 'Subtitle',
  [ExperimentAppField.ICON]: 'Icon',
  [ExperimentAppField.SCREENSHOT_PREVIEWS]: 'Screenshots',
  [ExperimentAppField.VIDEO_PREVIEWS]: 'Video preview',
  [ExperimentAppField.DEVELOPER_NAME]: 'Developer',
  [ExperimentAppField.PRICE]: 'Price',
  [ExperimentAppField.RATING]: 'Rating',
  [ExperimentAppField.REVIEWS_AMOUNT]: 'Number of reviews',
  reviews: 'Reviews by rating',
  [ExperimentAppField.STORE_POSITION]: 'Categoty position',
  [ExperimentAppField.DESCRIPTION]: 'Description',
  updates: `What's new`,
  [ExperimentAppField.AGE_RATING]: 'Age rating',
}

export const FIELDS_FOR_TEST_OPTIONS_SELECT: IOption[] = [
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.TITLE],
    value: ExperimentAppField.TITLE,
  },
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.SUBTITLE],
    value: ExperimentAppField.SUBTITLE,
  },
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.ICON],
    value: ExperimentAppField.ICON,
  },
  {
    label:
      TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[
        ExperimentAppField.SCREENSHOT_PREVIEWS
      ],
    value: ExperimentAppField.SCREENSHOT_PREVIEWS,
  },
  {
    label:
      TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.VIDEO_PREVIEWS],
    value: ExperimentAppField.VIDEO_PREVIEWS,
  },
  {
    label:
      TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.DEVELOPER_NAME],
    value: ExperimentAppField.DEVELOPER_NAME,
  },
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.PRICE],
    value: ExperimentAppField.PRICE,
  },
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.RATING],
    value: ExperimentAppField.RATING,
  },
  {
    label:
      TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.REVIEWS_AMOUNT],
    value: ExperimentAppField.REVIEWS_AMOUNT,
  },
  { label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP.reviews, value: 'reviews' },
  {
    label:
      TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.STORE_POSITION],
    value: ExperimentAppField.STORE_POSITION,
  },
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.DESCRIPTION],
    value: ExperimentAppField.DESCRIPTION,
  },
  { label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP.updates, value: 'updates' },
  {
    label: TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[ExperimentAppField.AGE_RATING],
    value: ExperimentAppField.AGE_RATING,
  },
]

export const LANGUAGE_SELECT_OPTIONS: Array<{
  label: string
  value: AppLanguage
}> = [
  {
    label: 'US English',
    value: AppLanguage.EN,
  },
  {
    label: 'Simplified Chinese',
    value: AppLanguage.ZH,
  },
]

export const STATISTIC_DATE_FORMAT = 'DD MMM YYYY'
export const RAW_DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_STATISTICS: IStatistics = {
  uniqueIphoneUsers: {
    total: 0,
    byDate: [],
  },
  uniqueNotIphoneUsers: {
    total: 0,
    byDate: [],
  },
  iphoneInstalls: {
    total: 0,
    byDate: [],
  },
  variants: [],
}

export const STATUSES_OPTIONS: IOption[] = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'finished',
    label: 'Finished',
  },
]
