import { TFormFieldData } from '@gismart/ui.library/core/types'
import isEqual from 'lodash/isEqual'

export const getFormFieldsAfterChange = (
  changedFields: TFormFieldData[],
  fields: TFormFieldData[],
): TFormFieldData[] => {
  const changedFieldsNames = changedFields.map(
    (field) => field.name,
  ) as string[][]
  const currentFieldsWithoutChanged = fields.filter(
    (field) =>
      !changedFieldsNames.find((fieldName: string[]) =>
        isEqual(fieldName, field.name),
      ),
  )

  return [...currentFieldsWithoutChanged, ...changedFields]
}
