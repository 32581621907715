import React, { useCallback } from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { ItemWrapper, RemoveButton } from './style'

export interface IProps {
  item: any
  renderItemContent: (item: any) => React.ReactNode
  onRemove?: (item: any) => void
}

export const Item: React.FC<IProps> = ({
  item,
  renderItemContent,
  onRemove,
}) => {
  const removeClick = useCallback(
    () => (onRemove ? onRemove(item) : undefined),
    [item, onRemove],
  )

  return (
    <ItemWrapper>
      {renderItemContent(item)}
      {onRemove && (
        <RemoveButton
          type="primary"
          shape="circle"
          icon={<MaterialIcon type="delete" />}
          size="small"
          onClick={removeClick}
        />
      )}
    </ItemWrapper>
  )
}
