import styled from 'styled-components'
import { antd } from '@gismart/ui.library/core'

const { Button } = antd

export const StyledButton = styled(Button)`
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  border-radius: 0;
`
