import { TFormFieldData } from '@gismart/ui.library/core/types'
import { getVariantsIndexesWithUids } from './getVariantsIndexesWithUids'

export const getNewVariantIndex = (fields: TFormFieldData[]): number => {
  const existedIndexes: number[] = getVariantsIndexesWithUids(fields).map(
    ([index]) => index,
  )

  return existedIndexes.length ? Math.max(...existedIndexes) + 1 : 0
}
