import {
  IParsedAppFile,
  IParsedAppFileBase,
  IParsedAppVideoPreview,
  TParsedAppPreviews,
} from 'models/parser.model'
import { uid } from '@gismart/ui.library/core/services'
import { AppPhone } from '../constants'

export const getPreviewsFromRaw = (
  rawPreviews:
    | TParsedAppPreviews
    | Array<IParsedAppFile | IParsedAppVideoPreview>,
): TParsedAppPreviews => {
  if (Array.isArray(rawPreviews)) {
    return rawPreviews.reduce(
      (result, rawPreview) => {
        if (!rawPreview.id) {
          rawPreview.id = uid()
        }

        return {
          ...result,
          [rawPreview.phoneType]: [
            ...(Array.isArray(result[rawPreview.phoneType])
              ? result[rawPreview.phoneType]
              : []),
            rawPreview,
          ],
        }
      },
      {
        [AppPhone.IPHONE_5]: [],
        [AppPhone.IPHONE_678]: [],
        [AppPhone.IPHONE_678PLUS]: [],
        [AppPhone.IPHONE_X]: [],
      },
    )
  }

  return Object.entries(rawPreviews).reduce(
    (result, currentItem) => {
      const [phoneType, previews] = currentItem

      if (!previews) {
        result[phoneType] = []
        return result
      }

      const previewsWithIds = (previews as IParsedAppFileBase[]).map((item) => {
        if (!item.id) {
          item.id = uid()
        }

        if (!item.phoneType) {
          item.phoneType = phoneType as AppPhone
        }

        return item
      })

      result[phoneType] = previewsWithIds
      return result
    },
    {
      [AppPhone.IPHONE_5]: [],
      [AppPhone.IPHONE_678]: [],
      [AppPhone.IPHONE_678PLUS]: [],
      [AppPhone.IPHONE_X]: [],
    },
  )
}
