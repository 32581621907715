import React from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { StyledButton } from './style'

interface IProps {
  title?: string
  onClick?: () => void // ??? -> renderAddButton={() => <AddButton onClick={onAdd} />}
}

export const AddButton: React.FC<IProps> = ({ title = 'Add', ...props }) => (
  <StyledButton type="dashed" icon={<MaterialIcon type="plus" />} {...props}>
    {title}
  </StyledButton>
)
