import {
  ApiService,
  IResponseResult,
  IRequestOptions,
  RequestMethod,
} from '@gismart/ui.library/core/services/api'

interface IPixel {
  // eslint-disable-next-line camelcase
  pixel_id: string
}

export class PixelApi {
  protected api: ApiService
  protected path = 'pixel'

  constructor(api: ApiService) {
    this.api = api
  }

  get(): Promise<IResponseResult<IPixel>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IPixel>(this.path, options)
  }

  update(entity: IPixel): Promise<IResponseResult<unknown>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: entity,
    }

    return this.api.makeRequest<unknown>(this.path, options)
  }
}
