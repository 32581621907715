import styled from 'styled-components'
import { antd } from '@gismart/ui.library/core'

const { Button, List, Modal } = antd

export const StyledListItem = styled(List.Item)`
  height: 100%;
`

export const StyledAddButton = styled(Button)`
  width: 100%;
  height: 284px;
`

export const StyledModal = styled(Modal)`
  min-width: 400px;
`
