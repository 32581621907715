import React, { useState } from 'react'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { InputWithCopy } from 'components/InputWithCopy'

const { Modal, Button } = antd

interface IProps {
  link?: string
}

export const ExperimentLink: React.FC<IProps> = ({ link = '' }) => {
  const [isModalShown, setIsModalShown] = useState(false)

  return (
    <>
      <Button
        icon={<MaterialIcon type="link" />}
        onClick={() => setIsModalShown(true)}
      >
        Link
      </Button>
      <Modal
        visible={isModalShown}
        footer={null}
        title="Link"
        onCancel={() => setIsModalShown(false)}
      >
        <InputWithCopy value={link} />
      </Modal>
    </>
  )
}
