import React, { useState } from 'react'
import { fileApi } from 'api'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { AppPreviewFileType } from 'root-constants'
import { Uploader } from './Uploader'
import { defineOrientation } from './helpers'
import {
  UploaderWrapper,
  PreviewVideo,
  EditButton,
  ItemWrapper,
  PreviewPlaceholder,
  StyledEditButtonUpload,
  StyledAddButtonUpload,
  StyledLoadingIndicator,
} from './style'

const { getBase64File } = Uploader

interface IProps {
  withPreview?: boolean
  btnText?: string
  value: any
  onChange: (data) => void
  style?: any
  showEditButton?: boolean
}

export const VideoUploader: React.FC<IProps> = ({
  withPreview = false,
  showEditButton = false,
  btnText,
  value: file,
  onChange: onUploadFile,
  style = {},
}) => {
  const isFileLoaded = file?.url
  const [isContentLoading, setIsContentLoading] = useState(false)

  const onUpload = (action) => {
    const { file: newFile } = action
    getBase64File(newFile, (base64File) => {
      const video: any = document.createElement('video')
      setIsContentLoading(true)

      video.addEventListener('loadedmetadata', async () => {
        const orientation = defineOrientation(
          video.videoWidth,
          video.videoHeight,
        )
        const response = await fileApi.create({
          fileName: newFile.name,
          file: newFile,
          orientation,
          fileType: AppPreviewFileType.VIDEO,
        })
        if (response.success && response.data) {
          const { id, url } = response.data
          onUploadFile({
            id,
            url,
            orientation,
            fileType: AppPreviewFileType.VIDEO,
          })
          setIsContentLoading(false)
          action.onSuccess()
        } else if (!response.success) {
          console.error(response.data || response)
          setIsContentLoading(false)
          action.onError(response.data)
        }
      })

      video.preload = true
      video.src = base64File
      video.load()
    })
  }

  const editButtonRender = () => (
    <EditButton
      type="primary"
      shape="circle"
      icon={<MaterialIcon type="pencil" />}
      size="small"
      onClick={onUpload}
    />
  )

  return (
    <UploaderWrapper style={style}>
      {isFileLoaded ? (
        <ItemWrapper>
          {isContentLoading && (
            <StyledLoadingIndicator>
              <MaterialIcon type="loading" spin={3} size={2} />
            </StyledLoadingIndicator>
          )}
          {showEditButton && (
            <StyledEditButtonUpload
              onUpload={onUpload}
              btnRender={editButtonRender}
              accept="video/*"
            />
          )}
          {withPreview ? (
            <PreviewVideo
              orientation={file.orientation}
              src={file.url}
              controls
            />
          ) : (
            <PreviewPlaceholder>
              <MaterialIcon type="video" />
              <span>Video</span>
            </PreviewPlaceholder>
          )}
        </ItemWrapper>
      ) : (
        <StyledAddButtonUpload
          onUpload={onUpload}
          btnText={btnText}
          accept="video/*"
        />
      )}
    </UploaderWrapper>
  )
}
