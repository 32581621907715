import React, { useState } from 'react'
import { fileApi } from 'api'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { AppPreviewFileType } from 'root-constants'
import { Uploader } from './Uploader'
import { defineOrientation } from './helpers'
import {
  UploaderWrapper,
  PreviewImg,
  EditButton,
  ItemWrapper,
  PreviewPlaceholder,
  StyledEditButtonUpload,
  StyledAddButtonUpload,
  StyledLoadingIndicator,
} from './style'

const { getBase64File } = Uploader

interface IProps {
  withPreview?: boolean
  showEditButton?: boolean
  btnText?: string
  value?: any
  onChange?: (data) => void
  style?: any
}

export const ImgUploader: React.FC<IProps> = ({
  value: file,
  btnText,
  onChange: onUploadFile = () => undefined,
  showEditButton = false,
  style = {},
  withPreview = false,
}) => {
  const isFileLoaded = file?.url
  const [isContentLoading, setIsContentLoading] = useState(false)

  const onUpload = (action) => {
    const { file: newFile } = action
    getBase64File(newFile, (base64File) => {
      const img = new Image()
      setIsContentLoading(true)

      img.addEventListener('load', async () => {
        const orientation = defineOrientation(img.width, img.height)
        const response = await fileApi.create({
          fileName: newFile.name,
          file: newFile,
          orientation,
          fileType: AppPreviewFileType.IMAGE,
        })
        if (response.success && response.data) {
          const { id, url } = response.data
          onUploadFile({
            id,
            url,
            orientation,
            fileType: AppPreviewFileType.IMAGE,
          })
          setIsContentLoading(false)
          action.onSuccess()
        } else if (!response.success) {
          console.error(response.data || response)
          setIsContentLoading(false)
          action.onError(response.data)
        }
      })
      img.src = base64File
    })
  }

  const editButtonRender = () => (
    <EditButton
      type="primary"
      shape="circle"
      icon={<MaterialIcon type="pencil" />}
      size="small"
    />
  )

  return (
    <UploaderWrapper style={style}>
      {isFileLoaded ? (
        <ItemWrapper>
          {isContentLoading && (
            <StyledLoadingIndicator>
              <MaterialIcon type="loading" spin={3} size={2} />
            </StyledLoadingIndicator>
          )}
          {showEditButton && (
            <StyledEditButtonUpload
              onUpload={onUpload}
              btnRender={editButtonRender}
              accept="image/*"
            />
          )}
          {withPreview ? (
            <PreviewImg
              orientation={file.orientation}
              src={file.url}
              alt={file.phoneType}
              decoding="async"
            />
          ) : (
            <PreviewPlaceholder>
              <MaterialIcon type="image" />
              <span>Image</span>
            </PreviewPlaceholder>
          )}
        </ItemWrapper>
      ) : (
        <StyledAddButtonUpload
          onUpload={onUpload}
          btnText={btnText}
          accept="image/*"
        />
      )}
    </UploaderWrapper>
  )
}
