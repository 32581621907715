import { IApplication } from 'models/applications.model'
import { IRawParsedApp } from 'models/parser.model'
import { AnyAction } from 'redux'
import { TPagination } from 'types'
import {
  FETCH_APPS,
  FETCH_APP,
  RESET_APP,
  UPDATE_APP,
  SET_PARSED_APP,
  RESET_PARSED_APP,
  UPDATE_FILTERS,
  RESET_FILTERS,
} from './actions'
import { getDefaultFilters } from '../helpers'

export interface IApplicationsFilters extends TPagination {
  title?: string
  tag: string
}

export interface IApplicationState {
  list: IApplication[]
  appsCount?: number
  filters: IApplicationsFilters
  current: IApplication | null
  parsedApp: IRawParsedApp | null
}

const initState: IApplicationState = {
  list: [],
  current: null,
  parsedApp: null,
  filters: getDefaultFilters(),
}

export function applicationsReducer(
  state: IApplicationState = initState,
  { type, payload = null }: AnyAction,
): IApplicationState {
  switch (type) {
    case FETCH_APPS: {
      return { ...state, ...payload }
    }
    case FETCH_APP: {
      return { ...state, current: payload }
    }
    case RESET_APP: {
      return { ...state, current: null }
    }
    case UPDATE_APP: {
      return { ...state, list: payload }
    }
    case SET_PARSED_APP: {
      return { ...state, parsedApp: payload }
    }
    case RESET_PARSED_APP: {
      return { ...state, parsedApp: null }
    }
    case UPDATE_FILTERS: {
      return { ...state, filters: payload }
    }
    case RESET_FILTERS: {
      return { ...state, filters: getDefaultFilters() }
    }
    default:
      return state
  }
}
