import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { NotFound } from 'pages'
import { ContentWrapper } from 'components/ContentWrapper'
import { LandingsContent } from './components/LandingsContent'

export const GoogleLandings: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${path}/:landingId?`}
        render={() => (
          <ContentWrapper isBreadcrumbShown={false}>
            <LandingsContent landingsContentLink={path} />
          </ContentWrapper>
        )}
      />
      <Route component={NotFound} />
    </Switch>
  )
}
