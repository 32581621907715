import dayjs from 'dayjs'
import { uid } from '@gismart/ui.library/core/services'
import { TFormFieldData } from '@gismart/ui.library/core/types'
import { IAppVariant, TFieldForTest } from 'models/experiments.model'
import {
  IRawParsedApp,
  TParsedAppPreviews,
  TParsedAppScreenshots,
  IParsedAppFile,
  IParsedAppVideoPreview,
  TParsedAppVideoPreviews,
} from 'models/parser.model'
import { AppPhone, ExperimentAppField } from '../constants'
import { isPreviewsEmpty } from './isPreviewsEmpty'
import { getFormFieldName } from './getFormFieldName'
import { getFormFieldValue } from './getFormFieldValue'
import { isFormFieldShown } from './isFormFieldShown'

const getPreviews = ({
  fieldName,
  rawAppPreviews,
  currentFormFields,
  isVariant = false,
  variantIndex = 0,
  variantPreviews,
}: {
  fieldName:
    | ExperimentAppField.SCREENSHOT_PREVIEWS
    | ExperimentAppField.VIDEO_PREVIEWS
  currentFormFields?: TFormFieldData[]
  rawAppPreviews?:
    | TParsedAppScreenshots
    | IParsedAppFile[]
    | TParsedAppVideoPreviews
    | IParsedAppVideoPreview[]
  isVariant?: boolean
  variantIndex?: number
  variantPreviews?: TParsedAppPreviews
}): TFormFieldData[] => {
  const rawPreviews =
    (!isPreviewsEmpty(variantPreviews) && variantPreviews) || rawAppPreviews

  return Object.values(AppPhone).map((appPhone) => {
    const currentFieldName = getFormFieldName(
      [fieldName, appPhone],
      isVariant,
      variantIndex,
    )

    return {
      name: currentFieldName,
      value:
        getFormFieldValue(currentFieldName, currentFormFields) ||
        rawPreviews?.[appPhone] ||
        [],
    }
  })
}

const addFormField = ({
  result,
  fieldName,
  fieldsForVariantTest,
  currentFormFields,
  rawAppValue,
  initialValue,
  isVariant = false,
  variantIndex = 0,
  variantValue,
  isFieldShownCheckingEnabled = true,
}: {
  result: TFormFieldData[]
  fieldName: ExperimentAppField
  fieldsForVariantTest: TFieldForTest[]
  currentFormFields?: TFormFieldData[]
  rawAppValue: any
  initialValue: any
  isVariant?: boolean
  variantIndex?: number
  variantValue?: any
  isFieldShownCheckingEnabled?: boolean
}): TFormFieldData[] => {
  const isFieldShown = isFieldShownCheckingEnabled
    ? isFormFieldShown(
        fieldName as TFieldForTest,
        fieldsForVariantTest,
        isVariant,
      )
    : true

  isFieldShown &&
    result.push({
      name: getFormFieldName([fieldName], isVariant, variantIndex),
      value:
        getFormFieldValue(
          getFormFieldName([fieldName], isVariant, variantIndex),
          currentFormFields,
        ) ??
        variantValue ??
        rawAppValue ??
        initialValue,
    })

  return result
}

export const getFormFieldsForTesting = ({
  rawApp,
  fieldsForVariantTest,
  currentFormFields,
  isVariant = false,
  isNewVariant = false,
  variantIndex = 0,
  variant,
}: {
  rawApp: IRawParsedApp
  fieldsForVariantTest: TFieldForTest[]
  currentFormFields?: TFormFieldData[]
  isVariant?: boolean
  isNewVariant?: boolean
  variantIndex?: number
  variant?: IAppVariant
}): TFormFieldData[] => {
  let result: TFormFieldData[] = []

  if (isVariant) {
    result.push({
      name: getFormFieldName(['link'], isVariant, variantIndex),
      value:
        getFormFieldValue(
          getFormFieldName(['link'], isVariant, variantIndex),
          currentFormFields,
        ) ||
        variant?.link ||
        rawApp.storeLink,
    })
    result.push({
      name: getFormFieldName(['uid'], isVariant, variantIndex),
      value:
        getFormFieldValue(
          getFormFieldName(['uid'], isVariant, variantIndex),
          currentFormFields,
        ) || uid(),
    })

    !isNewVariant &&
      result.push({
        name: getFormFieldName(['id'], isVariant, variantIndex),
        value:
          variant?.id ||
          getFormFieldValue(
            getFormFieldName(['id'], isVariant, variantIndex),
            currentFormFields,
          ),
      })

    !isNewVariant &&
      rawApp.experimentId &&
      result.push({
        name: getFormFieldName(['experimentId'], isVariant, variantIndex),
        value: rawApp.experimentId,
      })
  }

  result = addFormField({
    result,
    fieldName: ExperimentAppField.TITLE,
    rawAppValue: rawApp.title,
    variantValue: variant?.[ExperimentAppField.TITLE],
    initialValue: '',
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  result = addFormField({
    result,
    fieldName: ExperimentAppField.SUBTITLE,
    rawAppValue: rawApp.subTitle,
    variantValue: variant?.[ExperimentAppField.SUBTITLE],
    initialValue: '',
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  result = addFormField({
    result,
    fieldName: ExperimentAppField.DEVELOPER_NAME,
    rawAppValue: rawApp.developerName,
    variantValue: variant?.[ExperimentAppField.DEVELOPER_NAME],
    initialValue: '',
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  if (
    isFormFieldShown(ExperimentAppField.PRICE, fieldsForVariantTest, isVariant)
  ) {
    result = addFormField({
      result,
      fieldName: ExperimentAppField.PRICE,
      rawAppValue: rawApp.priceAmount,
      variantValue: variant?.[ExperimentAppField.PRICE],
      initialValue: 0,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN,
      rawAppValue: rawApp.offersInAppPurchases,
      variantValue:
        variant?.[ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN],
      initialValue: isNewVariant,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
  }

  result = addFormField({
    result,
    fieldName: ExperimentAppField.RATING,
    rawAppValue: rawApp.rating,
    variantValue: variant?.[ExperimentAppField.RATING],
    initialValue: 0,
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  result = addFormField({
    result,
    fieldName: ExperimentAppField.REVIEWS_AMOUNT,
    rawAppValue: rawApp.reviewCount,
    variantValue: variant?.[ExperimentAppField.REVIEWS_AMOUNT],
    initialValue: 0,
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  if (isFormFieldShown('reviews', fieldsForVariantTest, isVariant)) {
    result = addFormField({
      result,
      fieldName: ExperimentAppField.ONE_STAR_COUNT,
      rawAppValue: rawApp.oneStarsCount,
      variantValue: variant?.[ExperimentAppField.ONE_STAR_COUNT],
      initialValue: 0,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.TWO_STAR_COUNT,
      rawAppValue: rawApp.twoStarsCount,
      variantValue: variant?.[ExperimentAppField.TWO_STAR_COUNT],
      initialValue: 0,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.THREE_STAR_COUNT,
      rawAppValue: rawApp.threeStarsCount,
      variantValue: variant?.[ExperimentAppField.THREE_STAR_COUNT],
      initialValue: 0,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.FOUR_STAR_COUNT,
      rawAppValue: rawApp.fourStarsCount,
      variantValue: variant?.[ExperimentAppField.FOUR_STAR_COUNT],
      initialValue: 0,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.FIVE_STAR_COUNT,
      rawAppValue: rawApp.fiveStarsCount,
      variantValue: variant?.[ExperimentAppField.FIVE_STAR_COUNT],
      initialValue: 0,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
  }

  result = addFormField({
    result,
    fieldName: ExperimentAppField.STORE_POSITION,
    rawAppValue: rawApp.storePosition,
    variantValue: variant?.[ExperimentAppField.STORE_POSITION],
    initialValue: 0,
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  result = addFormField({
    result,
    fieldName: ExperimentAppField.DESCRIPTION,
    rawAppValue: rawApp.description,
    variantValue: variant?.[ExperimentAppField.DESCRIPTION],
    initialValue: '',
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  if (isFormFieldShown('updates', fieldsForVariantTest, isVariant)) {
    result = addFormField({
      result,
      fieldName: ExperimentAppField.IS_UPDATE_SHOWN,
      rawAppValue: rawApp.showWhatsNew,
      variantValue: variant?.[ExperimentAppField.IS_UPDATE_SHOWN],
      initialValue: false,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.UPDATE_DESCRIPTION,
      rawAppValue: rawApp.whatsNew,
      variantValue: variant?.[ExperimentAppField.UPDATE_DESCRIPTION],
      initialValue: '',
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result.push({
      name: getFormFieldName(
        [ExperimentAppField.UPDATE_DATE],
        isVariant,
        variantIndex,
      ),
      value:
        getFormFieldValue(
          getFormFieldName(
            [ExperimentAppField.UPDATE_DATE],
            isVariant,
            variantIndex,
          ),
          currentFormFields,
        ) || dayjs(variant?.whatsNewDate || rawApp.whatsNewDate),
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.UPDATE_VERSION,
      rawAppValue: rawApp.whatsNewVersion,
      variantValue: variant?.[ExperimentAppField.UPDATE_VERSION],
      initialValue: '',
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN,
      rawAppValue: rawApp.offersAppleTvApp,
      variantValue: variant?.[ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN],
      initialValue: false,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN,
      rawAppValue: rawApp.offersAppleWatchApp,
      variantValue:
        variant?.[ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN],
      initialValue: false,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
    result = addFormField({
      result,
      fieldName: ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN,
      rawAppValue: rawApp.offersIMessageApp,
      variantValue: variant?.[ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN],
      initialValue: false,
      fieldsForVariantTest,
      currentFormFields,
      isVariant,
      variantIndex,
      isFieldShownCheckingEnabled: false,
    })
  }

  result = addFormField({
    result,
    fieldName: ExperimentAppField.AGE_RATING,
    rawAppValue: rawApp.ageRating,
    variantValue: variant?.[ExperimentAppField.AGE_RATING],
    initialValue: '',
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  result = addFormField({
    result,
    fieldName: ExperimentAppField.ICON,
    rawAppValue: rawApp.icon,
    variantValue: variant?.[ExperimentAppField.ICON],
    initialValue: null,
    fieldsForVariantTest,
    currentFormFields,
    isVariant,
    variantIndex,
  })

  isFormFieldShown(
    ExperimentAppField.SCREENSHOT_PREVIEWS,
    fieldsForVariantTest,
    isVariant,
  ) &&
    result.push(
      ...getPreviews({
        fieldName: ExperimentAppField.SCREENSHOT_PREVIEWS,
        rawAppPreviews: rawApp?.screenshots,
        currentFormFields,
        isVariant,
        variantIndex,
        variantPreviews: variant?.[ExperimentAppField.SCREENSHOT_PREVIEWS],
      }),
    )

  isFormFieldShown(
    ExperimentAppField.VIDEO_PREVIEWS,
    fieldsForVariantTest,
    isVariant,
  ) &&
    result.push(
      ...getPreviews({
        fieldName: ExperimentAppField.VIDEO_PREVIEWS,
        rawAppPreviews: rawApp?.videoPreviews,
        currentFormFields,
        isVariant,
        variantIndex,
        variantPreviews: variant?.[ExperimentAppField.VIDEO_PREVIEWS],
      }),
    )

  return result
}
