import React from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { IRawExperimentsDataItem } from 'models/experiments.model'
import { PostTag } from 'components/PostTag'
import { List } from 'components/List'
import { getTitleNamesFromRawFieldsForTest } from 'modules/experiments/helpers/getTitleNamesFromRawFieldsForTest'
import { Description, STATUS_BADGE } from './style'

type TProps = {
  experiment: IRawExperimentsDataItem
}
export const ExperimentListItem: React.FC<TProps> = ({
  experiment: { title, variantsCount, tests = [], status },
}) => {
  const testsString = getTitleNamesFromRawFieldsForTest(tests).join(', ')
  const { bgColor, text } = STATUS_BADGE[status]

  return (
    <div style={{ flexGrow: 1 }}>
      <List.Item.Meta
        title={
          <div>
            <span>{title}</span>
            <PostTag bgColor={bgColor}>{text}</PostTag>
          </div>
        }
        description={
          <Description>
            <div>
              <span>{`Tests: ${testsString}`}</span>
              <MaterialIcon type="circle-small" />
              <span>{`Variants: ${variantsCount || 'N/A'}`}</span>
            </div>
          </Description>
        }
      />
    </div>
  )
}
