import { MaterialIcon } from '@gismart/ui.library/core/components'
import { Color } from '@gismart/ui.library/core/constants'
import React, { useMemo } from 'react'
import { StyledCopyableLink, StyledCopyableText } from './style'

type TProps = {
  isCopyable?: boolean
  href?: string
}

export const CopyableText: React.FC<TProps> = ({
  children,
  isCopyable = true,
  href = '',
}) => {
  const copyable = useMemo(
    () =>
      isCopyable && {
        icon: [
          <MaterialIcon type="content-copy" color={Color.PRIMARY} />,
          <MaterialIcon type="check" color={Color.SUCCESS} />,
        ],
        tooltips: ['Copy', 'Copied'],
      },
    [isCopyable],
  )

  return href ? (
    <StyledCopyableLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      copyable={copyable}
      ellipsis
    >
      {children || href}
    </StyledCopyableLink>
  ) : (
    <StyledCopyableText copyable={copyable} ellipsis={{ tooltip: true }}>
      {children}
    </StyledCopyableText>
  )
}
