import React, { useCallback } from 'react'
import { TFormFieldData, TFormInstance } from '@gismart/ui.library/core/types'
import { TFieldForTest } from 'models/experiments.model'
import { scrollToFirstErrorFormField } from 'modules/experiments/helpers/scrollToFirstErrorFormField'
import { EditingAppFields } from './EditingAppFields'
import { StepControls } from './StepControls'

interface IProps {
  form: TFormInstance
  formStateFields: TFormFieldData[]
  fieldsForVariantTest: TFieldForTest[]
  onContinue: () => void
  onCancel: () => void
}

export const SecondStep: React.FC<IProps> = ({
  form,
  formStateFields,
  fieldsForVariantTest,
  onContinue,
  onCancel,
}) => {
  const handleContinue = useCallback(async () => {
    try {
      await form.validateFields()
      onContinue()
    } catch (e: any) {
      if (e.errorFields) {
        scrollToFirstErrorFormField(e.errorFields)
      }
    }
  }, [form, onContinue])

  return (
    <>
      <EditingAppFields
        fieldsForVariantTest={fieldsForVariantTest}
        formStateFields={formStateFields}
      />
      <StepControls onBack={onCancel} onContinue={handleContinue} />
    </>
  )
}
