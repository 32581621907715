import { ILandingVariant, ILandingVariants } from 'models/common.model'
import { TPagination } from 'types'

export const APP_NAME = 'Gismetrics'

export enum PermissionAction {
  CREATE = 'C',
  READ = 'R',
  UPDATE = 'U',
  DELETE = 'D',
}

export const ALL_PERMISSION_ACTIONS = [
  PermissionAction.CREATE,
  PermissionAction.READ,
  PermissionAction.UPDATE,
  PermissionAction.DELETE,
]

export const RESOURCES = {
  example: 'ui:example',
}

export const LOCAL_STORAGE_TAG_NAME = 'applications.tag'

export const DEFAULT_PAGINATION_FILTERS: TPagination = {
  page: 1,
  limit: 50,
}

export const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100']

export enum AppImageOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
  SQUARE = 'square',
  UNKNOWN = 'unknown',
}

export enum AppPreviewFileType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export const DEFAULT_VARIANT: ILandingVariant = {
  pixelId: '',
  oneLink: '',
  source: '',
}

export const DEFAULT_VARIANTS: ILandingVariants = {
  fb: DEFAULT_VARIANT,
  snap: null,
  tiktok: null,
}

export const VARIANTS_FIELD_NAME = 'variants'
export const IS_EQUAL_PRIORITY_FIELD_NAME = 'isEqualPriority'

export const CARDS_LIST_DEFAULT_GRID_CONFIG = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 3,
}
