import { useState, useEffect, useCallback, SetStateAction } from 'react'

/**
 * Change state function
 * @name IChangeFunc
 * @function
 * @param uploadedMedia
 */

/**
 * Hook for saving uploaded media
 * ! NEED TO USE FOR FIX BUG RELATED TO VALUES IN CLOSURE ALONG TIME OF LOADING MEDIA
 * @param {IChangeFunc} changeFunc
 */
export function useUploadMedia(
  changeFunc: (data: any) => void,
): SetStateAction<any> {
  const [uploadedMedia, setUploadedMedia] = useState(null)

  useEffect(() => {
    if (uploadedMedia) {
      changeFunc(uploadedMedia)
      setUploadedMedia(null)
    }
  }, [uploadedMedia, changeFunc])

  return useCallback((image) => {
    setUploadedMedia(image)
  }, [])
}
