import { IRawParsedApp } from 'models/parser.model'
import { IAppState } from 'models/store.models'
import { IApplication } from 'models/applications.model'
import { IApplicationsFilters } from './reducer'

export const selectAppCurrentId = (state: IAppState): number =>
  state.applications.current?.id || 0
export const selectAppCurrentStoreLink = (state: IAppState): string =>
  state.applications.current?.storeLink || ''
export const selectAppCurrentTitle = (state: IAppState): string =>
  state.applications.current?.title || ''
export const selectCurrentApp = (state: IAppState): IApplication | null =>
  state.applications.current
export const selectParsedApp = (state: IAppState): IRawParsedApp | null =>
  state.applications.parsedApp
export const selectParsedAppFeaturedBanner = (
  state: IAppState,
): string | null => state.applications.parsedApp?.featuredBanner || null

export const selectAppsCount = (state: IAppState): number =>
  state.applications.appsCount || 0
export const selectApplications = (state: IAppState): IApplication[] =>
  state.applications.list

export const selectApplicationsFilters = (
  state: IAppState,
): IApplicationsFilters => state.applications.filters
