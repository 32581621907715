import React, { useState, useCallback } from 'react'
import { Select } from '@gismart/ui.library/core/components'
import { antd } from '@gismart/ui.library/core'
import { TAGS_OPTIONS_EXTENDED } from '../constants'
import { isTagSaved, saveTag, removeTag } from '../helpers'
import { IApplicationsFilters } from '../redux/reducer'

const { Checkbox, Col, Row, Input } = antd

interface IProps {
  filters: IApplicationsFilters
  onFiltersChange: (data: Partial<IApplicationsFilters>) => void
}

export const ApplicationsFilter: React.FC<IProps> = ({
  filters,
  onFiltersChange,
}) => {
  const [isSaved, setIsSaved] = useState(isTagSaved())

  const isTagsSavedChangeHandler = useCallback(
    (value) => {
      setIsSaved(value)

      value && filters.tag ? saveTag(filters.tag) : removeTag()
    },
    [filters],
  )

  const tagChangeHandler = useCallback(
    (tag) => {
      onFiltersChange({ tag })

      isSaved && saveTag(tag)
    },
    [isSaved, onFiltersChange],
  )

  const titleChangeHandler = useCallback(
    (title) => {
      onFiltersChange({ title })
    },
    [onFiltersChange],
  )

  return (
    <>
      <Row gutter={24}>
        <Col span={6}>
          <Select
            onSelectChange={tagChangeHandler}
            value={filters.tag}
            options={TAGS_OPTIONS_EXTENDED}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={18}>
          <Input
            placeholder="Search by app name"
            value={filters.title}
            onChange={({ target: { value } }) => titleChangeHandler(value)}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '12px' }}>
          <Checkbox
            checked={isSaved}
            onChange={({ target: { checked } }) =>
              isTagsSavedChangeHandler(checked)
            }
          >
            Save tag
          </Checkbox>
        </Col>
      </Row>
    </>
  )
}
