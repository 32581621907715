import React from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { Item } from './style'

interface IProps {
  noDelimetr: boolean
}

export const InfoItem: React.FC<IProps> = ({ children, noDelimetr }) => (
  <Item>
    {children}
    {!noDelimetr && <MaterialIcon type="circle-small" />}
  </Item>
)
