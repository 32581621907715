import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import {
  selectExperimentCustomStatistic,
  selectExperimentInitialData,
} from 'modules/experiments/redux/selects'
import { ExperimentStatisticComponent } from './Component'
import {
  fetchStatisticAction,
  resetCustomStatisticAction,
  resetInitialDataAction,
} from '../../redux/actions'

const mapStateToProps = (state: IAppState) => ({
  initialData: selectExperimentInitialData(state),
  statistic: selectExperimentCustomStatistic(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  fetchStatistic: (id: number) => dispatch(fetchStatisticAction(id)),
  resetStatistic: () => dispatch(resetCustomStatisticAction()),
  resetInitialData: () => dispatch(resetInitialDataAction()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const ExperimentStatistic = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExperimentStatisticComponent)
