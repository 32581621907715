import { AnyAction } from 'redux'
import { ICustomLanding } from 'models/landings.custom.model'
import { RESET_LANDINGS, SET_LANDINGS, SET_SEARCH_STRING } from './actions'

export interface ICustomLandingsState {
  landings: ICustomLanding[]
  landingsCount: number
  searchString: string
}

const initState: ICustomLandingsState = {
  landings: [],
  landingsCount: 0,
  searchString: '',
}

export function customLandingsReducer(
  state: ICustomLandingsState = initState,
  { type, payload = null }: AnyAction,
): ICustomLandingsState {
  switch (type) {
    case SET_LANDINGS: {
      return {
        ...state,
        landings: payload.landings,
        landingsCount: payload.landingsCount,
      }
    }
    case RESET_LANDINGS: {
      return {
        ...state,
        landings: [],
        landingsCount: 0,
        searchString: '',
      }
    }

    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload,
      }
    }
    default:
      return state
  }
}
