import { IParsedAppPayload, IRawParsedApp } from 'models/parser.model'
import {
  ApiService,
  IRequestOptions,
  IResponseResult,
  RequestMethod,
} from '@gismart/ui.library/core/services/api'

export class ParserApi {
  protected api: ApiService
  protected parserPath = 'app_snapshots/parse'

  constructor(api: ApiService) {
    this.api = api
  }

  parseApp(
    payload: IParsedAppPayload,
  ): Promise<IResponseResult<IRawParsedApp>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: payload,
    }

    return this.api.makeRequest<IRawParsedApp>(this.parserPath, options)
  }
}
