import { AnyAction } from 'redux'
import { IGoogleLanding } from 'models/landings.google.model'
import {
  RESET_LANDINGS,
  SET_LANDINGS,
  SET_SEARCH_STRING,
} from 'modules/googleLandings/redux/actions'

export interface IGoogleLandingsState {
  landings: IGoogleLanding[]
  landingsCount: number
  searchString: string
}

const initState: IGoogleLandingsState = {
  landings: [],
  landingsCount: 0,
  searchString: '',
}

export function googleLandingsReducer(
  state: IGoogleLandingsState = initState,
  { type, payload = null }: AnyAction,
): IGoogleLandingsState {
  switch (type) {
    case SET_LANDINGS: {
      return {
        ...state,
        landings: payload.landings,
        landingsCount: payload.landingsCount,
      }
    }
    case RESET_LANDINGS: {
      return {
        ...state,
        landings: [],
        landingsCount: 0,
        searchString: '',
      }
    }
    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload,
      }
    }
    default:
      return state
  }
}
