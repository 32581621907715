import styled from 'styled-components'

type TPostTag = {
  color?: string
  bgColor?: string
}

export const PostTag = styled.span<TPostTag>`
  display: inline-block;
  height: 20px;
  line-height: 20px;
  position: relative;
  font-size: 14px;
  margin-left: 20px;
  padding: 0 10px 0 12px;
  text-decoration: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: ${(props) => props.color || '#fff'};
  background: ${(props) => props.bgColor || '#0089e0'};

  &:before {
    content: '';
    float: left;
    position: absolute;
    top: 0;
    left: -10px;
    width: 0;
    height: 0;
    border-color: transparent ${(props) => props.bgColor || '#0089e0'}
      transparent transparent;
    border-style: solid;
    border-width: 10px 10px 10px 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    float: left;
    width: 4px;
    height: 4px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: ${(props) => props.color || '#fff'};
    -moz-box-shadow: -1px -1px 2px #004977;
    -webkit-box-shadow: -1px -1px 2px #004977;
    box-shadow: -1px -1px 2px #004977;
  }
`
