import styled from 'styled-components'
import { Color } from '@gismart/ui.library/core/constants'
import { Card } from 'components/Card'

export const StyledCard = styled(Card)`
  background:red .ant-card-body {
    width: 100%;
    background: red;
  }
`

export const ComponentWrapper = styled('div')`
  padding: 25px;
  background-color: ${Color.WHITE};
`
