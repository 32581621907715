import { IOption } from 'models/common.model'

export const DEFAULT_TAG_KEY = 'All'
export const TAGS_OPTIONS: IOption[] = [
  {
    key: 'Discovery',
    value: 'Discovery',
  },
  {
    key: 'ASO',
    value: 'ASO',
  },
]
export const TAGS_OPTIONS_EXTENDED: IOption[] = [
  ...TAGS_OPTIONS,
  {
    key: DEFAULT_TAG_KEY,
    value: 'All',
  },
]
