import { antd } from '@gismart/ui.library/core'
import {
  ICustomLanding,
  ICustomLandingFormValues,
} from 'models/landings.custom.model'
import {
  DEFAULT_VARIANTS,
  IS_EQUAL_PRIORITY_FIELD_NAME,
  VARIANTS_FIELD_NAME,
} from 'root-constants'
import {
  BODY_CONTENT_FIELD_NAME,
  HEAD_CONTENT_FIELD_NAME,
  TITLE_FIELD_NAME,
  UploaderFileExtension,
} from './constants'

const { message } = antd

export const getCustomLandingFormValues = (
  landing?: ICustomLanding,
): ICustomLandingFormValues => ({
  [TITLE_FIELD_NAME]: landing?.title || '',
  [VARIANTS_FIELD_NAME]: landing?.variants || DEFAULT_VARIANTS,
  [BODY_CONTENT_FIELD_NAME]: '',
  [HEAD_CONTENT_FIELD_NAME]: '',
  [IS_EQUAL_PRIORITY_FIELD_NAME]: landing?.isEqualPriority || false,
})

export function validateFileExtension(
  file: File,
  fileExtensions: UploaderFileExtension[],
): boolean {
  if (!fileExtensions.length) {
    return true
  }

  const fileType = (file.type ||
    file.name.match(/(\.\w+)$/)?.[1]) as UploaderFileExtension
  const isFileExtensionValid = fileExtensions.includes(fileType)

  if (isFileExtensionValid) {
    return true
  }

  message.error('Wrong type of file!')
  return false
}
