import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { TAppDispatchThunk } from 'models/store.models'
import { IApplication } from 'models/applications.model'
import { antd } from '@gismart/ui.library/core'
import { updateAppAction } from 'modules/applications/redux/actions'
import isEqual from 'lodash/isEqual'
import { TAGS_OPTIONS } from '../constants'

const { Form, Modal, Select } = antd

type TProps = TDispatchProps & {
  application: IApplication | null
  isVisible: boolean
  closeModal: () => void
}

const EditTagModalComponent: React.FC<TProps> = ({
  application,
  isVisible,
  closeModal,
  updateApp,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({ tag: application?.tags[0] || '' })
  }, [application, form])

  const handleOk = useCallback(() => {
    if (application) {
      const tags = [form.getFieldValue('tag')]

      if (!isEqual(tags, application.tags)) {
        updateApp({
          ...application,
          tags,
        })
      }

      closeModal()
      form.resetFields()
    }
  }, [application, closeModal, form, updateApp])

  const handleCancel = useCallback(() => {
    closeModal()
    form.resetFields()
  }, [closeModal, form])

  return (
    <Modal
      title="Edit tag"
      okText="Update"
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isVisible}
    >
      <Form name="edit-tag-form" form={form}>
        <Form.Item label="Tag" name="tag" style={{ marginBottom: 0 }}>
          <Select options={TAGS_OPTIONS} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  updateApp: (data: IApplication) => dispatch(updateAppAction(data)),
})

type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const EditTagModal = connect(
  null,
  mapDispatchToProps,
)(EditTagModalComponent)
