import { ImgHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Color } from '@gismart/ui.library/core/constants'
import { antd } from '@gismart/ui.library/core'
import { AppImageOrientation } from 'root-constants'

const { Form, Collapse } = antd

export const FormItemReviews = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

interface IPreviewImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  orientation: AppImageOrientation
}

export const PreviewImage = styled.img<IPreviewImageProps>`
  width: 150px;
  height: auto;

  ${(props) =>
    props.orientation === AppImageOrientation.PORTRAIT &&
    css`
      width: auto;
      height: 250px;
    `}
`

export const PreviewMediaTitle = styled.span`
  line-height: 32px;
`

export const StyledCollapsePanel = styled(Collapse.Panel)`
  border-bottom: 0 !important;
  margin-bottom: 24px;
  border-radius: 0;
  background-color: ${Color.BACKGROUND_GRAY};
`

export const StyledStepControls = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  background-color: ${Color.WHITE};
  border-top: 1px solid ${Color.BORDER_BASE};
`
