import React, { useCallback } from 'react'
import { SortableGroup } from 'components/SortableGroup'
import { ImgUploader } from 'components/Uploader'
import { useUploadMedia } from 'hooks/useUploadMedia'
import { IParsedAppFile } from 'models/parser.model'
import { AppPhone } from '../../constants'

const MAX_SCREENSHOT_NUMBER = 10

interface IPhotoPreviewGroupProps {
  phoneType: AppPhone
  value?: IParsedAppFile[]
  onChange?: (value: IParsedAppFile[]) => void
}

interface IPhotoItemProps {
  phoneType: AppPhone
  item: IParsedAppFile
  onChange: (value: {
    uploadedImage: IParsedAppFile
    replacedImage: IParsedAppFile
  }) => void
}

const PhotoItem: React.FC<IPhotoItemProps> = ({
  item,
  onChange,
  phoneType,
}) => {
  const saveUploadedImage = useUploadMedia((uploadedImage) => {
    onChange({
      uploadedImage: { ...uploadedImage, phoneType },
      replacedImage: item,
    })
  })

  return (
    <ImgUploader
      withPreview
      showEditButton
      value={item}
      onChange={saveUploadedImage}
    />
  )
}

export const PhotoPreviewGroup: React.FC<IPhotoPreviewGroupProps> = ({
  onChange = () => undefined,
  value = [],
  phoneType,
}) => {
  const saveUploadedImage = useUploadMedia((uploadedImage) =>
    onChange([...value, { ...uploadedImage, phoneType }]),
  )

  const updateItem = useCallback(
    ({ uploadedImage, replacedImage }) =>
      onChange(
        value.map((item) => {
          if (item.id === replacedImage.id) {
            return uploadedImage
          }
          return item
        }),
      ),
    [onChange, value],
  )

  return (
    <SortableGroup
      key={phoneType}
      withoutContext
      items={value || []}
      onMove={onChange}
      onRemove={onChange}
      type="image"
      renderItemContent={(item) => (
        <PhotoItem
          item={item}
          onChange={(uploadResult) => updateItem(uploadResult)}
          phoneType={phoneType}
        />
      )}
      addComponent={
        value.length < MAX_SCREENSHOT_NUMBER && (
          <ImgUploader onChange={saveUploadedImage} />
        )
      }
    />
  )
}
