import { isRightAppLink } from './isRightAppLink'

export const validateAppLink = (error?: string): any => ({
  validator: (_, value) => {
    if (error) {
      return Promise.reject(error)
    }

    if (!value) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Please enter link to application!')
    }

    if (!isRightAppLink(value)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Link is invalid!')
    }

    return Promise.resolve()
  },
})
