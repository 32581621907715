import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { routeHistory } from 'route-history'

import { authReducer } from 'root-redux/reducers/auth'
import { commonReducer } from 'root-redux/reducers/common'
import { serviceReducer } from 'root-redux/reducers/service'
import { applicationsReducer } from 'modules/applications/redux/reducer'
import { experimentsReducer } from 'modules/experiments/redux/reducer'
import { landingsReducer } from 'modules/landings/redux/reducer'
import { googleLandingsReducer } from 'modules/googleLandings/redux/reducer'
import { customLandingsReducer } from 'modules/customLandings/redux/reducer'

import { IAppState } from 'models/store.models'

export const rootReducer = combineReducers<IAppState>({
  router: connectRouter(routeHistory),
  common: commonReducer,
  auth: authReducer,
  applications: applicationsReducer,
  experiments: experimentsReducer,
  service: serviceReducer,
  landings: landingsReducer,
  googleLandings: googleLandingsReducer,
  customLandings: customLandingsReducer,
})
