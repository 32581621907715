import { IUser } from 'models/user.model'
import {
  ApiService,
  RequestMethod,
  IResponseResult,
  IRequestOptions,
} from '@gismart/ui.library/core/services/api'

export class AuthApi {
  protected api: ApiService
  protected mePath = 'auth/me'
  protected loginPath = 'auth/login'
  protected logoutPath = 'auth/logout'

  constructor(api: ApiService) {
    this.api = api
  }

  me(): Promise<IResponseResult<IUser>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IUser>(this.mePath, options)
  }

  login(entity: { token: string }): Promise<IResponseResult<IUser>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: entity,
    }

    return this.api.makeRequest<IUser>(this.loginPath, options)
  }

  logout(): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
    }

    return this.api.makeRequest<never>(this.logoutPath, options)
  }
}
