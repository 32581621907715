import compose from 'lodash/fp/compose'
import isNil from 'lodash/isNil'
import { TFormFieldData } from '@gismart/ui.library/core/types'
import dayjs, { Dayjs } from 'dayjs'
import {
  IAppVariant,
  IChangeExperimentPayload,
  TFieldForTest,
  TRawFieldForTest,
} from 'models/experiments.model'
import { IRawParsedApp, TParsedAppPreviews } from 'models/parser.model'
import {
  AppPhone,
  ExperimentAppField,
  ExperimentMetaField,
  RAW_DATE_FORMAT,
} from '../constants'
import { getFormFieldValue } from './getFormFieldValue'
import { getFormFieldName } from './getFormFieldName'
import { isVariantField } from './isVariantField'

const getRawTestFieldsFromForm = (
  fieldsNames: TFieldForTest[],
): TRawFieldForTest[] =>
  fieldsNames
    .map((fieldName): TRawFieldForTest | TRawFieldForTest[] => {
      switch (fieldName) {
        case ExperimentAppField.TITLE:
          return ExperimentAppField.TITLE
        case ExperimentAppField.SUBTITLE:
          return ExperimentAppField.SUBTITLE
        case ExperimentAppField.ICON:
          return ExperimentAppField.ICON
        case ExperimentAppField.SCREENSHOT_PREVIEWS:
          return 'screenshot'
        case ExperimentAppField.VIDEO_PREVIEWS:
          return 'app-preview'
        case ExperimentAppField.DEVELOPER_NAME:
          return ExperimentAppField.DEVELOPER_NAME
        case ExperimentAppField.PRICE:
          return [
            ExperimentAppField.PRICE,
            ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN,
          ]
        case ExperimentAppField.RATING:
          return ExperimentAppField.RATING
        case ExperimentAppField.REVIEWS_AMOUNT:
          return ExperimentAppField.REVIEWS_AMOUNT
        case 'reviews':
          return [
            ExperimentAppField.ONE_STAR_COUNT,
            ExperimentAppField.TWO_STAR_COUNT,
            ExperimentAppField.THREE_STAR_COUNT,
            ExperimentAppField.FOUR_STAR_COUNT,
            ExperimentAppField.FIVE_STAR_COUNT,
          ]
        case ExperimentAppField.STORE_POSITION:
          return ExperimentAppField.STORE_POSITION
        case ExperimentAppField.DESCRIPTION:
          return ExperimentAppField.DESCRIPTION
        case 'updates':
          return [
            ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN,
            ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN,
            ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN,
            ExperimentAppField.IS_UPDATE_SHOWN,
            ExperimentAppField.UPDATE_DESCRIPTION,
            ExperimentAppField.UPDATE_VERSION,
            ExperimentAppField.UPDATE_DATE,
          ]
        case ExperimentAppField.AGE_RATING:
          return ExperimentAppField.AGE_RATING
        default:
          throw Error
      }
    })
    .flat()

const getFormattedDateFieldValue = (value: any | Dayjs): any | string =>
  dayjs.isDayjs(value) ? value.format(RAW_DATE_FORMAT) : value

const areVideoPreviewsNotEmpty = (
  formFields: TFormFieldData[],
  isVariant = false,
  variantIndex = 0,
): boolean =>
  Object.values(AppPhone).some(
    (appPhone) =>
      !!getFormFieldValue(
        getFormFieldName(
          [ExperimentAppField.VIDEO_PREVIEWS, appPhone],
          isVariant,
          variantIndex,
        ),
        formFields,
      )?.length,
  )

const getRawVariantsFromForm = (formFields: TFormFieldData[]): IAppVariant[] =>
  formFields
    .reduce((result, field) => {
      const isVariant = isVariantField(field.name[0])
      const variantFieldName = field.name[1]

      if (!isVariant || variantFieldName === 'uid') return result

      const variantIndex = parseInt(field.name[0], 10)
      const value = getFormattedDateFieldValue(field.value)
      const isVideoPreviewsNeededToTest = getFormFieldValue(
        [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
        formFields,
      ).includes(ExperimentAppField.VIDEO_PREVIEWS)
      const isVideoPreviewFieldNeededToAdd =
        result[variantIndex]?.videoPreview === undefined &&
        isVideoPreviewsNeededToTest

      if (isVideoPreviewFieldNeededToAdd) {
        result[variantIndex] = {
          ...result[variantIndex],
          videoPreview: areVideoPreviewsNotEmpty(
            formFields,
            true,
            variantIndex,
          ),
        }
      }

      if (
        variantFieldName === ExperimentAppField.SCREENSHOT_PREVIEWS ||
        variantFieldName === ExperimentAppField.VIDEO_PREVIEWS
      ) {
        if (!result[variantIndex]?.[variantFieldName]) {
          result[variantIndex] = {
            ...result[variantIndex],
            [variantFieldName]: {
              [AppPhone.IPHONE_5]: [],
              [AppPhone.IPHONE_678]: [],
              [AppPhone.IPHONE_678PLUS]: [],
              [AppPhone.IPHONE_X]: [],
            },
          }
        }

        const appPhone = field.name[2]

        result[variantIndex] = {
          ...result[variantIndex],
          [variantFieldName]: {
            ...result[variantIndex][variantFieldName],
            [appPhone]: [
              ...result[variantIndex][variantFieldName]?.[appPhone],
              ...value,
            ].filter((item) => item.url || item.video?.url),
          },
        }

        return result
      }

      result[variantIndex] = {
        ...result[variantIndex],
        [variantFieldName]: value,
      }

      return result
    }, [] as IAppVariant[])
    .filter((variant) => variant)

const getRawPreviewsFromForm = (
  previewFieldName:
    | ExperimentAppField.SCREENSHOT_PREVIEWS
    | ExperimentAppField.VIDEO_PREVIEWS,
  formFields: TFormFieldData[],
): TParsedAppPreviews => {
  const previews = formFields.filter(({ name }) => name[0] === previewFieldName)

  return Object.values(AppPhone).reduce(
    (result, appPhone) => {
      const value =
        previews.find(({ name }) => name[1] === appPhone)?.value || []

      const valueWithValidItems = value.filter(
        (item) => item.url || item.video?.url,
      )

      return {
        ...result,
        [appPhone]: valueWithValidItems,
      }
    },
    {
      [AppPhone.IPHONE_5]: [],
      [AppPhone.IPHONE_678]: [],
      [AppPhone.IPHONE_678PLUS]: [],
      [AppPhone.IPHONE_X]: [],
    } as TParsedAppPreviews,
  )
}

const isPreviewsTestField = (
  fields: TFormFieldData[],
  previewFieldName: ExperimentAppField,
) =>
  !!fields
    .find(({ name }) => name[0] === ExperimentMetaField.FIELDS_FOR_VARIANT_TEST)
    ?.value?.includes(previewFieldName)

const getAppValuesFromFormWithoutPreviews = (formFields: TFormFieldData[]) =>
  Object.values(ExperimentAppField).reduce(
    (
      resultWithoutPreviews: Record<ExperimentAppField, any>,
      fieldName: ExperimentAppField,
    ) => {
      const fieldValue = getFormFieldValue([fieldName], formFields)

      return isNil(fieldValue)
        ? resultWithoutPreviews
        : {
            ...resultWithoutPreviews,
            [fieldName]: fieldValue,
          }
    },
    {} as Record<ExperimentAppField, any>,
  )

const getAppValuesFromForm = (
  initialDataApp: IRawParsedApp,
  formFields: TFormFieldData[],
): Record<ExperimentAppField, any> => {
  let result = getAppValuesFromFormWithoutPreviews(formFields)

  const isVideoPreviewsTestField = isPreviewsTestField(
    formFields,
    ExperimentAppField.VIDEO_PREVIEWS,
  )
  const isScreenshotPreviewsTestField = isPreviewsTestField(
    formFields,
    ExperimentAppField.SCREENSHOT_PREVIEWS,
  )

  result = {
    ...result,
    [ExperimentAppField.VIDEO_PREVIEWS]: isVideoPreviewsTestField
      ? initialDataApp[ExperimentAppField.VIDEO_PREVIEWS]
      : getRawPreviewsFromForm(ExperimentAppField.VIDEO_PREVIEWS, formFields),
  }

  result = {
    ...result,
    [ExperimentAppField.SCREENSHOT_PREVIEWS]: isScreenshotPreviewsTestField
      ? initialDataApp[ExperimentAppField.SCREENSHOT_PREVIEWS]
      : getRawPreviewsFromForm(
          ExperimentAppField.SCREENSHOT_PREVIEWS,
          formFields,
        ),
  }

  return result
}

export const getRawDataFromForm = (
  initialDataApp: IRawParsedApp,
  formFields: TFormFieldData[],
): Omit<IChangeExperimentPayload, 'page' | 'applicationId'> => ({
  application: {
    ...initialDataApp,
    ...getAppValuesFromForm(initialDataApp, formFields),
    videoPreview: areVideoPreviewsNotEmpty(formFields),
  },
  language: getFormFieldValue(
    [ExperimentMetaField.PARSED_APP_LANGUAGE],
    formFields,
  ),
  tests: compose(getRawTestFieldsFromForm, getFormFieldValue)(
    [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
    formFields,
  ),
  title: getFormFieldValue([ExperimentMetaField.EXPERIMENT_TITLE], formFields),
  variants: getRawVariantsFromForm(formFields),
})
