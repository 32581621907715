import React, { useCallback, useEffect, useState } from 'react'
import { antd } from '@gismart/ui.library/core'
import { selectCommonError } from 'root-redux/selects/common'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { parseNewAppAction } from 'modules/applications/redux/actions'
import { resetError } from 'root-redux/actions/common'
import { connect } from 'react-redux'
import { isRightAppLink } from 'helpers/isRightAppLink'

const { Form, Input } = antd

type TProps = TStateProps & TDispatchProps

export const ApplicationLinkInputComponent: React.FC<TProps> = ({
  commonError,
  parseNewApp,
  resetCommonError,
}) => {
  const [form] = Form.useForm()
  const [wasAppTriedToParse, setWasAppTriedToParse] = useState(false)

  const handleContinue = useCallback(
    async (link: string) => {
      try {
        await form.validateFields()

        setWasAppTriedToParse(true)

        await parseNewApp(link)
      } catch (e) {
        // error
      }
    },
    [form, parseNewApp],
  )

  useEffect(() => {
    if (wasAppTriedToParse) {
      form.validateFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonError])

  return (
    <Form name="link-form" form={form}>
      <Form.Item
        name="link"
        getValueFromEvent={(e) => {
          // Clear validation error after change
          if (commonError) {
            resetCommonError()
          }

          return e.target.value
        }}
        validateTrigger="onSearch"
        rules={[
          {
            validator(rule, value) {
              if (commonError) {
                return Promise.reject(commonError)
              }

              if (!value) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Please enter link to application')
              }

              if (!isRightAppLink(value)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Link is invalid')
              }

              return Promise.resolve()
            },
          },
        ]}
      >
        <Input.Search
          placeholder="Enter link to store"
          enterButton="Next"
          onSearch={handleContinue}
        />
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state: IAppState) => ({
  commonError: selectCommonError(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  parseNewApp: (link: string) => dispatch(parseNewAppAction(link)),
  resetCommonError: () => dispatch(resetError()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const ApplicationLinkInput = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationLinkInputComponent)
