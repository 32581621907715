import React from 'react'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { Color } from '@gismart/ui.library/core/constants'
import { IGoogleLanding } from 'models/landings.google.model'
import { CopyableText } from 'components/CopyableText'
import { StyledListCard } from 'components/style'

const { Row, Divider, Button } = antd

type TProps = {
  landing: IGoogleLanding
  handleEdit: (id?: number) => void
}

export const LandingsCard: React.FC<TProps> = ({
  landing: { id, iconUrl, title, previewLink },
  handleEdit,
}) => (
  <StyledListCard.Card
    hoverable
    actions={[
      <Button type="link" onClick={() => handleEdit(id)}>
        <MaterialIcon type="cog" color={Color.PRIMARY} />
        <span>Edit Landing</span>
      </Button>,
    ]}
  >
    <Row wrap={false} align="middle">
      <StyledListCard.Avatar src={iconUrl} size="large" shape="square" />
      <StyledListCard.Title ellipsis={{ tooltip: true }}>
        {title}
      </StyledListCard.Title>
    </Row>
    <Divider />
    <Row wrap={false}>
      <StyledListCard.Label underline>Preview Link:</StyledListCard.Label>
      <CopyableText href={previewLink} />
    </Row>
  </StyledListCard.Card>
)
