import React from 'react'
import { IStatisticVariant } from 'models/experiments.statistic.model'
import { antd } from '@gismart/ui.library/core'
import { StatisticNumber } from './StatisticNumber'

const { Col, Row } = antd

interface IProps {
  variant: IStatisticVariant
  index: number
  previewUrl: string
}

export const StatisticVariant: React.FC<IProps> = ({
  previewUrl,
  variant,
  index,
}) => (
  <div
    key={variant.id}
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '50px',
    }}
  >
    {previewUrl && (
      <iframe
        title={`Preview - ${index}`}
        src={`${previewUrl}/${index}`}
        frameBorder="0"
        height="520px"
        width="300px"
        style={{ borderRadius: '20px', marginRight: '50px' }}
      />
    )}
    <div style={{ flexBasis: '600px', flexShrink: 0 }}>
      <Row>
        <Col span={8}>
          <StatisticNumber
            title="Click to Install Rate"
            value={variant.installClicksRate}
            isPercent
          />
        </Col>
        <Col span={8}>
          <StatisticNumber title="Visitors" value={variant.visitors} />
        </Col>
        <Col span={8}>
          <StatisticNumber
            title="Click to install"
            value={variant.installClicks}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <StatisticNumber title="Engagement Rate" value={null} />
        </Col>
        <Col span={8}>
          <StatisticNumber title="Time to Click to Install" value={null} />
        </Col>
        <Col span={8}>
          <StatisticNumber title="Avg. Time on Page" value={null} />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <StatisticNumber title="Improvement" value={null} />
        </Col>
        <Col span={8}>
          <StatisticNumber title="Confidence" value={null} />
        </Col>
      </Row>
    </div>
  </div>
)
