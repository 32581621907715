import { TFieldForTest } from 'models/experiments.model'

export const isFormFieldShown = (
  field: TFieldForTest,
  fieldsForVariantTest: TFieldForTest[],
  isVariant = false,
): boolean => {
  const isFieldForVariantTest = fieldsForVariantTest.includes(field)

  return isVariant ? isFieldForVariantTest : !isFieldForVariantTest
}
