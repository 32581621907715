import { selectParsedApp } from 'modules/applications/redux/selects'
import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { resetParsedAppAction } from '../../redux/actions'
import { NewApplicationComponent } from './Component'

const mapStateToProps = (state: IAppState) => ({
  parsedApp: selectParsedApp(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  resetParsedApp: () => dispatch(resetParsedAppAction()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const NewApplication = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewApplicationComponent)
