import React, { ReactElement, ReactNode } from 'react'
import { antd } from '@gismart/ui.library/core'
import { IOption } from 'models/common.model'
import { StyledListCard } from 'components/style'

const { Popover, Descriptions } = antd

export interface IExtendedTextOption extends IOption {
  href?: string
}

type TProps = {
  values: IExtendedTextOption[]
  children: ReactElement<ReactNode>
}

export const ExtendedText: React.FC<TProps> = ({ children, values }) => (
  <Popover
    content={
      <Descriptions column={1} size="small" style={{ width: 290 }}>
        {values.map(({ key, value, href }) => (
          <Descriptions.Item
            label={key}
            key={key}
            contentStyle={{ minWidth: 0 }}
          >
            {React.cloneElement(children as ReactElement, {
              children: value,
              ...(href && { href }),
            })}
          </Descriptions.Item>
        ))}
      </Descriptions>
    }
  >
    <StyledListCard.MoreButton type="link">more...</StyledListCard.MoreButton>
  </Popover>
)
