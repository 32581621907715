import { getIdFromFieldName } from './getIdFromFieldName'

export const scrollToFirstErrorFormField = (errorFields: any[]): void => {
  const firstErrorField = errorFields[0]
  const fieldId = getIdFromFieldName(firstErrorField.name)

  const firstElementWithError = document.getElementById(fieldId)

  firstElementWithError?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}
