import React, { useEffect, useState, useRef } from 'react'
import * as plots from '@antv/g2plot'
import { ViewConfig } from '@antv/g2plot'

// TODO:
// Not integrate yet ['stackColumn', 'groupColumn', 'stackBar', 'groupBar', 'pie', 'ring']
// 1 - Combine column + stackColumn + groupColumn
//    by options for example type = column stack = true -> stackColumn
// 2 - The same with bar
// 3 - Area should work with stack options

interface IProps extends Omit<React.HTMLProps<HTMLDivElement>, 'data'> {
  type: string
  options: ViewConfig
  data?: any[]
}

interface IChart extends React.FC<IProps> {
  plotTypes: { [key: string]: string }
}

export const Chart: IChart = ({ type, data = [], options, ...props }) => {
  const container = useRef(null)
  const [plot, setPLot] = useState<any>(null)

  useEffect(() => {
    const Constructor = plots[type]
    if (Constructor) {
      // Cleanup prev plot
      if (plot) {
        plot.destroy()
      }

      setPLot(
        new Constructor(container.current, {
          ...options,
          data,
        }),
      )
    }
    // Unobvious solution, need refactoring
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (plot !== null) plot.render()
  }, [plot])

  useEffect(() => {
    if (plot) {
      try {
        plot.updateConfig({
          ...options,
          data,
        })
        plot.render()
      } catch (error) {
        console.warn(error)
      }
    }
  }, [data, options, plot])

  return <div ref={container} {...props} />
}

Chart.plotTypes = {
  line: 'Line',
  area: 'Area',
  stackArea: 'StackedArea',
  column: 'Column',
  stackColumn: 'StackedColumn',
  groupColumn: 'GroupedColumn',
  bar: 'Bar',
  stackBar: 'StackedBar',
  groupBar: 'GroupedBar',
  pie: 'Pie',
  columnLine: 'ColumnLine',
}
