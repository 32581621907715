import styled from 'styled-components'
import { Color } from '@gismart/ui.library/core/constants'

export const NumberItem = styled.div`
  &.notAvailableValue {
    color: ${Color.DISABLED};
  }
`

export const Title = styled.div`
  height: 42px;

  & > span {
    padding-right: 5px;
  }
`

export const Value = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 45px;
  font-weight: 500;
`

export const Percent = styled.span`
  font-size: 20px;
  margin-top: 10px;
`
