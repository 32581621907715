import '@gismart/ui.library/core/style.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AnyAction } from 'redux'
import { fetchUserAction } from 'root-redux/actions/auth'
import { store } from 'root-redux/store'
import { App } from './components/App'

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  )
}

store.dispatch((fetchUserAction() as unknown) as AnyAction).then(render)
