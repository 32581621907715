import { IPermission } from 'models/permission.model'
import { ALL_PERMISSION_ACTIONS, PermissionAction } from 'root-constants'

export function getAllowedPermissionsActions(
  requestedResource: string,
  permissions: IPermission[] = [],
): PermissionAction[] {
  if (!requestedResource) {
    return ALL_PERMISSION_ACTIONS
  }

  const allowedPermission = permissions.find(
    ({ resource, effect }) =>
      resource === requestedResource && effect === 'allow',
  )
  const deniedPermission = permissions.find(
    ({ resource, effect }) =>
      resource === requestedResource && effect === 'deny',
  )
  const allowedActions = ALL_PERMISSION_ACTIONS.filter((action) =>
    allowedPermission?.actions.includes(action),
  )
  const deniedActions = ALL_PERMISSION_ACTIONS.filter(
    (action) => !deniedPermission?.actions.includes(action),
  )

  // Has not any permissions
  if (!deniedPermission && !allowedPermission) {
    return ALL_PERMISSION_ACTIONS
  }

  // Has only denied permission
  if (deniedPermission && !allowedPermission) {
    return deniedActions
  }

  // Has only allowed permission
  if (allowedPermission && !deniedPermission) {
    return allowedActions
  }

  // Has allowed and denied permissions
  return allowedActions.filter((action) => deniedActions.includes(action))
}

export function isResourceAvailable(
  requestedResource: string,
  permissions: IPermission[] = [],
  action: PermissionAction,
): boolean {
  return getAllowedPermissionsActions(requestedResource, permissions).includes(
    action,
  )
}
