import styled, { css } from 'styled-components'
import { antd } from '@gismart/ui.library/core'
import React from 'react'

const { List: AntdList, Button } = antd

export const StyledButton = styled(Button)`
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  border-radius: 0;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledList = styled(({ withAddBtn, ...rest }) => (
  <AntdList {...rest} />
))`
  .ant-list-item:first-child {
    ${(props) =>
      props.withAddBtn &&
      css`
        border-bottom: none;
      `}
  }
`
