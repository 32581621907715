import React, { useCallback } from 'react'
import { Draggable } from '../Draggable'
import { Wrapper, Title, Content, draggableStyle } from './style'
import { Item } from './Item'

export interface IProps {
  id?: string
  withoutContext?: boolean
  bottomLine?: boolean
  groupTitle?: string
  items: any[]
  onMove: (items: any[]) => void
  onRemove?: (items: any[], item: any) => void
  type: string
  renderItemContent: (item: any) => React.ReactNode
  addComponent?: React.ReactNode
}

export const SortableGroup: React.FC<IProps> = ({
  id,
  withoutContext = false,
  bottomLine = false,
  groupTitle = '',
  items,
  onMove,
  onRemove = () => undefined,
  type,
  renderItemContent,
  addComponent = null,
}) => {
  const onRemoveItem = useCallback(
    (item) => {
      const { id: idToRemove } = item

      onRemove(
        items.filter(({ id: itemId }) => itemId !== idToRemove),
        item,
      )
    },
    [onRemove, items],
  )

  const renderCard = useCallback(
    (item) => (
      <Item
        item={item}
        renderItemContent={renderItemContent}
        onRemove={onRemoveItem}
      />
    ),
    [renderItemContent, onRemoveItem],
  )

  return (
    <Wrapper bottomLine={bottomLine} id={id}>
      {groupTitle && <Title>{groupTitle}:</Title>}
      <Content>
        <Draggable
          withoutContext={withoutContext}
          style={draggableStyle}
          items={items}
          renderItem={renderCard}
          onChange={onMove}
          type={type}
        />
        {addComponent}
      </Content>
    </Wrapper>
  )
}
