import { TFormFieldData } from '@gismart/ui.library/core/types'

export const getFormFieldsWithoutVariantFieldsByUid = (
  variantUid: string,
  fields: TFormFieldData[],
): TFormFieldData[] => {
  const removedVariantName = fields.find(
    ({ name, value }) => name[1] === 'uid' && value === variantUid,
  )?.name[0]

  return removedVariantName
    ? fields.filter(({ name }) => name[0] !== removedVariantName)
    : fields
}
