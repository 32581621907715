import React, { useCallback, useMemo } from 'react'
import { ImgUploader } from 'components/Uploader'
import { convertUrlToImgPreview } from 'modules/googleLandings/helpers/convertUrlToImgPreview'
import { IImagePreview } from 'models/landings.google.model'

interface IProps {
  value?: string
  onChange?: (value: string) => void
}

export const IconUploader: React.FC<IProps> = ({
  value = '',
  onChange = () => undefined,
}) => {
  const iconPreview = useMemo(() => convertUrlToImgPreview(value), [value])

  const handleChange = useCallback(
    (preview: IImagePreview) => {
      onChange(preview.url)
    },
    [onChange],
  )

  return (
    <ImgUploader
      withPreview
      showEditButton
      value={iconPreview}
      onChange={handleChange}
    />
  )
}
