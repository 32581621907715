/* eslint-disable camelcase */
import { AppLanguage, ExperimentAppField } from 'modules/experiments/constants'
import {
  IAppIconFileData,
  IRawParsedApp,
  TParsedAppScreenshots,
  TParsedAppVideoPreviews,
} from './parser.model'

export enum ExperimentStatus {
  NEW = 'new',
  FINISHED = 'finished',
  PUBLISHED = 'published',
}

export interface IRawExperiments {
  data: IRawExperimentsDataItem[]
  hasNext: boolean
}

export interface IRawExperimentsDataItem {
  applicationId: number
  createdAt: string
  updatedAt: string
  finishedAt: string | null
  id: number
  language: AppLanguage
  page: string
  pixelEvent: string
  pixelId: string
  previewUrl: string
  publishedAt: string | null
  searchQuery: string
  tests: TRawFieldForTest[]
  title: string
  url: string
  variantsCount: number
  status: ExperimentStatus
}

export interface IRawExperiment {
  application: IRawParsedApp
  applicationId: number
  createdAt: string
  facebookCampaignId: number | null
  finishedAt: string | null
  id: number
  language: AppLanguage
  page: string
  previewUrl: string // url
  publishedAt: string | null
  searchQuery: string
  tests: TRawFieldForTest[]
  title: string
  updatedAt: string
  url: string // url
  variants: IAppVariant[]
  variantsCount: number
  pixelEvent?: string
  featuredBanner?: string
}

export interface IAppVariant extends Partial<IExperimentApp> {
  link: string // url
  id?: number
  experimentId?: number
  videoPreview?: boolean
}

export interface IChangeExperimentPayload {
  application: IRawParsedApp
  applicationId: number
  language: AppLanguage
  page: string
  tests: TRawFieldForTest[]
  title: string
  variants: IAppVariant[]
  pixelEvent?: string
}

export type TRawFieldForTest =
  | Exclude<
      ExperimentAppField,
      ExperimentAppField.SCREENSHOT_PREVIEWS | ExperimentAppField.VIDEO_PREVIEWS
    >
  | 'screenshot'
  | 'app-preview'

export type TFieldForTest =
  | Exclude<
      ExperimentAppField,
      | ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN
      | ExperimentAppField.ONE_STAR_COUNT
      | ExperimentAppField.TWO_STAR_COUNT
      | ExperimentAppField.THREE_STAR_COUNT
      | ExperimentAppField.FOUR_STAR_COUNT
      | ExperimentAppField.FIVE_STAR_COUNT
      | ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN
      | ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN
      | ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN
      | ExperimentAppField.IS_UPDATE_SHOWN
      | ExperimentAppField.UPDATE_DESCRIPTION
      | ExperimentAppField.UPDATE_DATE
      | ExperimentAppField.UPDATE_VERSION
    >
  | 'updates'
  | 'reviews'

export type TExperimentInitialData = Partial<IRawExperiment> | null

export interface IExperimentApp {
  [ExperimentAppField.TITLE]: string
  [ExperimentAppField.SUBTITLE]: string
  [ExperimentAppField.DEVELOPER_NAME]: string
  [ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN]: boolean
  [ExperimentAppField.PRICE]: number
  [ExperimentAppField.RATING]: number
  [ExperimentAppField.REVIEWS_AMOUNT]: number
  [ExperimentAppField.ONE_STAR_COUNT]: number
  [ExperimentAppField.TWO_STAR_COUNT]: number
  [ExperimentAppField.THREE_STAR_COUNT]: number
  [ExperimentAppField.FOUR_STAR_COUNT]: number
  [ExperimentAppField.FIVE_STAR_COUNT]: number
  [ExperimentAppField.STORE_POSITION]: number
  [ExperimentAppField.DESCRIPTION]: string
  [ExperimentAppField.IS_UPDATE_SHOWN]: boolean
  [ExperimentAppField.UPDATE_DESCRIPTION]: string
  [ExperimentAppField.UPDATE_DATE]: string
  [ExperimentAppField.UPDATE_VERSION]: string
  [ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN]: boolean
  [ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN]: boolean
  [ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN]: boolean
  [ExperimentAppField.AGE_RATING]: string
  [ExperimentAppField.ICON]: IAppIconFileData
  [ExperimentAppField.SCREENSHOT_PREVIEWS]: TParsedAppScreenshots
  [ExperimentAppField.VIDEO_PREVIEWS]: TParsedAppVideoPreviews
}
