import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import {
  BODY_CONTENT_FIELD_NAME,
  HEAD_CONTENT_FIELD_NAME,
  TEMPLATE_FIELD_NAME,
  UploaderFileExtension,
} from 'modules/customLandings/constants'
import { validateFileExtension } from 'modules/customLandings/helpers'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsFetching } from 'root-redux/selects/common'

const {
  Upload,
  Button,
  Form,
  message,
  Row,
  Space,
  Typography: { Text },
} = antd

interface IProps {
  form: antd.FormInstance
  isCreateMode: boolean
}

export const FormItemFileUpload: React.FC<IProps> = ({
  form,
  isCreateMode,
}) => {
  const isFetching = useSelector(selectIsFetching)
  const [isUploading, setIsUploading] = useState(false)
  const [fileList, setFileList] = useState<any[]>([])

  const setFileChunks = useCallback(
    (file: File) => {
      const reader = new FileReader()

      reader.addEventListener('load', (e) => {
        const result = e.target?.result || ''
        const parser = new DOMParser()
        const parsedHtmlFile = parser.parseFromString(
          result as string,
          UploaderFileExtension.HTML,
        )

        form.setFieldsValue({
          [BODY_CONTENT_FIELD_NAME]: parsedHtmlFile.body?.innerHTML || '',
          [HEAD_CONTENT_FIELD_NAME]: parsedHtmlFile.head?.innerHTML || '',
        })
      })

      reader.addEventListener('loadend', () => {
        message.success('File was added!')
        form.validateFields([TEMPLATE_FIELD_NAME])

        setIsUploading(false)
      })

      reader.addEventListener('error', () => {
        message.error('Upload failed!')
        form.validateFields([TEMPLATE_FIELD_NAME])

        setIsUploading(false)
      })

      reader.readAsText(file)
    },
    [form],
  )

  const handleChange = useCallback(
    ({ file }: { file: { status?: string; originFileObj?: File } }) => {
      const { status, originFileObj } = file
      setFileList([file])
      setIsUploading(true)

      if (status === 'done' && originFileObj) {
        setFileChunks(originFileObj)
      }
    },
    [setFileChunks],
  )

  return (
    <>
      <Form.Item
        label="Template"
        name={TEMPLATE_FIELD_NAME}
        required={isCreateMode}
        extra={
          <Space direction="vertical">
            <Text type="secondary">Uploaded file will not be downloadable</Text>
            <Text strong type="warning">
              Template requirement: buttons (links) aimed to redirect by One
              Link url should have empty <Text code>href</Text> attribute (when
              it&apos;s link) and <Text code>data-link</Text> attribute for
              anchor
            </Text>
          </Space>
        }
        rules={[
          ({ getFieldValue }) => ({
            validator() {
              if (!isCreateMode) {
                return Promise.resolve()
              }

              const headContent = getFieldValue(HEAD_CONTENT_FIELD_NAME)
              const bodyContent = getFieldValue(BODY_CONTENT_FIELD_NAME)

              return headContent && bodyContent
                ? Promise.resolve()
                : Promise.reject(new Error('This field is required!'))
            },
          }),
        ]}
      >
        <Row align="middle">
          <Upload
            showUploadList={{ showRemoveIcon: false }}
            fileList={fileList}
            accept=".html"
            beforeUpload={(file) => {
              validateFileExtension(file, [UploaderFileExtension.HTML])
            }}
            customRequest={({ file, onSuccess, data }: any) => {
              onSuccess(data, file)
            }}
            onChange={handleChange}
          >
            <Button
              disabled={isFetching || isUploading}
              icon={
                <MaterialIcon
                  type={isUploading ? 'loading' : 'upload'}
                  spin={isUploading}
                />
              }
            >
              Upload
            </Button>
          </Upload>
        </Row>
      </Form.Item>
      <Form.Item name={HEAD_CONTENT_FIELD_NAME} noStyle />
      <Form.Item name={BODY_CONTENT_FIELD_NAME} noStyle />
    </>
  )
}
