import React from 'react'
import { antd } from '@gismart/ui.library/core'

const { Rate, InputNumber } = antd

interface IProps {
  starsCount: number
  value?: React.ReactText
  onChange?: (value: React.ReactText) => void
}

export const NumberInputWithRate: React.FC<IProps> = ({
  starsCount,
  onChange = () => undefined,
  value = 0,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: 160 }}>
    <Rate
      value={starsCount}
      disabled
      style={{ marginBottom: 8, textAlign: 'center' }}
    />
    <InputNumber
      onChange={(currentValue) => onChange(currentValue || 0)}
      min={0}
      value={+value}
      style={{ width: '100%' }}
    />
  </div>
)
