import styled from 'styled-components'
import { antd } from '@gismart/ui.library/core'
import { Color } from '@gismart/ui.library/core/constants'

const {
  Typography: { Link, Text },
  Card,
  Avatar,
  Button,
} = antd

export const Title = styled.h2`
  margin: 0;
  padding: 16px 0;
`

export const FilterWrapper = styled.div`
  margin-bottom: 25px;
  background: #fff;
  padding: 25px;
`

export const StyledCopyableLink = styled(Link)`
  min-width: 0;
  margin-left: 5px;
`

export const StyledCopyableText = styled(Text)`
  min-width: 0;
  margin-left: 5px;
`

export const StyledListCard = {
  Card: styled(Card)`
    cursor: initial;
  `,
  Avatar: styled(Avatar)`
    flex-shrink: 0;
    margin-right: 10px;
  `,
  Title: styled(Text)`
    flex-grow: 1;
    min-width: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${Color.HEADING};
  `,
  TitleMark: styled(Text)`
    margin-left: 10px;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
  `,
  Label: styled(Text)`
    flex-shrink: 0;
    min-width: 70px;
  `,
  Text: styled(Text)`
    min-width: 0;
    margin-left: 5px;
  `,
  Link: styled(Link)`
    min-width: 0;
    margin-left: 5px;
  `,
  EditIconWrapper: styled.div`
    margin-left: 5px;
    cursor: pointer;
  `,
  MoreButton: styled(Button)`
    margin-left: 5px;
    padding: 0;
    height: auto;
    border: 0;
  `,
}
