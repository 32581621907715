import { pixelApi } from 'api'
import { startFetching, stopFetching } from 'root-redux/actions/common'
import { antd } from '@gismart/ui.library/core'
import { Action, ActionCreator } from 'redux'

const { message } = antd

const MODULE_NAME = 'SERVICE'

const FETCH_PIXEL_ID = `${MODULE_NAME}/FETCH_PIXEL_ID`
const UPDATE_PIXEL_ID = `${MODULE_NAME}/UPDATE_PIXEL_ID`
export const SET_PIXEL_ID = `${MODULE_NAME}/SET_PIXEL_ID`
export const RESET_PIXEL_ID = `${MODULE_NAME}/RESET_PIXEL_ID`

export function fetchPixelIdAction(): any {
  return async (dispatch) => {
    dispatch(startFetching(FETCH_PIXEL_ID))

    const response = await pixelApi.get()

    if (response.success && response.data) {
      dispatch({
        type: SET_PIXEL_ID,
        payload: response.data.pixel_id,
      })
    }

    if (!response.success) {
      message.error('Something went wrong. Pixel ID was not received')
    }

    dispatch(stopFetching(FETCH_PIXEL_ID))
  }
}

export function updatePixelIdAction(id: string): any {
  return async (dispatch) => {
    dispatch(startFetching(UPDATE_PIXEL_ID))

    const response = await pixelApi.update({ pixel_id: id })

    if (response.success && response.data) {
      dispatch({
        type: SET_PIXEL_ID,
        payload: id,
      })
    }

    if (!response.success) {
      message.error('Something went wrong. Pixel ID was not saved')
    }

    dispatch(stopFetching(UPDATE_PIXEL_ID))
  }
}

export const resetPixelIdAction: ActionCreator<Action> = () => ({
  type: RESET_PIXEL_ID,
})
