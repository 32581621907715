import styled from 'styled-components'
import { Color } from '@gismart/ui.library/core/constants'

export const Container = styled.div`
  padding-bottom: 25px;
  color: ${Color.HEADING};
  font-size: 16px;
`

export const Title = styled.h3`
  font-weight: normal;
  color: inherit;
`
