import React from 'react'
import { antd } from '@gismart/ui.library/core'
import { Header } from './Header'
import { Spinner } from './Spinner'

const { Layout, BackTop } = antd
const { Content, Footer } = Layout

const currentYear = new Date().getFullYear()

export const MainWrapper: React.FC = ({ children }) => (
  <Layout>
    <Header />
    <Content>
      <Spinner />
      {children}
    </Content>
    <Footer>{`©${currentYear} Created by GISMART`}</Footer>
    <BackTop />
  </Layout>
)
