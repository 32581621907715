import React from 'react'
import { IStatistics } from 'models/experiments.statistic.model'
import { antd } from '@gismart/ui.library/core'
import { StatisticVariant } from './StatisticVariant'
import { StatisticChart } from './StatisticChart'
import { StyledOverview, StyledVariantsWrapper } from '../style'

const { Col, Row } = antd

interface IProps {
  previewUrl: string
  statistic: IStatistics
}

export const Overview: React.FC<IProps> = ({ previewUrl, statistic }) => {
  const {
    iphoneInstalls,
    uniqueIphoneUsers,
    uniqueNotIphoneUsers,
    variants,
  } = statistic

  return (
    <StyledOverview>
      <Row style={{ marginBottom: '25px' }}>
        <Col span={8}>
          <StatisticChart
            data={uniqueIphoneUsers.byDate}
            config={{ xField: 'date', yField: 'value' }}
            title="Unique users (iPhone)"
            number={uniqueIphoneUsers.total}
            type="area"
            chartHeight="200px"
          />
        </Col>
        <Col span={8}>
          <StatisticChart
            data={uniqueNotIphoneUsers.byDate}
            config={{ xField: 'date', yField: 'value' }}
            title="Filtered users (Not iPhone)"
            number={uniqueNotIphoneUsers.total}
            type="area"
            chartHeight="200px"
          />
        </Col>
        <Col span={8}>
          <StatisticChart
            data={iphoneInstalls.byDate}
            config={{ xField: 'date', yField: 'value' }}
            title="Install (iPhone)"
            number={iphoneInstalls.total}
            type="area"
            chartHeight="200px"
          />
        </Col>
      </Row>
      <StyledVariantsWrapper>
        {variants.map((variant, index) => (
          <StatisticVariant
            key={variant.id}
            variant={variant}
            index={index}
            previewUrl={previewUrl}
          />
        ))}
      </StyledVariantsWrapper>
    </StyledOverview>
  )
}
