import { uniq, compact, map } from 'lodash'
import compose from 'lodash/fp/compose'
import { TFieldForTest, TRawFieldForTest } from 'models/experiments.model'
import { ExperimentAppField } from '../constants'

export const getFormTestFieldsFromRaw = (
  fieldsNames: TRawFieldForTest[],
): TFieldForTest[] => {
  const mapCallback = (fieldName: TRawFieldForTest) => {
    switch (fieldName) {
      case ExperimentAppField.TITLE:
        return ExperimentAppField.TITLE
      case ExperimentAppField.SUBTITLE:
        return ExperimentAppField.SUBTITLE
      case ExperimentAppField.ICON:
        return ExperimentAppField.ICON
      case 'screenshot':
        return ExperimentAppField.SCREENSHOT_PREVIEWS
      case 'app-preview':
        return ExperimentAppField.VIDEO_PREVIEWS
      case ExperimentAppField.DEVELOPER_NAME:
        return ExperimentAppField.DEVELOPER_NAME
      case ExperimentAppField.PRICE:
      case ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN:
        return ExperimentAppField.PRICE
      case ExperimentAppField.RATING:
        return ExperimentAppField.RATING
      case ExperimentAppField.REVIEWS_AMOUNT:
        return ExperimentAppField.REVIEWS_AMOUNT
      case ExperimentAppField.ONE_STAR_COUNT:
      case ExperimentAppField.TWO_STAR_COUNT:
      case ExperimentAppField.THREE_STAR_COUNT:
      case ExperimentAppField.FOUR_STAR_COUNT:
      case ExperimentAppField.FIVE_STAR_COUNT:
        return 'reviews'
      case ExperimentAppField.STORE_POSITION:
        return ExperimentAppField.STORE_POSITION
      case ExperimentAppField.DESCRIPTION:
        return ExperimentAppField.DESCRIPTION
      case ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN:
      case ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN:
      case ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN:
      case ExperimentAppField.IS_UPDATE_SHOWN:
      case ExperimentAppField.UPDATE_DESCRIPTION:
      case ExperimentAppField.UPDATE_VERSION:
      case ExperimentAppField.UPDATE_DATE:
        return 'updates'
      case ExperimentAppField.AGE_RATING:
        return ExperimentAppField.AGE_RATING
      default:
        return null
    }
  }

  return compose(uniq, compact, (value) => map(value, mapCallback))(fieldsNames)
}
