import React from 'react'

const iconPartStyle: React.CSSProperties = {
  fill: 'none',
  stroke: '#fff',
  strokeWidth: '40',
  strokeMiterlimit: 10,
}

export const Logo: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    fill="#fff"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2340.5 512"
    xmlSpace="preserve"
  >
    <g>
      <rect y="452.2" width="512" height="40" />
      <path
        style={iconPartStyle}
        className="st0"
        d="M103.6,404.4L103.6,404.4c-22,0-39.9-17.9-39.9-39.9V272c0-22,17.9-39.9,39.9-39.9l0,0
          c22,0,39.9,17.9,39.9,39.9v92.5C143.5,386.6,125.6,404.4,103.6,404.4z"
      />
      <path
        style={iconPartStyle}
        className="st0"
        d="M254.4,404.4L254.4,404.4c-22,0-39.9-17.9-39.9-39.9V182.2c0-22,17.9-39.9,39.9-39.9l0,0
          c22,0,39.9,17.9,39.9,39.9v182.3C294.3,386.6,276.5,404.4,254.4,404.4z"
      />
      <path
        style={{
          ...iconPartStyle,
          stroke: '#ffc336',
        }}
        className="st0"
        d="M408.4,404.4L408.4,404.4c-22,0-39.9-17.9-39.9-39.9V82.1c0-22,17.9-39.9,39.9-39.9l0,0
          c22,0,39.9,17.9,39.9,39.9v282.5C448.4,386.6,430.5,404.4,408.4,404.4z"
      />
      <rect y="452.2" width="512" height="40" />
    </g>
    <g className="st1">
      <path
        d="M760.6,330.3c-22.2-21.5-33.4-48.7-33.4-81.8c0-33.4,11.2-60.6,33.4-81.8c22.2-21.2,50.6-31.8,84.6-31.8
          c25.3,0,47.1,5.6,65.6,16.5v44.3c-20.3-12.5-40.9-18.7-62.4-18.7c-22.2,0-40.3,6.6-54.3,20c-14,13.1-20.9,30.6-20.9,52.4
          c0,21.5,6.9,39,20.3,51.8c13.4,12.8,30.3,19.4,50.3,19.4c19,0,33.7-4.7,44-14.4V231h45v96.5c-9.1,9.7-21.5,18.1-37.5,24.7
          c-15.9,6.6-33.1,10-51.5,10C810.5,362.1,782.7,351.5,760.6,330.3z"
      />
      <path
        d="M995.6,134.2c14.7,0,26.2,11.5,26.2,26.5c0,14.7-11.5,26.2-26.2,26.2s-26.2-11.5-26.2-26.2
          C969.4,145.8,981,134.2,995.6,134.2z M973.8,357.7V202h44v155.8H973.8z"
      />
      <path
        d="M1054.9,347.1v-39c13.7,9.1,38.4,17.8,63.7,17.8c17.8,0,26.8-4.4,26.8-13.1c0-4.1-1.9-7.2-7.8-9.7
          c-2.8-1.2-5.6-2.2-8.1-2.8l-11.5-2.8c-5.3-1.6-9.7-2.5-12.5-3.4c-35.9-9.7-50.6-20.9-50.6-48.4c0-29.7,25.9-47.8,68.7-47.8
          c23.4,0,43.1,4.7,58.4,14.4v37.5c-18.7-10.9-37.5-16.2-56.5-16.2c-16.5,0-25,4.1-25,12.5c0,5.3,4.1,7.5,8.7,10.3
          c3.4,1.2,9.7,2.8,13.4,4.1l16.5,3.4c33.7,7.8,50.9,22.2,50.9,49.3c0,14.7-6.2,26.2-19,35.3s-29.7,13.4-50.6,13.4
          C1092.7,361.8,1070.9,356.8,1054.9,347.1z"
      />
      <path
        d="M1222.9,357.7V202h44v19c11.5-15.3,27.5-23.1,47.4-23.1c23.4,0,41.8,11.9,48.4,26.5c10.3-15.3,31.2-26.5,53.7-26.5
          c33.4,0,55.6,21.5,55.6,62.4v97.4h-44v-87.4c0-21.5-8.7-32.2-25.9-32.2c-13.4,0-26.5,9.1-32.5,21.2v98.3h-44v-87.4
          c0-21.5-8.7-32.2-25.9-32.2c-12.8,0-26.5,9.1-32.8,20.6v99H1222.9z"
      />
      <path
        d="M1526.6,339.3c-16.5-15-25-35-25-59.6c0-23.1,7.5-42.5,22.5-58.1c15-15.9,34.7-23.7,58.7-23.7c22.8,0,40.6,8.1,54,24
          c13.4,15.9,20.3,35.3,20.3,58.4v11.6h-111.8c4.7,21.2,24.7,32.5,52.1,32.5c17.5,0,34-4.7,49.9-13.7v35.3
          c-14,10.6-33.7,15.9-59.3,15.9C1563.8,361.8,1543.2,354.3,1526.6,339.3z M1613.7,263.8c-0.9-17.2-13.1-31.2-32.8-31.2
          c-20,0-33.4,12.8-35.6,31.2H1613.7z"
      />
      <path
        d="M1705.5,238.8h-30.6V202h30.6v-42.8h44V202h44.3v36.8h-44.3v61.8c0,15.3,6.2,21.9,21.2,21.9c7.8,0,15.3-1.9,22.8-5.6v36.5
          c-9.1,5.6-21.2,8.4-37.1,8.4c-34,0-50.9-17.5-50.9-52.4V238.8z"
      />
      <path
        d="M1828.5,357.7V202h44v26.2c4.7-8.1,11.9-15.3,21.9-21.2c10-5.9,20.9-9.1,32.5-9.1c2.2,0,3.7,0,5,0.3v43.1
          c-3.7-0.9-8.4-1.6-13.7-1.6c-21.2,0-39.3,13.1-45.6,27.2v90.8H1828.5z"
      />
      <path
        d="M1984.6,134.2c14.7,0,26.2,11.5,26.2,26.5c0,14.7-11.5,26.2-26.2,26.2s-26.2-11.5-26.2-26.2
          C1958.3,145.8,1969.9,134.2,1984.6,134.2z M1962.7,357.7V202h44v155.8H1962.7z"
      />
      <path
        d="M2066.7,339.3c-16.5-15.3-25-35.3-25-59.9s8.4-44.3,25-59c16.9-15,36.8-22.5,60.2-22.5c17.2,0,32.5,3.4,46.2,10.3v37.5
          c-13.4-6.2-26.8-9.4-39.6-9.4c-13.7,0-25.3,4.1-34.7,12.2c-9.1,7.8-13.7,18.4-13.7,31.2s4.7,23.4,14,31.5
          c9.4,8.1,20.9,12.2,34.3,12.2c15.9,0,30-3.4,42.1-10.6v37.5c-14.7,7.8-30.9,11.6-49.3,11.6C2103.2,361.8,2083.2,354.3,2066.7,339.3
          z"
      />
      <path
        d="M2204.9,347.1v-39c13.7,9.1,38.4,17.8,63.7,17.8c17.8,0,26.8-4.4,26.8-13.1c0-4.1-1.9-7.2-7.8-9.7
          c-2.8-1.2-5.6-2.2-8.1-2.8l-11.5-2.8c-5.3-1.6-9.7-2.5-12.5-3.4c-35.9-9.7-50.6-20.9-50.6-48.4c0-29.7,25.9-47.8,68.7-47.8
          c23.4,0,43.1,4.7,58.4,14.4v37.5c-18.7-10.9-37.5-16.2-56.5-16.2c-16.5,0-25,4.1-25,12.5c0,5.3,4.1,7.5,8.7,10.3
          c3.4,1.2,9.7,2.8,13.4,4.1l16.5,3.4c33.7,7.8,50.9,22.2,50.9,49.3c0,14.7-6.2,26.2-19,35.3s-29.7,13.4-50.6,13.4
          C2242.7,361.8,2220.9,356.8,2204.9,347.1z"
      />
    </g>
  </svg>
)
