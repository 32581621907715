import { AnyAction } from 'redux'
import { ILanding } from 'models/landings.model'
import { RESET_LANDINGS, SET_LANDINGS, SET_SEARCH_STRING } from './actions'

export interface ILandingsState {
  landings: ILanding[]
  landingsCount: number
  searchString: string
}

const initState: ILandingsState = {
  landings: [],
  landingsCount: 0,
  searchString: '',
}

export function landingsReducer(
  state: ILandingsState = initState,
  { type, payload = null }: AnyAction,
): ILandingsState {
  switch (type) {
    case SET_LANDINGS: {
      return {
        ...state,
        landings: payload.landings,
        landingsCount: payload.landingsCount,
      }
    }
    case RESET_LANDINGS: {
      return {
        ...state,
        landings: [],
        landingsCount: 0,
        searchString: '',
      }
    }
    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload,
      }
    }
    default:
      return state
  }
}
