import { MainWrapper } from 'components/MainWrapper'
import { ConnectedRouter } from 'connected-react-router'
import { TAppDispatchThunk } from 'models/store.models'
import { Applications } from 'modules/applications'
import { CustomLandings } from 'modules/customLandings'
import { GoogleLandings } from 'modules/googleLandings'
import { Landings } from 'modules/landings'
import { Login, NotFound } from 'pages'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { logoutAction } from 'root-redux/actions/auth'
import { routeHistory } from 'route-history'

type TProps = TDispatchProps

const AppComponent: React.FC<TProps> = ({ logout }) => (
  <ConnectedRouter history={routeHistory}>
    <Route exact path="/" render={() => <Redirect to="/applications" />} />
    <Switch>
      <Route path="/login" component={Login} />
      <Route
        path="/logout"
        render={() => {
          logout()
          return null
        }}
      />
      <Route
        path={[
          '/applications',
          '/landings',
          '/googleLandings',
          // '/autoclickLandings',
          '/customLandings',
        ]}
      >
        <MainWrapper>
          <Route path="/applications" component={Applications} />
          <Route path="/landings" component={Landings} />
          <Route path="/googleLandings" component={GoogleLandings} />
          {/* <Route path="/autoclickLandings" component={AutoclickLandings} /> */}
          <Route path="/customLandings" component={CustomLandings} />
        </MainWrapper>
      </Route>
      <Route component={NotFound} />
    </Switch>
  </ConnectedRouter>
)

type TDispatchProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  logout: () => dispatch(logoutAction()),
})

export const App = connect(null, mapDispatchToProps)(AppComponent)
