import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { antd } from '@gismart/ui.library/core'
import { TFormInstance } from '@gismart/ui.library/core/types'
import { selectCommonError } from 'root-redux/selects/common'
import { resetError } from 'root-redux/actions/common'
import { TrimmedInput } from 'components/TrimmedInput'
import {
  ExperimentMetaField,
  FIELDS_FOR_TEST_OPTIONS_SELECT,
  LANGUAGE_SELECT_OPTIONS,
} from '../../constants'
import { FormItemSelect } from './FormItemSelect'
import { StepControls } from './StepControls'

const { Form } = antd

type TProps = TStateProps &
  TDispatchProps & {
    form: TFormInstance
    isLanguageSelectShown: boolean
    onContinue: () => void
    onCancel: () => void
    isNeededValidationByCommonError: boolean
  }

export const FirstStepComponent: React.FC<TProps> = ({
  form,
  isLanguageSelectShown,
  isNeededValidationByCommonError,
  onContinue,
  onCancel,
  commonError,
  resetCommonError,
}) => {
  const handleContinue = useCallback(async () => {
    try {
      await form.validateFields()
      onContinue()
    } catch (e) {
      // error
    }
  }, [form, onContinue])

  useEffect(
    () => () => {
      if (commonError) {
        resetCommonError()
      }
    },
    [commonError, resetCommonError],
  )

  useEffect(() => {
    if (isNeededValidationByCommonError) {
      form.validateFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonError])

  return (
    <>
      <Form.Item
        label="Title"
        name={ExperimentMetaField.EXPERIMENT_TITLE}
        required
        rules={[{ required: true }]}
      >
        <TrimmedInput placeholder="Experiment title" />
      </Form.Item>
      <Form.Item
        label="What to test"
        name="fieldsForVariantTest"
        required
        rules={[
          {
            required: true,
            message: 'Please select at least one item to test!',
          },
        ]}
      >
        <FormItemSelect
          options={FIELDS_FOR_TEST_OPTIONS_SELECT}
          mode="multiple"
        />
      </Form.Item>
      {isLanguageSelectShown && (
        <Form.Item
          label="Store language"
          name={ExperimentMetaField.PARSED_APP_LANGUAGE}
          getValueFromEvent={(value) => {
            // Clear validation error after change
            if (commonError) {
              resetCommonError()
            }

            return value
          }}
          extra="You will not have the ability to edit language after this step"
          rules={[
            {
              validator() {
                return commonError
                  ? // eslint-disable-next-line prefer-promise-reject-errors
                    Promise.reject(commonError)
                  : Promise.resolve()
              },
            },
          ]}
        >
          <FormItemSelect options={LANGUAGE_SELECT_OPTIONS} />
        </Form.Item>
      )}
      <StepControls
        backText="Cancel"
        onBack={onCancel}
        onContinue={handleContinue}
      />
    </>
  )
}

const mapStateToProps = (state: IAppState) => ({
  commonError: selectCommonError(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  resetCommonError: () => dispatch(resetError()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const FirstStep = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirstStepComponent)
