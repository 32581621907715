import React, { CSSProperties, useCallback } from 'react'
import { DndProviderProps } from 'react-dnd'
import { Provider } from './Provider'
import { Item, IProps as IItemProps } from './Item'

interface IProps {
  type: string
  items: any[]
  withoutContext?: boolean
  className?: string
  style?: CSSProperties
  renderItem?: (item: any) => React.ReactNode
  onChange?: (items: any[]) => void
}

interface IDraggable extends React.FC<IProps> {
  Provider: React.FC<Omit<DndProviderProps<any, any>, 'backend'>>
  Item: React.FC<IItemProps>
}

const DraggableComponent: IDraggable = ({
  style,
  className,
  items,
  renderItem = () => null,
  onChange = () => undefined,
  type,
  withoutContext = false,
}) => {
  const onMove = useCallback(
    (dragIndex, hoverIndex) => {
      const copiedItems = items.map((item) => ({ ...item }))
      const dragItem = copiedItems[dragIndex]
      copiedItems.splice(dragIndex, 1)
      copiedItems.splice(hoverIndex, 0, dragItem)
      onChange(copiedItems)
    },
    [items, onChange],
  )

  const content = (
    <div style={style} className={className}>
      {items.map((item, index) => (
        <Item
          key={item.id || item.video.id}
          id={item.id || item.video.id}
          index={index}
          type={type}
          item={item}
          renderItem={renderItem}
          onMove={onMove}
        />
      ))}
    </div>
  )

  return withoutContext ? content : <Provider>{content}</Provider>
}

DraggableComponent.Provider = Provider
DraggableComponent.Item = Item

export const Draggable = DraggableComponent
