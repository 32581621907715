import { TFormFieldData } from '@gismart/ui.library/core/types'
import { IRawExperiment } from 'models/experiments.model'
import { AppLanguage, ExperimentMetaField } from '../constants'
import { getFormTestFieldsFromRaw } from './getFormTestFieldsFromRaw'

export const getFirstStepFormFields = (
  rawExperiment: Partial<IRawExperiment>,
): TFormFieldData[] => [
  {
    name: [ExperimentMetaField.EXPERIMENT_TITLE],
    value: rawExperiment.title || '',
  },
  {
    name: [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
    value: getFormTestFieldsFromRaw(rawExperiment.tests || []),
  },
  {
    name: [ExperimentMetaField.PARSED_APP_LANGUAGE],
    value: rawExperiment.language || AppLanguage.EN,
  },
]
