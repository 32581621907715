import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPixelIdAction,
  resetPixelIdAction,
  updatePixelIdAction,
} from 'root-redux/actions/service'
import { selectPixelId } from 'root-redux/selects/service'
import { FacebookPixelModal } from './FacebookPixelModal'

const { Button } = antd

export const ButtonWithPixelModal: React.FC = () => {
  const dispatch = useDispatch()
  const pixelId = useSelector(selectPixelId)
  const [isModalShown, setIsModalShown] = useState(false)

  return (
    <>
      <Button
        icon={<MaterialIcon type="facebook" />}
        onClick={() => {
          setIsModalShown(true)
          dispatch(fetchPixelIdAction())
        }}
        style={{ marginLeft: '8px' }}
      >
        Pixel ID
      </Button>
      <FacebookPixelModal
        label="Pixel ID"
        isVisible={isModalShown && pixelId !== null}
        setIsVisible={setIsModalShown}
        value={pixelId}
        updateValue={(id: string) => dispatch(updatePixelIdAction(id))}
        closeCallback={() => dispatch(resetPixelIdAction())}
      />
    </>
  )
}
