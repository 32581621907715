import { TFormFieldData } from '@gismart/ui.library/core/types'
import { IAppVariant, TFieldForTest } from 'models/experiments.model'
import { IRawParsedApp } from 'models/parser.model'
import { getFormFieldsForTesting } from './getFormFieldsForTesting'
import { getVariantsIndexesWithUids } from './getVariantsIndexesWithUids'

export const getThirdStepFormFields = ({
  rawApp,
  fieldsForVariantTest,
  currentFormFields,
  variants,
}: {
  rawApp: IRawParsedApp
  fieldsForVariantTest: TFieldForTest[]
  currentFormFields?: TFormFieldData[]
  variants?: IAppVariant[]
}): TFormFieldData[] => {
  const currentVariantsIndexes =
    currentFormFields && getVariantsIndexesWithUids(currentFormFields)

  if (currentVariantsIndexes?.length) {
    return currentVariantsIndexes.reduce(
      (result, [variantIndex]) => [
        ...result,
        ...getFormFieldsForTesting({
          rawApp,
          currentFormFields,
          fieldsForVariantTest,
          isVariant: true,
          isNewVariant: false,
          variantIndex,
        }),
      ],
      [] as TFormFieldData[],
    )
  }

  if (variants) {
    return variants.reduce(
      (result, variant, variantIndex) => [
        ...result,
        ...getFormFieldsForTesting({
          rawApp,
          currentFormFields,
          fieldsForVariantTest,
          isVariant: true,
          isNewVariant: false,
          variantIndex,
          variant,
        }),
      ],
      [] as TFormFieldData[],
    )
  }

  return getFormFieldsForTesting({
    rawApp,
    fieldsForVariantTest,
    isVariant: true,
    isNewVariant: true,
    variantIndex: 0,
  })
}
