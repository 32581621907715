/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState, useCallback } from 'react'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'

const { Input, Tooltip } = antd

const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const InputWithCopy: React.FC<antd.InputProps> = (props) => {
  const inputEl = useRef<any>(null)
  const [visible, setVisible] = useState(false)

  const onClickCopy = useCallback(() => {
    copyToClipboard(inputEl.current.state.value)
    setVisible(true)
    setTimeout(() => setVisible(false), 300)
  }, [])

  const CopyAddon = (
    <div style={{ cursor: 'pointer' }} onClick={onClickCopy}>
      <Tooltip placement="bottom" title="Copied" visible={visible}>
        <MaterialIcon
          type="content-copy"
          size="1.2em"
          style={{ paddingRight: '4px' }}
        />
        <span>Copy</span>
      </Tooltip>
    </div>
  )

  return <Input {...props} ref={inputEl} addonAfter={CopyAddon} />
}
