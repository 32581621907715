export const getPageNumberById = ({
  id,
  allPagesItems,
  limit,
}: {
  id: string
  allPagesItems: { id: number }[]
  limit: number
}): number => {
  const indexOfItem = allPagesItems.findIndex(
    ({ id: itemId }) => String(itemId) === id,
  )
  return Math.ceil((indexOfItem + 1) / limit)
}
