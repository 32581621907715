import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { goTo } from 'route-history'
import { Redirect } from 'react-router-dom'
import { ErrorPage } from '@gismart/ui.library/core/components'
import { ServerErrorCode } from '@gismart/ui.library/core/constants'
import { PermissionAction } from 'root-constants'
import { IAppState } from 'models/store.models'
import { IPermissionProps } from 'models/permission.model'
import { getPermissions, getIsLoggedIn } from 'root-redux/selects/auth'
import { getAllowedPermissionsActions } from 'helpers/permissions'

export type TOwnProps = {
  children: any | ((permissions: IPermissionProps) => React.ReactElement)
  resource?: string
  action?: PermissionAction
}

type TProps = TStateProps & TOwnProps

const SecureComponent: React.FC<TProps> = ({
  children,
  resource = '',
  action,
  isLoggedIn,
  permissions,
}) => {
  if (typeof children !== 'function') {
    return isLoggedIn ? children : <Redirect to="/login" />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const allowedPermissionsActions = useMemo(
    () => getAllowedPermissionsActions(resource, permissions),
    [permissions, resource],
  )

  if (action !== undefined && !allowedPermissionsActions.includes(action)) {
    return (
      <ErrorPage
        statusCode={ServerErrorCode.FORBIDDEN}
        onClick={() => goTo('/')}
      />
    )
  }

  return children({
    isCreatable: allowedPermissionsActions.includes(PermissionAction.CREATE),
    isReadable: allowedPermissionsActions.includes(PermissionAction.READ),
    isEditable: allowedPermissionsActions.includes(PermissionAction.UPDATE),
    isDeletable: allowedPermissionsActions.includes(PermissionAction.DELETE),
  } as IPermissionProps)
}

const mapStateToProps = (state: IAppState) => ({
  isLoggedIn: getIsLoggedIn(state),
  permissions: getPermissions(state),
})

type TStateProps = ReturnType<typeof mapStateToProps>

export const Secure = connect(mapStateToProps)(SecureComponent)
