import { antd } from '@gismart/ui.library/core'
import { landingsApi } from 'api'
import {
  setError,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { IAction, TAppActionThunk } from 'models/store.models'
import { IUpdateLanding, ICreateLanding } from 'models/landings.model'
import { selectLandingsSearchString } from './selects'

const { message } = antd

const MODULE_NAME = 'LANDINGS'

export const SET_LANDINGS = `${MODULE_NAME}/SET_LANDINGS`
export const RESET_LANDINGS = `${MODULE_NAME}/RESET_LANDINGS`
export const SET_SEARCH_STRING = `${MODULE_NAME}/SET_SEARCH_STRING`
const FETCH_LANDINGS = `${MODULE_NAME}/FETCH_LANDINGS`
const CREATE_LANDING = `${MODULE_NAME}/CREATE_LANDING`
const UPDATE_LANDING = `${MODULE_NAME}/UPDATE_LANDING`

export const resetLandingsAction = (): IAction<never> => ({
  type: RESET_LANDINGS,
})

export const setLandingsSearchStringAction = (
  payload: string,
): IAction<string> => ({
  type: SET_SEARCH_STRING,
  payload,
})

export function fetchLandingsAction(
  isShouldReset = false,
): TAppActionThunk<any> {
  return async (dispatch, getState) => {
    const state = getState()
    const currentSearchString = selectLandingsSearchString(state)
    const searchString = isShouldReset ? '' : currentSearchString
    dispatch(startFetching(FETCH_LANDINGS))

    const response = await landingsApi.fetchLandings(searchString)

    if (response.success && response.data) {
      dispatch({
        type: SET_LANDINGS,
        payload: {
          landings: response.data.data,
          landingsCount: response.data.meta?.count,
        },
      })

      if (isShouldReset) {
        dispatch(setLandingsSearchStringAction(searchString))
      }
    } else {
      message.error('Something went wrong!')
    }

    dispatch(stopFetching(FETCH_LANDINGS))
  }
}

export function createLandingAction(
  payload: ICreateLanding,
  successfulCallback?: () => void,
): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(CREATE_LANDING))

    const response = await landingsApi.createLanding(payload)

    if (response.success && response.data) {
      dispatch(fetchLandingsAction(true))
      successfulCallback && successfulCallback()
      message.success('Landing was created!')
    } else if (!response.success && response.status === 404) {
      dispatch(setError('Application was not found!'))
    } else {
      message.error('Something went wrong!')
    }

    dispatch(stopFetching(CREATE_LANDING))
  }
}

export function updateLandingAction(
  payload: IUpdateLanding,
  succesfulCallback?: () => void,
): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(UPDATE_LANDING))

    const response = await landingsApi.updateLanding(payload)

    if (response.success && response.data) {
      dispatch(fetchLandingsAction())
      succesfulCallback && succesfulCallback()
      message.success('Landing was updated!')
    } else {
      message.error('Something went wrong!')
    }

    dispatch(stopFetching(UPDATE_LANDING))
  }
}
