import {
  IChangeExperimentPayload,
  IRawExperiment,
  IRawExperiments,
} from 'models/experiments.model'
import { IRawStatisticItem } from 'models/experiments.statistic.model'
import {
  ApiService,
  RequestMethod,
  IRequestOptions,
  IResponseResult,
  IRequestParams,
} from '@gismart/ui.library/core/services/api'

export class ExperimentsApi {
  protected fetchExperimentsController: AbortController | undefined
  protected api: ApiService
  protected experimentsPath = 'experiments'

  constructor(api: ApiService) {
    this.api = api
  }

  fetchExperiments(
    applicationId: number,
    experimentsParams: IRequestParams = {},
  ): Promise<IResponseResult<IRawExperiments>> {
    if (this.fetchExperimentsController) {
      this.fetchExperimentsController.abort()
    }
    this.fetchExperimentsController = new AbortController()

    const options: IRequestOptions = {
      params: {
        applicationId,
        ...experimentsParams,
      },
      signal: this.fetchExperimentsController.signal,
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IRawExperiments>(this.experimentsPath, options)
  }

  fetchExperiment(id: number): Promise<IResponseResult<IRawExperiment>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IRawExperiment>(
      `${this.experimentsPath}/${id}`,
      options,
    )
  }

  createExperiment(
    payload: IChangeExperimentPayload,
  ): Promise<IResponseResult<IRawExperiment>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: payload,
    }

    return this.api.makeRequest<IRawExperiment>(this.experimentsPath, options)
  }

  updateExperiment(
    id: number,
    payload: IChangeExperimentPayload,
  ): Promise<IResponseResult<IRawExperiment>> {
    const options: IRequestOptions = {
      method: RequestMethod.PUT,
      body: payload,
    }

    return this.api.makeRequest<IRawExperiment>(
      `${this.experimentsPath}/${id}`,
      options,
    )
  }

  publishExperiment(id: number): Promise<IResponseResult<unknown>> {
    const options: IRequestOptions = { method: RequestMethod.PUT }

    return this.api.makeRequest<unknown>(
      `${this.experimentsPath}/${id}/publish`,
      options,
    )
  }

  finishExperiment(id: number): Promise<IResponseResult<unknown>> {
    const options: IRequestOptions = { method: RequestMethod.PUT }

    return this.api.makeRequest<unknown>(
      `${this.experimentsPath}/${id}/finish`,
      options,
    )
  }

  getCustomStatistic(
    id: number,
  ): Promise<IResponseResult<IRawStatisticItem[]>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IRawStatisticItem[]>(
      `${this.experimentsPath}/${id}/stats`,
      options,
    )
  }
}
