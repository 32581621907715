import React from 'react'
import { antd } from '@gismart/ui.library/core'
import { StyledStepControls } from './style'

const { Button } = antd

interface IProps {
  onContinue: () => void
  onBack: () => void
  continueText?: string
  backText?: string
}

export const StepControls: React.FC<IProps> = ({
  continueText = 'Continue',
  backText = 'Back',
  onContinue,
  onBack,
}) => (
  <StyledStepControls>
    <Button onClick={onBack} style={{ marginRight: '8px' }}>
      {backText}
    </Button>
    <Button type="primary" onClick={onContinue}>
      {continueText}
    </Button>
  </StyledStepControls>
)
