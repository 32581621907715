import React from 'react'
import { Breadcrumb } from 'components/Breadcrumb'
import { Secure, TOwnProps as TSecureProps } from './Secure'

type TProps = TSecureProps & {
  isBreadcrumbShown?: boolean
}

export const ContentWrapper: React.FC<TProps> = ({
  isBreadcrumbShown = true,
  ...props
}) => (
  <>
    {isBreadcrumbShown && <Breadcrumb />}
    <Secure {...props} />
  </>
)
