import React from 'react'
import { antd } from '@gismart/ui.library/core'
import { Color } from '@gismart/ui.library/core/constants'
import { TFieldForTest } from 'models/experiments.model'
import { ImgUploader } from 'components/Uploader'
import { getFormFieldName } from 'modules/experiments/helpers/getFormFieldName'
import { isFormFieldShown } from 'modules/experiments/helpers/isFormFieldShown'
import { getIdFromFieldName } from 'modules/experiments/helpers/getIdFromFieldName'
import { TrimmedInput } from 'components/TrimmedInput'
import { TFormFieldData } from '@gismart/ui.library/core/types'
import { NumberInputWithCheckbox } from './NumberInputWithCheckbox'
import { NumberInputWithRate } from './NumberInputWithRate'
import { APP_PHONE_FIELDS_MAP, ExperimentAppField } from '../../constants'
import { FormItemReviews, PreviewMediaTitle } from './style'
import { PhotoPreviewGroup } from './PhotoPreviewGroup'
import { VideoPreviewGroup } from './VideoPreviewGroup'
import { UpdateFormItems } from './UpdateFormItems'

const { Form, Checkbox, InputNumber } = antd

interface IProps {
  fieldsForVariantTest: TFieldForTest[]
  isVariant?: boolean
  variantIndex?: number
  formStateFields: TFormFieldData[]
}

export const EditingAppFields: React.FC<IProps> = ({
  fieldsForVariantTest,
  isVariant = false,
  variantIndex = 0,
  formStateFields,
}) => (
  <div
    style={{
      backgroundColor: Color.WHITE,
      padding: isVariant ? '24px 20px' : 0,
    }}
  >
    {isVariant && (
      <Form.Item
        label="Link"
        name={getFormFieldName(['link'], isVariant, variantIndex)}
        rules={[{ required: true }]}
      >
        <TrimmedInput />
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.TITLE,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Title"
        name={getFormFieldName(
          [ExperimentAppField.TITLE],
          isVariant,
          variantIndex,
        )}
        rules={[
          { max: 30, message: 'Title should be max 30 symbols!' },
          { required: true },
        ]}
      >
        <TrimmedInput />
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.SUBTITLE,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Subtitle"
        name={getFormFieldName(
          [ExperimentAppField.SUBTITLE],
          isVariant,
          variantIndex,
        )}
        rules={[{ max: 30, message: 'Subtitle should be max 30 symbols!' }]}
      >
        <TrimmedInput />
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.DEVELOPER_NAME,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Developer"
        name={getFormFieldName(
          [ExperimentAppField.DEVELOPER_NAME],
          isVariant,
          variantIndex,
        )}
        rules={[{ required: true }]}
      >
        <TrimmedInput />
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.PRICE,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item label="Price">
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.PRICE],
            isVariant,
            variantIndex,
          )}
        >
          <NumberInputWithCheckbox valueType="float" isCheckedByZero>
            App is free
          </NumberInputWithCheckbox>
        </Form.Item>
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.IS_OFFERS_IN_APP_PURCHASES_SHOWN],
            isVariant,
            variantIndex,
          )}
          valuePropName="checked"
          noStyle
        >
          <Checkbox>Offers in-app purchases</Checkbox>
        </Form.Item>
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.RATING,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Rating"
        name={getFormFieldName(
          [ExperimentAppField.RATING],
          isVariant,
          variantIndex,
        )}
        getValueFromEvent={(currentValue) => currentValue || 0}
      >
        <InputNumber min={0} max={5} style={{ width: '100%' }} />
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.REVIEWS_AMOUNT,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Number of reviews"
        name={getFormFieldName(
          [ExperimentAppField.REVIEWS_AMOUNT],
          isVariant,
          variantIndex,
        )}
        getValueFromEvent={(currentValue) => currentValue || 0}
      >
        <InputNumber min={0} style={{ width: '100%' }} />
      </Form.Item>
    )}
    {isFormFieldShown('reviews', fieldsForVariantTest, isVariant) && (
      <FormItemReviews label="Reviews by rating">
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.ONE_STAR_COUNT],
            isVariant,
            variantIndex,
          )}
        >
          <NumberInputWithRate starsCount={1} />
        </Form.Item>
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.TWO_STAR_COUNT],
            isVariant,
            variantIndex,
          )}
        >
          <NumberInputWithRate starsCount={2} />
        </Form.Item>
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.THREE_STAR_COUNT],
            isVariant,
            variantIndex,
          )}
        >
          <NumberInputWithRate starsCount={3} />
        </Form.Item>
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.FOUR_STAR_COUNT],
            isVariant,
            variantIndex,
          )}
        >
          <NumberInputWithRate starsCount={4} />
        </Form.Item>
        <Form.Item
          name={getFormFieldName(
            [ExperimentAppField.FIVE_STAR_COUNT],
            isVariant,
            variantIndex,
          )}
        >
          <NumberInputWithRate starsCount={5} />
        </Form.Item>
      </FormItemReviews>
    )}
    {isFormFieldShown(
      ExperimentAppField.STORE_POSITION,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Category position"
        name={getFormFieldName(
          [ExperimentAppField.STORE_POSITION],
          isVariant,
          variantIndex,
        )}
      >
        <NumberInputWithCheckbox valueType="integer">
          Show
        </NumberInputWithCheckbox>
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.DESCRIPTION,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Description"
        name={getFormFieldName(
          [ExperimentAppField.DESCRIPTION],
          isVariant,
          variantIndex,
        )}
        rules={[{ required: true }]}
      >
        <TrimmedInput.TextArea rows={5} />
      </Form.Item>
    )}
    {isFormFieldShown('updates', fieldsForVariantTest, isVariant) && (
      <Form.Item label="What's new">
        <UpdateFormItems
          isVariant={isVariant}
          variantIndex={variantIndex}
          formStateFields={formStateFields}
        />
        <div style={{ display: 'flex' }}>
          <Form.Item
            name={getFormFieldName(
              [ExperimentAppField.IS_OFFERS_APPLE_WATCH_APP_SHOWN],
              isVariant,
              variantIndex,
            )}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>Offers Apple Watch App</Checkbox>
          </Form.Item>
          <Form.Item
            name={getFormFieldName(
              [ExperimentAppField.IS_OFFERS_IMESSAGE_APP_SHOWN],
              isVariant,
              variantIndex,
            )}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>Offers iMessage App</Checkbox>
          </Form.Item>
          <Form.Item
            name={getFormFieldName(
              [ExperimentAppField.IS_OFFERS_APPLE_TV_APP_SHOWN],
              isVariant,
              variantIndex,
            )}
            valuePropName="checked"
            noStyle
          >
            <Checkbox>Offers Apple TV App</Checkbox>
          </Form.Item>
        </div>
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.AGE_RATING,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Age Rating"
        name={getFormFieldName(
          [ExperimentAppField.AGE_RATING],
          isVariant,
          variantIndex,
        )}
        rules={[{ required: true }]}
      >
        <TrimmedInput />
      </Form.Item>
    )}
    {isFormFieldShown(
      ExperimentAppField.ICON,
      fieldsForVariantTest,
      isVariant,
    ) && (
      <Form.Item
        label="Icon"
        name={getFormFieldName(
          [ExperimentAppField.ICON],
          isVariant,
          variantIndex,
        )}
      >
        <ImgUploader withPreview showEditButton />
      </Form.Item>
    )}
    {(isFormFieldShown(
      ExperimentAppField.SCREENSHOT_PREVIEWS,
      fieldsForVariantTest,
      isVariant,
    ) ||
      isFormFieldShown(
        ExperimentAppField.VIDEO_PREVIEWS,
        fieldsForVariantTest,
        isVariant,
      )) &&
      APP_PHONE_FIELDS_MAP.map(({ label, value }) => (
        <Form.Item label={label} key={value}>
          {isFormFieldShown(
            ExperimentAppField.SCREENSHOT_PREVIEWS,
            fieldsForVariantTest,
            isVariant,
          ) && (
            <>
              <PreviewMediaTitle>Preview</PreviewMediaTitle>
              <Form.Item
                name={getFormFieldName(
                  [ExperimentAppField.SCREENSHOT_PREVIEWS, value],
                  isVariant,
                  variantIndex,
                )}
                noStyle
              >
                <PhotoPreviewGroup phoneType={value} />
              </Form.Item>
            </>
          )}
          {isFormFieldShown(
            ExperimentAppField.VIDEO_PREVIEWS,
            fieldsForVariantTest,
            isVariant,
          ) && (
            <>
              <PreviewMediaTitle>Video Preview</PreviewMediaTitle>
              <Form.Item
                name={getFormFieldName(
                  [ExperimentAppField.VIDEO_PREVIEWS, value],
                  isVariant,
                  variantIndex,
                )}
                noStyle
                rules={[
                  () => ({
                    validator(_, videoPreviewValue) {
                      const isValidPreview = videoPreviewValue.every(
                        ({ stopScreen, video }) =>
                          (!stopScreen.url && !video.url) ||
                          (stopScreen.url && video.url),
                      )
                      return isValidPreview
                        ? Promise.resolve()
                        : // eslint-disable-next-line prefer-promise-reject-errors
                          Promise.reject('Please upload both image and video!')
                    },
                  }),
                ]}
              >
                <VideoPreviewGroup
                  phoneType={value}
                  id={getIdFromFieldName(
                    getFormFieldName(
                      [ExperimentAppField.VIDEO_PREVIEWS, value],
                      isVariant,
                      variantIndex,
                    ),
                  )}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
      ))}
  </div>
)
