import React, { useCallback, useState } from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { antd } from '@gismart/ui.library/core'
import { Color } from '@gismart/ui.library/core/constants'
import { TFormFieldData, TFormInstance } from '@gismart/ui.library/core/types'
import { TFieldForTest } from 'models/experiments.model'
import { scrollToFirstErrorFormField } from 'modules/experiments/helpers/scrollToFirstErrorFormField'
import { StyledCollapsePanel } from './style'
import { EditingAppFields } from './EditingAppFields'
import { StepControls } from './StepControls'

const { Button, Collapse, Space } = antd

interface IProps {
  form: TFormInstance
  formStateFields: TFormFieldData[]
  isNewExperiment: boolean
  variantsIndexesWithUids: [number, string][]
  fieldsForVariantTest: TFieldForTest[]
  onAddVariant: () => void
  onRemoveVariant: (variantUid: string) => void
  onSubmit: () => void
  onCancel: () => void
}

export const ThirdStep: React.FC<IProps> = ({
  form,
  formStateFields,
  isNewExperiment,
  variantsIndexesWithUids,
  fieldsForVariantTest,
  onAddVariant,
  onRemoveVariant,
  onSubmit,
  onCancel,
}) => {
  const [activeVariantsKeys, setActiveVariantsKeys] = useState<
    string | string[]
  >(variantsIndexesWithUids.find(([index]) => index === 0)?.[1] as string)

  const findFirstPanelWithErrorAndExpand = useCallback(
    (errorFields) => {
      const [firstErrorVariantName] = errorFields[0].name
      const firstErrorPanelIndex = firstErrorVariantName.split('-')[0]

      const panelToExpand = variantsIndexesWithUids.find(
        ([index]) => index === Number(firstErrorPanelIndex),
      )
      setActiveVariantsKeys(panelToExpand?.[1] || [])
    },
    [variantsIndexesWithUids],
  )

  const handleContinue = useCallback(async () => {
    try {
      await form.validateFields()
      onSubmit()
    } catch (e: any) {
      if (e.errorFields) {
        findFirstPanelWithErrorAndExpand(e.errorFields)
        setTimeout(() => scrollToFirstErrorFormField(e.errorFields), 300)
      }
    }
  }, [findFirstPanelWithErrorAndExpand, form, onSubmit])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 24,
        }}
      >
        <Space>
          <Button
            onClick={() => {
              setActiveVariantsKeys(
                variantsIndexesWithUids.map((item) => item[1]),
              )
            }}
          >
            Expand All
          </Button>
          <Button
            onClick={() => {
              setActiveVariantsKeys([])
            }}
          >
            Collapse All
          </Button>
        </Space>
        <Button
          type="primary"
          icon={<MaterialIcon type="plus" />}
          onClick={onAddVariant}
        >
          Add Variant
        </Button>
      </div>
      <Collapse
        bordered={false}
        activeKey={activeVariantsKeys}
        onChange={setActiveVariantsKeys}
        style={{ backgroundColor: Color.WHITE }}
      >
        {variantsIndexesWithUids.map(([variantIndex, variantUid], index) => (
          <StyledCollapsePanel
            header={`Variant ${index + 1}`}
            key={variantUid}
            forceRender
            extra={
              variantsIndexesWithUids.length > 1 ? (
                <MaterialIcon
                  type="delete"
                  onClick={(e) => {
                    e.stopPropagation()
                    onRemoveVariant(variantUid)
                  }}
                />
              ) : null
            }
          >
            <EditingAppFields
              fieldsForVariantTest={fieldsForVariantTest}
              formStateFields={formStateFields}
              isVariant
              variantIndex={variantIndex}
            />
          </StyledCollapsePanel>
        ))}
      </Collapse>
      <StepControls
        continueText={isNewExperiment ? 'Create' : 'Update'}
        onBack={onCancel}
        onContinue={handleContinue}
      />
    </>
  )
}
