import compose from 'lodash/fp/compose'
import { map } from 'lodash'
import { TFieldForTest, TRawFieldForTest } from 'models/experiments.model'
import { TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP } from '../constants'
import { getFormTestFieldsFromRaw } from './getFormTestFieldsFromRaw'

export const getTitleNamesFromRawFieldsForTest = (
  rawFieldsNames: TRawFieldForTest[],
): string[] =>
  compose(
    (fieldsNames: TFieldForTest[]) =>
      map(
        fieldsNames,
        (fieldName) => TITLE_NAMES_OF_FIELDS_FOR_TEST_MAP[fieldName],
      ),
    getFormTestFieldsFromRaw,
  )(rawFieldsNames)
