import React, { useState } from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { antd } from '@gismart/ui.library/core'
import { Chart } from 'components/Chart'
import { ViewConfig } from '@antv/g2plot'
import { Container, Title, Value, StyledChart } from './style'

const { Tooltip } = antd
const { plotTypes } = Chart

const options: ViewConfig = {
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  legend: {
    visible: false,
  },
}

interface IProps {
  data: any[]
  config: ViewConfig
  title?: string
  helpText?: string
  number?: number | string
  chartHeight?: string
  type?: string
}

export const StatisticChart: React.FC<IProps> = ({
  data,
  config,
  title = '',
  number = null,
  helpText = '',
  type = 'area',
  chartHeight = '',
}) => {
  const [chartConfig] = useState({ ...options, ...config })

  return (
    <Container>
      <Title>
        <span>{title}</span>
        {helpText && (
          <Tooltip title={helpText}>
            <MaterialIcon type="help-circle-outline" />
          </Tooltip>
        )}
      </Title>
      <Value>{number}</Value>
      <StyledChart
        height={chartHeight}
        type={plotTypes[type]}
        data={data}
        options={chartConfig}
      />
    </Container>
  )
}
