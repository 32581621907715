import React, { useEffect } from 'react'
import { Overview, Header } from './components'
import { TStateProps, TDispatchProps } from './Container'

type TProps = TStateProps & TDispatchProps

export const ExperimentStatisticComponent: React.FC<TProps> = ({
  initialData,
  statistic,
  fetchStatistic,
  resetStatistic,
  resetInitialData,
}) => {
  useEffect(
    () => () => {
      resetStatistic()
      resetInitialData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(() => {
    if (initialData?.id && initialData?.publishedAt) {
      fetchStatistic(initialData.id)
    }
  }, [initialData, fetchStatistic])

  if (initialData?.id && !initialData?.publishedAt) {
    // TODO: add button to config
    // TODO: add button to publish
    return <div> Experiment not published yet </div>
  }

  return (
    <>
      <Header />
      <Overview
        previewUrl={initialData?.previewUrl || ''}
        statistic={statistic}
      />
    </>
  )
}
