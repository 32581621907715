import {
  ApiService,
  IRequestOptions,
  IRequestParams,
  IResponseResult,
  RequestMethod,
} from '@gismart/ui.library/core/services/api'
import {
  ICreateGoogleLanding,
  IGoogleLandingsResponse,
  IUpdateGoogleLanding,
} from 'models/landings.google.model'

export class GoogleLandingsApi {
  protected fetchLandingsController: AbortController | undefined
  protected api: ApiService
  protected googleLandingsPath = 'google_landings'

  constructor(api: ApiService) {
    this.api = api
  }

  fetchLandings(
    title: string,
  ): Promise<IResponseResult<IGoogleLandingsResponse>> {
    this.fetchLandingsController && this.fetchLandingsController.abort()
    this.fetchLandingsController = new AbortController()

    const options: IRequestOptions = {
      method: RequestMethod.GET,
      signal: this.fetchLandingsController.signal,
      ...(title && { params: ({ title } as unknown) as IRequestParams }),
    }

    return this.api.makeRequest<IGoogleLandingsResponse>(
      this.googleLandingsPath,
      options,
    )
  }

  createLanding(
    payload: ICreateGoogleLanding,
  ): Promise<IResponseResult<IGoogleLandingsResponse>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: payload,
    }

    return this.api.makeRequest<IGoogleLandingsResponse>(
      this.googleLandingsPath,
      options,
    )
  }

  updateLanding(
    payload: IUpdateGoogleLanding,
  ): Promise<IResponseResult<IGoogleLandingsResponse>> {
    const { id, ...body } = payload
    const options: IRequestOptions = {
      method: RequestMethod.PUT,
      body,
    }

    return this.api.makeRequest<IGoogleLandingsResponse>(
      `${this.googleLandingsPath}/${id}`,
      options,
    )
  }
}
