import {
  IEditGoogleLandingFormValues,
  IGoogleLanding,
} from 'models/landings.google.model'
import { GoogleLandingEditFieldName } from '../constants'

export const getGoogleLandingFormValues = (
  editedLanding?: IGoogleLanding,
): IEditGoogleLandingFormValues => ({
  [GoogleLandingEditFieldName.STORE_LINK]: editedLanding?.storeLink || '',
  [GoogleLandingEditFieldName.PIXEL_ID]: editedLanding?.pixelId || '',
  [GoogleLandingEditFieldName.TITLE]: editedLanding?.title || '',
  [GoogleLandingEditFieldName.ICON]: editedLanding?.iconUrl || '',
  [GoogleLandingEditFieldName.IMAGE_PREVIEWS]:
    editedLanding?.imagePreviews || [],
  [GoogleLandingEditFieldName.DESCRIPTION]: editedLanding?.description || '',
})
