import { antd } from '@gismart/ui.library/core'
import { TrimmedInput } from 'components/TrimmedInput'
import { hasDuplicates } from 'helpers/hasDuplicates'
import { ILandingVariant } from 'models/common.model'
import React from 'react'
import { useSelector } from 'react-redux'
import { DEFAULT_VARIANT, VARIANTS_FIELD_NAME } from 'root-constants'
import { selectIsFetching } from 'root-redux/selects/common'

const { Form, Checkbox } = antd

type TProps = {
  fieldName: string
  form: antd.FormInstance
  label: string
  checkboxLabel: string
  isChecked: boolean
  handleCheckboxChange: (value: boolean) => void
  isSourceRequired: boolean
  isRequired: boolean
}

export const FormItemAttrGridVariant: React.FC<TProps> = ({
  fieldName,
  form,
  label,
  checkboxLabel,
  isChecked,
  handleCheckboxChange,
  isSourceRequired,
  isRequired,
}) => {
  const isFetching = useSelector(selectIsFetching)

  return (
    <>
      <Form.Item
        label={label}
        name={[VARIANTS_FIELD_NAME, fieldName]}
        rules={[
          () => ({
            validator() {
              return isRequired
                ? Promise.reject(
                    new Error('Please select at least one attribution grid!'),
                  )
                : Promise.resolve()
            },
          }),
        ]}
      >
        <Checkbox
          checked={isChecked}
          onChange={(e) => {
            const variantFieldValue = form.getFieldValue([
              VARIANTS_FIELD_NAME,
              fieldName,
            ])

            if (!variantFieldValue && e.target.checked) {
              form.setFieldsValue({
                [VARIANTS_FIELD_NAME]: {
                  [fieldName]: DEFAULT_VARIANT,
                },
              })
            }

            form.validateFields()
            handleCheckboxChange(e.target.checked)
          }}
          disabled={isFetching}
        >
          {checkboxLabel}
        </Checkbox>
      </Form.Item>
      {isChecked && (
        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, sm: { offset: 6 } }}
          style={{ marginBottom: 0 }}
        >
          <Form.Item
            label="Source"
            name={[VARIANTS_FIELD_NAME, fieldName, 'source']}
            rules={[
              { required: isSourceRequired },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const hasOneLinksDuplicates = hasDuplicates(
                    Object.values<ILandingVariant | null>(
                      getFieldValue(VARIANTS_FIELD_NAME),
                    ).map((fieldValue) => fieldValue?.source),
                  )

                  return !value || !hasOneLinksDuplicates
                    ? Promise.resolve()
                    : Promise.reject(new Error('Not unique value!'))
                },
              }),
            ]}
          >
            <TrimmedInput disabled={isFetching} />
          </Form.Item>
          <Form.Item
            label="Pixel ID"
            name={[VARIANTS_FIELD_NAME, fieldName, 'pixelId']}
            rules={[{ required: true }]}
          >
            <TrimmedInput disabled={isFetching} />
          </Form.Item>
          <Form.Item
            label="One Link URL"
            name={[VARIANTS_FIELD_NAME, fieldName, 'oneLink']}
            rules={[
              {
                required: true,
              },
              {
                pattern: /(https:\/\/)(([^\\.][^\\.]+).)(.*\/)(.*)/, // taken from SmartScript 2.2.0
                message: 'One Link is not valid!',
              },
            ]}
          >
            <TrimmedInput disabled={isFetching} />
          </Form.Item>
        </Form.Item>
      )}
    </>
  )
}
