import styled from 'styled-components'
import { Chart } from 'components/Chart'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Title = styled.div`
  padding-right: 35px;

  & > span {
    padding-right: 5px;
  }
`

export const Value = styled.div`
  padding-right: 35px;
  font-size: 25px;
`

export const StyledChart = styled(Chart)`
  width: 100%;
  height: ${(props) => props.height};
  margin-top: -25px;
`
