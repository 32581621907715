import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { InfoLine } from 'components/InfoLine'
import { STATISTIC_DATE_FORMAT } from 'modules/experiments/constants'
import { Title, Container } from './style'
import { ExperimentLink } from '../../../ExperimentLink'
import { TDispatchProps, TStateProps } from './Container'

const { Button, Modal } = antd
const { BoldText, LowerCaseText, InfoItem } = InfoLine

type TProps = TStateProps & TDispatchProps

export const HeaderComponent: React.FC<TProps> = ({
  initialData,
  fetchStatistic,
  publishExperiment,
  finishExperiment,
}) => {
  const confirmFinish = useCallback(() => {
    Modal.confirm({
      title: 'Finish',
      content: 'Are you sure you want to finish this experiment?',
      okText: 'Finish',
      onOk() {
        initialData?.id && finishExperiment(initialData.id)
      },
    })
  }, [finishExperiment, initialData])

  const confirmPublish = useCallback(() => {
    Modal.confirm({
      title: 'Publish',
      content: 'Are you sure you want to publish this experiment?',
      okText: 'Publish',
      onOk() {
        initialData?.id && publishExperiment(initialData.id)
      },
    })
  }, [publishExperiment, initialData])

  return (
    <Container>
      <Title>{initialData?.title || ''}</Title>

      <InfoLine>
        {(initialData?.tests || []).map((test) => (
          <InfoItem key={test}>
            <BoldText>{test}</BoldText>
          </InfoItem>
        ))}
        {!initialData?.publishedAt && [
          <InfoItem key="created">
            Created:
            {initialData?.createdAt
              ? dayjs(initialData.createdAt).format(STATISTIC_DATE_FORMAT)
              : ''}
          </InfoItem>,
          <InfoItem noDelimetr key="notLaunched">
            <BoldText>Not launched</BoldText>
            <LowerCaseText> yet</LowerCaseText>
          </InfoItem>,
        ]}
        {initialData?.publishedAt &&
          !initialData?.finishedAt && [
            <InfoItem key="launched">
              {`Launched: ${
                initialData?.publishedAt
                  ? dayjs(initialData.publishedAt).format(STATISTIC_DATE_FORMAT)
                  : ''
              }`}
            </InfoItem>,
            <InfoItem noDelimetr key="launchedDate">
              <BoldText>Running</BoldText>
              <LowerCaseText> now</LowerCaseText>
            </InfoItem>,
          ]}
        {initialData?.publishedAt &&
          initialData?.finishedAt && [
            <InfoItem key="finished">
              {`Finished: ${
                initialData?.finishedAt
                  ? dayjs(initialData.finishedAt).format(STATISTIC_DATE_FORMAT)
                  : ''
              }`}
            </InfoItem>,
            <InfoItem noDelimetr key="finishedDate">
              <BoldText>Finished</BoldText>
              <LowerCaseText> now</LowerCaseText>
            </InfoItem>,
          ]}
      </InfoLine>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '15px',
        }}
      >
        <div>
          <Button.Group>
            <ExperimentLink link={initialData?.url} />
            {!initialData?.publishedAt && !initialData?.finishedAt && (
              <Button
                icon={<MaterialIcon type="play-box" />}
                onClick={confirmPublish}
              >
                Publish
              </Button>
            )}
            {initialData?.publishedAt && !initialData?.finishedAt && (
              <Button
                icon={<MaterialIcon type="check" />}
                onClick={confirmFinish}
              >
                Finish
              </Button>
            )}
          </Button.Group>
        </div>
        <div>
          <Button
            icon={<MaterialIcon type="sync" />}
            onClick={() => {
              initialData?.id && fetchStatistic(initialData.id)
            }}
          >
            Refresh statistic
          </Button>
        </div>
      </div>
    </Container>
  )
}
