import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { selectExperimentInitialData } from 'modules/experiments/redux/selects'
import { HeaderComponent } from './Component'
import {
  fetchStatisticAction,
  publishExperimentAction,
  finishExperimentAction,
} from '../../../../redux/actions'

const mapStateToProps = (state: IAppState) => ({
  initialData: selectExperimentInitialData(state),
})

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  fetchStatistic: (id: number) => dispatch(fetchStatisticAction(id)),
  publishExperiment: (id: number) => dispatch(publishExperimentAction(id)),
  finishExperiment: (id: number) => dispatch(finishExperimentAction({ id })),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderComponent)
