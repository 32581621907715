import { antd } from '@gismart/ui.library/core'
import { FormItemAttrGridVariant } from 'components/FormItemAttrGridVariant'
import { TrimmedInput } from 'components/TrimmedInput'
import { validateAppLink } from 'helpers/validateAppLink'
import isEqual from 'lodash/isEqual'
import { IEditLandingFormValues } from 'models/landings.model'
import { selectLandings } from 'modules/landings/redux/selects'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IS_EQUAL_PRIORITY_FIELD_NAME } from 'root-constants'
import { selectCommonError, selectIsFetching } from 'root-redux/selects/common'
import { getLandingFormValues } from '../helpers'
import { createLandingAction, updateLandingAction } from '../redux/actions'
import { StyledModal } from './style'

const { Form, Checkbox } = antd
const APP_LINK_FIELD_NAME = 'appLink'

interface IProps {
  isShown: boolean
  handleCloseModal: () => void
  landingId: string | undefined
  resetPaginationPage: () => void
}

export const LandingEditModal: React.FC<IProps> = ({
  isShown,
  landingId,
  handleCloseModal,
  resetPaginationPage,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const landings = useSelector(selectLandings)
  const commonError = useSelector(selectCommonError)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [isFbPixelChecked, setIsFbPixelChecked] = useState(false)
  const [isSnapPixelChecked, setIsSnapPixelChecked] = useState(false)
  const [isTikTokPixelChecked, setIsTikTokPixelChecked] = useState(false)

  const editedLanding = useMemo(
    () => landings.find((landing) => landing.id === Number(landingId)),
    [landingId, landings],
  )
  const isCreateMode = useMemo(() => !editedLanding, [editedLanding])
  const initialLandingFormValues: IEditLandingFormValues = useMemo(
    () => getLandingFormValues(editedLanding),
    [editedLanding],
  )
  const resetedLandingFormValues: IEditLandingFormValues = useMemo(
    () => getLandingFormValues(),
    [],
  )
  const isVariantRequired = useMemo(
    () => !isFbPixelChecked && !isSnapPixelChecked && !isTikTokPixelChecked,
    [isFbPixelChecked, isSnapPixelChecked, isTikTokPixelChecked],
  )
  const isFbPixelCheckedInitial = useMemo(
    () => !!initialLandingFormValues.variants.fb,
    [initialLandingFormValues.variants.fb],
  )
  const isSnapPixelCheckedInitial = useMemo(
    () => !!initialLandingFormValues.variants.snap,
    [initialLandingFormValues.variants.snap],
  )
  const isTikTokPixelCheckedInitial = useMemo(
    () => !!initialLandingFormValues.variants.tiktok,
    [initialLandingFormValues.variants.tiktok],
  )

  useEffect(() => {
    form.setFieldsValue(initialLandingFormValues)
    setIsFbPixelChecked(isFbPixelCheckedInitial)
    setIsSnapPixelChecked(isSnapPixelCheckedInitial)
    setIsTikTokPixelChecked(isTikTokPixelCheckedInitial)
  }, [
    initialLandingFormValues,
    form,
    isFbPixelCheckedInitial,
    isSnapPixelCheckedInitial,
    isTikTokPixelCheckedInitial,
  ])

  const handleCancel = useCallback(() => {
    handleCloseModal()
    form.setFieldsValue(resetedLandingFormValues)
    setIsFormChanged(false)
    setIsFbPixelChecked(!!resetedLandingFormValues.variants.fb)
    setIsSnapPixelChecked(!!resetedLandingFormValues.variants.snap)
    setIsTikTokPixelChecked(!!resetedLandingFormValues.variants.tiktok)

    if (!isCreateMode) {
      return
    }
    resetPaginationPage()
  }, [
    form,
    handleCloseModal,
    isCreateMode,
    resetPaginationPage,
    resetedLandingFormValues,
  ])

  const handleOk = useCallback(async () => {
    try {
      const values = await form.validateFields()

      const variants = {
        fb: isFbPixelChecked ? values.variants.fb : null,
        snap: isSnapPixelChecked ? values.variants.snap : null,
        tiktok: isTikTokPixelChecked ? values.variants.tiktok : null,
      }

      isCreateMode
        ? dispatch(createLandingAction({ ...values, variants }, handleCancel))
        : dispatch(
            updateLandingAction(
              { id: +(landingId as string), ...values, variants },
              handleCancel,
            ),
          )
    } catch (error) {
      //
    }
  }, [
    dispatch,
    form,
    handleCancel,
    isCreateMode,
    isFbPixelChecked,
    isSnapPixelChecked,
    isTikTokPixelChecked,
    landingId,
  ])

  const handleValuesChange = useCallback(
    (values: IEditLandingFormValues) => {
      const isFormUpdated = !isEqual(values, initialLandingFormValues)

      setIsFormChanged(isFormUpdated)
    },
    [initialLandingFormValues],
  )

  return (
    <StyledModal
      title={isCreateMode ? 'Create Landing' : 'Edit Landing'}
      visible={isShown}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isFetching}
      okText="Save"
      okButtonProps={{
        disabled:
          !isFormChanged &&
          isFbPixelChecked === isFbPixelCheckedInitial &&
          isSnapPixelChecked === isSnapPixelCheckedInitial &&
          isTikTokPixelChecked === isTikTokPixelCheckedInitial,
      }}
      destroyOnClose
      width="800px"
    >
      <Form
        form={form}
        name="edit-landing"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 21 }}
        validateMessages={{ required: 'This field is required!' }}
        initialValues={resetedLandingFormValues}
        onValuesChange={(_, values) => {
          handleValuesChange(values)
        }}
      >
        {isCreateMode && (
          <Form.Item
            label="App Link"
            name={APP_LINK_FIELD_NAME}
            required
            rules={[validateAppLink(commonError)]}
          >
            <TrimmedInput disabled={isFetching} />
          </Form.Item>
        )}
        <FormItemAttrGridVariant
          fieldName="fb"
          form={form}
          label="Facebook"
          checkboxLabel="Use Facebook Pixel"
          isChecked={isFbPixelChecked}
          isSourceRequired
          isRequired={isVariantRequired}
          handleCheckboxChange={(value: boolean) => {
            setIsFbPixelChecked(value)
          }}
        />
        <FormItemAttrGridVariant
          fieldName="snap"
          form={form}
          label="Snapchat"
          checkboxLabel="Use Snapchat Pixel"
          isChecked={isSnapPixelChecked}
          isRequired={isVariantRequired}
          isSourceRequired
          handleCheckboxChange={(value: boolean) => {
            setIsSnapPixelChecked(value)
          }}
        />
        <FormItemAttrGridVariant
          fieldName="tiktok"
          form={form}
          label="TikTok"
          checkboxLabel="Use TikTok Pixel"
          isChecked={isTikTokPixelChecked}
          isRequired={isVariantRequired}
          isSourceRequired
          handleCheckboxChange={(value: boolean) => {
            setIsTikTokPixelChecked(value)
          }}
        />
        <Form.Item
          label="Equal Priority"
          name={IS_EQUAL_PRIORITY_FIELD_NAME}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </StyledModal>
  )
}
