/* eslint-disable no-unused-vars */
import dayjs from 'dayjs'
import {
  StatisticName,
  STATISTIC_DATE_FORMAT,
} from 'modules/experiments/constants'
import {
  IRawCustomStatistics,
  IRawStatisticByDays,
  IStatistics,
  IStatisticByDays,
} from 'models/experiments.statistic.model'

const getStatisticsByDayFromRaw = (
  rawStatisticsByDays: IRawStatisticByDays[],
): IStatisticByDays[] =>
  rawStatisticsByDays?.map((item) => ({
    date: dayjs(item.date).format(STATISTIC_DATE_FORMAT),
    value: item.value,
  })) || []

export const getStatisticItemFromRaw = (
  data: IRawCustomStatistics,
  statisticName?: StatisticName,
): IStatistics => ({
  uniqueIphoneUsers: {
    total: data.iphoneVisitors.total,
    byDate: getStatisticsByDayFromRaw(data.iphoneVisitors.byDate),
  },
  uniqueNotIphoneUsers: {
    total: data.notIphoneVisitors.total,
    byDate: getStatisticsByDayFromRaw(data.notIphoneVisitors.byDate),
  },
  iphoneInstalls: {
    total: data.iphoneInstalls.total,
    byDate: getStatisticsByDayFromRaw(data.iphoneInstalls.byDate),
  },
  variants: data.variants || [],
})
