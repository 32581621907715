import { TPagination } from 'types'

export const getPaginationAfterChange = ({
  updatedPage,
  updatedLimit,
  currentLimit,
}: {
  updatedPage: number
  updatedLimit: number
  currentLimit: number
}): TPagination => ({
  page: updatedLimit === currentLimit ? updatedPage : 1, // if limit was changed go to the first page
  limit: updatedLimit,
})
