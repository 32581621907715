import { useEffect, useRef } from 'react'
import { goTo, routeHistory } from 'route-history'
import { antd } from '@gismart/ui.library/core'

const { Modal } = antd

export const useBlockAppRouteTransition = (
  isTransitionBlocked: boolean,
): any => {
  const unblockHandler = useRef<any>(null)

  useEffect(() => {
    unblockHandler.current =
      isTransitionBlocked &&
      routeHistory.block(({ pathname }) => {
        Modal.confirm({
          title: 'Confirm your action',
          content:
            'Leaving this page will lose your changes. Are you sure you want to leave this page?',
          okText: 'Yes',
          onOk: () => {
            unblockHandler.current && unblockHandler.current()
            goTo(pathname)
          },
        })
        return false
      })

    return () => {
      if (unblockHandler.current) {
        unblockHandler.current()
      }
    }
  }, [isTransitionBlocked])

  return unblockHandler.current
}
