import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { goTo } from 'route-history'
import { Logo } from './Logo'

const { Layout, Menu } = antd
const { Header: LayoutHeader } = Layout

export const Header: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <>
      <LayoutHeader style={{ padding: 0 }}>
        <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <Logo height="28px" style={{ padding: '0 20px' }} />
        </Link>
        <Menu
          mode="horizontal"
          theme="dark"
          selectable
          selectedKeys={[
            pathname.includes('/applications') ? '/applications' : pathname,
          ]}
          style={{ flexGrow: 1 }}
        >
          <Menu.Item key="/applications" onClick={() => goTo('/applications')}>
            App Store A/B Tests
          </Menu.Item>
          <Menu.SubMenu
            title="Landings"
            icon={<MaterialIcon type="chevron-down" />}
            key="landings-menu"
          >
            <Menu.Item key="/landings" onClick={() => goTo('/landings')}>
              App Store Landings
            </Menu.Item>
            <Menu.Item
              key="/googleLandings"
              onClick={() => goTo('/googleLandings')}
            >
              Google Play Landings
            </Menu.Item>
            {/* <Menu.Item
              key="/autoclickLandings"
              onClick={() => goTo('/autoclickLandings')}
            >
              Autoclick Landings
            </Menu.Item> */}
            <Menu.Item
              key="/customLandings"
              onClick={() => goTo('/customLandings')}
            >
              Web Custom Landings
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <Menu
          mode="horizontal"
          theme="dark"
          selectable={false}
          style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Menu.Item
            key="log out"
            icon={<MaterialIcon type="logout" />}
            onClick={() => goTo('/logout')}
          >
            Log out
          </Menu.Item>
        </Menu>
      </LayoutHeader>
    </>
  )
}
