import React, { useEffect, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { antd } from '@gismart/ui.library/core'
import { PAGE_SIZE_OPTIONS } from 'root-constants'
import { FilterWrapper } from 'components/style'
import { getPaginationAfterChange } from 'helpers/getPaginationAfterChange'
import { ApplicationsList } from '../ApplicationsList'
import { ApplicationsFilter } from '../ApplicationsFilter'
import { TStateProps, TDispatchProps } from './Container'

const { Pagination } = antd

type TProps = TStateProps & TDispatchProps

export const ApplicationsWrapperComponent: React.FC<TProps> = ({
  filters,
  applications,
  appsCount,
  fetchApps,
  updateFilters,
  resetFilters,
}) => {
  const { url } = useRouteMatch()

  useEffect(
    () => () => {
      resetFilters()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    fetchApps(filters)
  }, [filters, fetchApps])

  const handleFiltersChange = useCallback(
    (newFilters) => {
      updateFilters({
        ...filters,
        ...newFilters,
        page: 1,
      })
    },
    [filters, updateFilters],
  )

  const handlePaginationChange = useCallback(
    (updatedPage, updatedLimit) => {
      updateFilters({
        ...filters,
        ...getPaginationAfterChange({
          updatedPage,
          updatedLimit,
          currentLimit: filters.limit,
        }),
      })
    },
    [filters, updateFilters],
  )

  return (
    <>
      <FilterWrapper>
        <ApplicationsFilter
          filters={filters}
          onFiltersChange={handleFiltersChange}
        />
      </FilterWrapper>
      <ApplicationsList applications={applications} url={url} />
      <Pagination
        showSizeChanger
        total={appsCount}
        current={filters.page}
        pageSize={filters.limit}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onChange={handlePaginationChange}
        style={{
          textAlign: 'center',
          padding: '20px',
        }}
      />
    </>
  )
}
