import {
  ApiService,
  IRequestOptions,
  IRequestParams,
  IResponseResult,
  RequestMethod,
} from '@gismart/ui.library/core/services/api'
import {
  ICustomLandingsResponse,
  TCreateCustomLanding,
  IUpdateCustomLanding,
} from 'models/landings.custom.model'

export class CustomLandingsApi {
  protected fetchLandingsController: AbortController | undefined
  protected api: ApiService
  protected landingsPath = 'custom_landings'

  constructor(api: ApiService) {
    this.api = api
  }

  fetchLandings(
    title: string,
  ): Promise<IResponseResult<ICustomLandingsResponse>> {
    this.fetchLandingsController && this.fetchLandingsController.abort()
    this.fetchLandingsController = new AbortController()

    const options: IRequestOptions = {
      method: RequestMethod.GET,
      signal: this.fetchLandingsController.signal,
      ...(title && { params: ({ title } as unknown) as IRequestParams }),
    }

    return this.api.makeRequest<ICustomLandingsResponse>(
      this.landingsPath,
      options,
    )
  }

  createLanding(
    payload: TCreateCustomLanding,
  ): Promise<IResponseResult<ICustomLandingsResponse>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: payload,
    }

    return this.api.makeRequest<ICustomLandingsResponse>(
      this.landingsPath,
      options,
    )
  }

  updateLanding(
    payload: IUpdateCustomLanding,
  ): Promise<IResponseResult<ICustomLandingsResponse>> {
    const { id, ...body } = payload
    const options: IRequestOptions = {
      method: RequestMethod.PUT,
      body,
    }

    return this.api.makeRequest<ICustomLandingsResponse>(
      `${this.landingsPath}/${id}`,
      options,
    )
  }
}
