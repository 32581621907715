import React, { useCallback, useState } from 'react'
import { goTo } from 'route-history'
import { CARDS_LIST_DEFAULT_GRID_CONFIG } from 'root-constants'
import { IApplication } from 'models/applications.model'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { InfoLine } from 'components/InfoLine'
import { Card } from 'components/Card'
import { List } from 'components/List'
import { StyledAddButton, EditTagButton } from './style'
import { EditTagModal } from '../EditTagModal'

const { BoldText, InfoItem } = InfoLine

interface IProps {
  applications: IApplication[]
  url: string
}

export const ApplicationsList: React.FC<IProps> = ({ applications, url }) => {
  const [editingApplication, setEditingApplication] = useState(null)
  const onAdd = useCallback(() => {
    goTo(`${url}/new`)
  }, [url])

  const onClickRow = useCallback(
    (id) => {
      goTo(`${url}/${id}`)
    },
    [url],
  )

  const onEditTag = useCallback((e, data) => {
    e.stopPropagation()
    setEditingApplication(data)
  }, [])

  return (
    <>
      <EditTagModal
        application={editingApplication}
        isVisible={!!editingApplication}
        closeModal={() => {
          setEditingApplication(null)
        }}
      />
      <List
        style={{ padding: '25px', background: '#fff' }}
        grid={CARDS_LIST_DEFAULT_GRID_CONFIG}
        data={applications}
        renderItem={(application) => {
          const {
            id,
            icon: { url: imgUrl },
            title,
            publishedExperiments,
            totalExperiments,
            finishedExperiments,
            tags,
          } = application
          return (
            <Card
              title={title}
              description={
                <>
                  <InfoLine>
                    <InfoItem>
                      <span>New: </span>
                      <BoldText>
                        {totalExperiments -
                          (finishedExperiments + publishedExperiments)}
                      </BoldText>
                    </InfoItem>
                    <InfoItem>
                      <span>Published: </span>
                      <BoldText>{publishedExperiments}</BoldText>
                    </InfoItem>
                    <InfoItem noDelimetr>
                      <span>Finished: </span>
                      <BoldText>{finishedExperiments}</BoldText>
                    </InfoItem>
                  </InfoLine>
                  <InfoLine>
                    <InfoItem noDelimetr>
                      <span>Tags: {tags ? tags.join(', ') : '-'}</span>
                    </InfoItem>
                  </InfoLine>
                </>
              }
              imgUrl={imgUrl}
              onClick={() => onClickRow(id)}
              imgStyle={{ borderRadius: '5px', width: '50px', height: '50px' }}
              actions={[
                <EditTagButton
                  key="editTag"
                  onClick={(e) => onEditTag(e, application)}
                >
                  Edit tags
                  <MaterialIcon type="tag" size="1.2em" />
                </EditTagButton>,
              ]}
            />
          )
        }}
        renderAddButton={() => <StyledAddButton onClick={onAdd} />}
      />
    </>
  )
}
