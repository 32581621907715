import { LoginPage as LoginPageFromUIKit } from '@gismart/ui.library/core/components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loginAction } from 'root-redux/actions/auth'

export const Login: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loginAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoginPageFromUIKit appName="Gismetrics" />
}
