import { Action, ActionCreator } from 'redux'

const MODULE_NAME = 'COMMON'

export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`

export const startFetching: ActionCreator<Action> = (action: Action) => ({
  type: START_FETCHING,
  payload: action,
})

export const stopFetching = (actionToStop: string): any => (
  dispatch,
  getState,
) => {
  const runningActions = getState().common.actionList
  const fetchList = runningActions.filter(
    (action) => action && action !== actionToStop,
  )
  dispatch({
    type: STOP_FETCHING,
    payload: fetchList,
  })
}

export const setError: ActionCreator<Action> = (error: string) => ({
  type: SET_ERROR,
  payload: error,
})

export const resetError: ActionCreator<Action> = () => ({
  type: RESET_ERROR,
})
