import { ApiService } from '@gismart/ui.library/core/services/api'
import { ServerErrorCode } from '@gismart/ui.library/core/constants'
import { goTo } from 'route-history'

import { ApplicationsApi } from './ApplicationsApi'
import { AuthApi } from './AuthApi'
import { ExperimentsApi } from './ExperimentsApi'
import { FileApi } from './FileApi'
import { ParserApi } from './ParserApi'
import { PixelApi } from './PixelApi'
import { LandingsApi } from './LandingsApi'
import { GoogleLandingsApi } from './GoogleLandingsApi'
import { CustomLandingsApi } from './CustomLandingsApi'

/**
 * API_PREFIX - declare in webpack.config.js as a global variable
 */
declare const API_PREFIX: string

const errorCallback = (error: any) => {
  if (error.status === ServerErrorCode.UNAUTHORIZED) {
    goTo('/login')
  }
}
const api = new ApiService(API_PREFIX, errorCallback)

export const applicationsApi = new ApplicationsApi(api)
export const authApi = new AuthApi(api)
export const experimentsApi = new ExperimentsApi(api)
export const fileApi = new FileApi(api)
export const parserApi = new ParserApi(api)
export const pixelApi = new PixelApi(api)
export const landingsApi = new LandingsApi(api)
export const googleLandingsApi = new GoogleLandingsApi(api)
export const customLandingsApi = new CustomLandingsApi(api)
