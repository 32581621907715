import React, { useEffect } from 'react'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { NotFound } from 'pages'
import { TAppDispatchThunk } from 'models/store.models'
import { stopFetching } from 'root-redux/actions/common'
import { ContentWrapper } from 'components/ContentWrapper'
import { ExperimentStatistic } from './ExperimentStatistic'
import { FETCH_STATISTIC, fetchExperimentAction } from '../redux/actions'
import { ExperimentEditing } from './ExperimentEditing/ExperimentEditing'

type TProps = TDispatchProps & {
  backLink: string
}

const ExperimentComponent: React.FC<TProps> = ({
  backLink,
  fetchExperiment,
  stopFetchingStatistic,
}) => {
  const { path } = useRouteMatch()
  const { experimentId }: Record<string, string> = useParams()

  useEffect(() => {
    if (experimentId) {
      fetchExperiment(parseInt(experimentId, 10))
    }
  }, [experimentId, fetchExperiment])

  useEffect(() => {
    const handleBrowserBackAction = () => stopFetchingStatistic()

    window.addEventListener('popstate', handleBrowserBackAction)

    return () => {
      setTimeout(() =>
        window.removeEventListener('popstate', handleBrowserBackAction),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route
        path={path}
        exact
        render={() => (
          <ContentWrapper>
            <ExperimentEditing backLink={backLink} />
          </ContentWrapper>
        )}
      />
      <Route
        path={`${path}/statistic`}
        render={() => (
          <ContentWrapper>
            <ExperimentStatistic />
          </ContentWrapper>
        )}
      />
      <Route component={NotFound} />
    </Switch>
  )
}

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  stopFetchingStatistic: () => dispatch(stopFetching(FETCH_STATISTIC)),
  fetchExperiment: (id: number) => dispatch(fetchExperimentAction(id)),
})

type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const Experiment = connect(null, mapDispatchToProps)(ExperimentComponent)
