import React, { useRef, useEffect, useState } from 'react'
import Hls from 'hls.js'

const HLS_TYPE = '.m3u8'

const hlsConfig = {
  fetchSetup: (context, initParams) =>
    new Request(context.url, {
      ...initParams,
      credentials: 'include',
    }),
}

export const Video: React.FC<React.VideoHTMLAttributes<HTMLVideoElement>> = ({
  src = '',
  ...props
}) => {
  const [hls, setHls] = useState(new Hls())
  const videoEl = useRef(null)

  useEffect(() => {
    if (!src || !src.endsWith(HLS_TYPE)) {
      return undefined
    }
    hls.destroy()
    const newHls = new Hls(hlsConfig)
    newHls.attachMedia(videoEl.current)
    newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
      newHls.loadSource(src)
    })

    newHls.on(Hls.Events.ERROR, (event, data) => {
      if (data.fatal) {
        console.error(data)
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // eslint-disable-next-line no-console
            console.log('fatal network error encountered, try to recover')
            // try to recover network error
            newHls.startLoad()
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            // eslint-disable-next-line no-console
            console.log('fatal media error encountered, try to recover')
            newHls.recoverMediaError()
            break
          default:
            // cannot recover
            newHls.destroy()
            break
        }
      }
    })

    setHls(newHls)
    return () => hls.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video {...props} ref={videoEl} src={src} />
}
