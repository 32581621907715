import React from 'react'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { antd } from '@gismart/ui.library/core'
import {
  ExperimentStatus,
  IRawExperimentsDataItem,
} from 'models/experiments.model'
import { ExperimentLink } from '../ExperimentLink'
import { PixelEventButtonWithModal } from './PixelEventButtonWithModal'

const { Button, Modal, Space } = antd

type TProps = {
  experiment: IRawExperimentsDataItem
  onEdit: (id) => void
  onViewStatistic: (id) => void
  onFinish: (id) => void
  onPublish: (id) => void
}

export const ActionButtons: React.FC<TProps> = ({
  experiment: { id, status, previewUrl, url: link },
  onEdit,
  onViewStatistic,
  onFinish,
  onPublish,
}) => (
  <Space>
    {status === ExperimentStatus.NEW && (
      <Button
        key={`${id}-edit`}
        icon={<MaterialIcon type="pencil" />}
        onClick={() => onEdit(id)}
      >
        Edit
      </Button>
    )}

    {status === ExperimentStatus.NEW && (
      <Button
        key={`${id}-publish`}
        onClick={() => {
          Modal.confirm({
            title: 'Publish',
            content: 'Are you sure you want to publish this experiment?',
            okText: 'Publish',
            onOk() {
              onPublish(id)
            },
          })
        }}
        icon={<MaterialIcon type="play-box" />}
      >
        Publish
      </Button>
    )}

    {status === ExperimentStatus.PUBLISHED && (
      <ExperimentLink key={`${id}-link`} link={link} />
    )}

    {(status === ExperimentStatus.PUBLISHED ||
      status === ExperimentStatus.FINISHED) && (
      <PixelEventButtonWithModal key={`${id}-pixel-event`} id={id} />
    )}

    {(status === ExperimentStatus.PUBLISHED ||
      status === ExperimentStatus.FINISHED) && (
      <Button
        key={`${id}-statistic`}
        icon={<MaterialIcon type="chart-areaspline-variant" />}
        onClick={() => onViewStatistic(id)}
      >
        Statistic
      </Button>
    )}

    {status === ExperimentStatus.PUBLISHED && (
      <Button
        key={`${id}-finish`}
        onClick={() => {
          Modal.confirm({
            title: 'Finish',
            content: 'Are you sure you want to finish this experiment?',
            okText: 'Finish',
            onOk() {
              onFinish(id)
            },
          })
        }}
        icon={<MaterialIcon type="check" />}
      >
        Finish
      </Button>
    )}

    <a href={previewUrl} target="_blank" rel="noopener noreferrer">
      <Button key={`${id}-preview`} icon={<MaterialIcon type="eye" />}>
        Preview
      </Button>
    </a>
  </Space>
)
