import { IStatistics } from 'models/experiments.statistic.model'
import {
  IRawExperimentsDataItem,
  TExperimentInitialData,
  ExperimentStatus,
} from 'models/experiments.model'
import { AnyAction } from 'redux'
import { DEFAULT_PAGINATION_FILTERS } from 'root-constants'
import { TPagination } from 'types'
import { DEFAULT_STATISTICS } from '../constants'
import {
  SET_EXPERIMENTS,
  RESET_EXPERIMENTS,
  SET_INITIAL_DATA,
  SET_INITIAL_DATA_APP,
  SET_CUSTOM_STATISTIC,
  RESET_CUSTOM_STATISTIC,
  RESET_INITIAL_DATA,
  SET_PIXEL_EVENT_WAS_FETCHED,
  UPDATE_FILTERS,
  RESET_FILTERS,
} from './actions'
import { getPreviewsFromRaw } from '../helpers/getPreviewsFromRaw'

export interface IExperimentsFilters extends TPagination {
  title?: string
  status?: ExperimentStatus[]
}

export interface IExperimentsState {
  list: IRawExperimentsDataItem[]
  experimentsCount?: number
  current: {
    // Always contains not modified data from server
    initialData: TExperimentInitialData
    isPixelEventFetched: boolean
    statistic: {
      customStatistic: IStatistics
    }
  }
  filters: IExperimentsFilters
}

const INITIAL_CURRENT_EXPERIMENT = {
  initialData: null,
  isPixelEventFetched: false,
  statistic: {
    customStatistic: { ...DEFAULT_STATISTICS },
  },
}

const initState: IExperimentsState = {
  list: [],
  current: INITIAL_CURRENT_EXPERIMENT,
  filters: DEFAULT_PAGINATION_FILTERS,
}

export function experimentsReducer(
  state: IExperimentsState = initState,
  { type, payload = null }: AnyAction,
): IExperimentsState {
  switch (type) {
    case SET_EXPERIMENTS: {
      return { ...state, ...payload }
    }
    case RESET_EXPERIMENTS: {
      return { ...state, list: [], experimentsCount: 0 }
    }
    case SET_INITIAL_DATA: {
      return {
        ...state,
        current: {
          ...state.current,
          initialData: {
            ...payload,
            variants:
              payload.variants?.map((variant) => ({
                ...variant,
                screenshots: getPreviewsFromRaw(variant.screenshots || []),
                videoPreviews: getPreviewsFromRaw(variant.videoPreviews || []),
              })) || [],
            application: {
              ...payload.application,
              screenshots: getPreviewsFromRaw(payload.application.screenshots),
              videoPreviews: getPreviewsFromRaw(
                payload.application.videoPreviews,
              ),
            },
          },
        },
      }
    }
    case RESET_INITIAL_DATA: {
      return {
        ...state,
        current: { ...state.current, initialData: null },
      }
    }
    case SET_INITIAL_DATA_APP: {
      return {
        ...state,
        current: {
          ...state.current,
          initialData: {
            ...state.current.initialData,
            application: {
              ...payload,
              screenshots: getPreviewsFromRaw(payload.screenshots),
              videoPreviews: getPreviewsFromRaw(payload.videoPreviews),
            },
          },
        },
      }
    }
    case SET_PIXEL_EVENT_WAS_FETCHED: {
      return {
        ...state,
        current: {
          ...state.current,
          isPixelEventFetched: payload,
        },
      }
    }
    case SET_CUSTOM_STATISTIC: {
      return {
        ...state,
        current: {
          ...state.current,
          statistic: {
            ...state.current.statistic,
            customStatistic: {
              ...state.current.statistic.customStatistic,
              ...payload,
            },
          },
        },
      }
    }
    case RESET_CUSTOM_STATISTIC: {
      return {
        ...state,
        current: {
          ...state.current,
          statistic: {
            ...state.current.statistic,
            customStatistic: { ...DEFAULT_STATISTICS },
          },
        },
      }
    }
    case UPDATE_FILTERS: {
      return { ...state, filters: payload }
    }
    case RESET_FILTERS: {
      return { ...state, filters: DEFAULT_PAGINATION_FILTERS }
    }
    default:
      return state
  }
}
