import React from 'react'

import { MaterialIcon } from '@gismart/ui.library/core/components'
import { antd } from '@gismart/ui.library/core'
import { NumberItem, Title, Value, Percent } from './style'

const { Tooltip } = antd

interface IProps {
  title?: string
  mesurment?: string
  helpText?: string
  value: number | null
  isPercent?: boolean
}

export const StatisticNumber: React.FC<IProps> = ({
  title = '',
  value = null,
  mesurment = '',
  helpText = '',
  isPercent = false,
}) => {
  // eslint-disable-next-line no-restricted-globals
  const isNotAvailvable = value === null || isNaN(value)

  return (
    <NumberItem className={isNotAvailvable ? 'notAvailableValue' : undefined}>
      <Title>
        <span>{title}</span>
        {helpText && (
          <Tooltip title={helpText}>
            <MaterialIcon type="help-circle-outline" />
          </Tooltip>
        )}
      </Title>
      <Value>
        {isNotAvailvable ? (
          <span>N/A</span>
        ) : (
          [
            <span key="value">
              {value}
              {mesurment}
            </span>,
            isPercent && <Percent key="percent">%</Percent>,
          ]
        )}
      </Value>
    </NumberItem>
  )
}
