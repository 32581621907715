import { antd } from '@gismart/ui.library/core'
import { FilterWrapper } from 'components/style'
import React from 'react'
import { STATUSES_OPTIONS } from '../../constants'

const { Input, Select, Col, Row } = antd

type TProps = {
  onFilter: (filters) => void
}

export const Filters: React.FC<TProps> = ({ onFilter }) => (
  <FilterWrapper>
    <Row gutter={24}>
      <Col span={6}>
        <Select
          options={STATUSES_OPTIONS}
          onChange={(value) => {
            onFilter({
              status: value,
            })
          }}
          showSearch
          mode="multiple"
          allowClear
          placeholder="Status"
          style={{ width: '100%' }}
        />
      </Col>
      <Col span={18}>
        <Input
          onChange={({ target: { value } }) =>
            onFilter({
              title: value,
            })
          }
          placeholder="Experiment title"
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
  </FilterWrapper>
)
