import {
  IRawExperimentsDataItem,
  TExperimentInitialData,
} from 'models/experiments.model'
import { IStatistics } from 'models/experiments.statistic.model'
import { IAppState } from 'models/store.models'
import { IExperimentsFilters } from './reducer'

export const selectExperimentsList = (
  state: IAppState,
): IRawExperimentsDataItem[] => state.experiments.list
export const selectExperimentsCount = (state: IAppState): number =>
  state.experiments.experimentsCount || 0

export const selectExperimentsCurrentTitle = (state: IAppState): string =>
  state.experiments.current.initialData?.title || ''
export const selectExperimentCustomStatistic = (
  state: IAppState,
): IStatistics => state.experiments.current.statistic.customStatistic
export const selectExperimentInitialData = (
  state: IAppState,
): TExperimentInitialData => state.experiments.current.initialData
export const selectExperimentPixelEvent = (
  state: IAppState,
): string | undefined => state.experiments.current.initialData?.pixelEvent
export const selectExperimentId = (state: IAppState): number =>
  state.experiments.current.initialData?.id || 0
export const selectExperimentPixelEventStatus = (state: IAppState): boolean =>
  state.experiments.current.isPixelEventFetched

export const selectExperimentsFilters = (
  state: IAppState,
): IExperimentsFilters => state.experiments.filters
