export const getItemsByPage = <T = any>({
  page,
  allPagesItems,
  limit,
}: {
  page: number
  allPagesItems: Array<T>
  limit: number
}): Array<T> => {
  const indexOfLastItemOnPage = page * limit
  const indexOfFirstItemOnPage = indexOfLastItemOnPage - limit
  return allPagesItems.slice(indexOfFirstItemOnPage, indexOfLastItemOnPage)
}
