import React, { useState, useCallback } from 'react'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'

const { Upload } = antd

export interface IProps extends antd.UploadProps {
  btnText?: string
  btnRender?: (isLoading: boolean, text: string) => React.ReactNode
  onUpload: (action: any) => void
}

interface IUploader extends React.FC<IProps> {
  getBase64File: (file: Blob, callback: any) => void
}

export const Uploader: IUploader = ({
  onUpload,
  btnText = 'Upload',
  btnRender = null,
  ...props
}) => {
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback(
    (info) => {
      if (info.file.status === 'uploading') {
        setLoading(true)
      }

      if (info.file.status === 'done' || info.file.status === 'error') {
        setLoading(false)
      }
    },
    [setLoading],
  )

  return (
    <Upload
      name="file"
      listType="picture-card"
      showUploadList={false}
      customRequest={onUpload}
      onChange={handleChange}
      {...props}
    >
      {btnRender ? (
        btnRender(loading, btnText)
      ) : (
        <div style={{ padding: 10 }}>
          <MaterialIcon type={loading ? 'loading' : 'plus'} spin={loading} />
          <div className="ant-upload-text">{btnText}</div>
        </div>
      )}
    </Upload>
  )
}

Uploader.getBase64File = (file, callback) => {
  if (file) {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      callback(reader.result)
    })
    reader.readAsDataURL(file)
  }
}
