import styled from 'styled-components'

export const StyledOverview = styled.div`
  background: #fff;
  padding: 16px;
`

export const Value = styled.span`
  font-size: 30px;
  font-weight: 500;
  margin-right: 4px;
`

export const Title = styled.span`
  font-size: 20px;
`

export const Text = styled.span`
  margin-right: 3px;
`

export const StyledVariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
