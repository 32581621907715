import React, { useEffect, useState } from 'react'
import { antd } from '@gismart/ui.library/core'

const { Checkbox, InputNumber } = antd

interface IProps {
  valueType: 'float' | 'integer'
  isCheckedByZero?: boolean
  value?: React.ReactText
  onChange?: (value: React.ReactText) => void
}

export const NumberInputWithCheckbox: React.FC<IProps> = ({
  children,
  valueType,
  isCheckedByZero = false,
  onChange = () => undefined,
  value = 0,
}) => {
  const [isChecked, setIsChecked] = useState(isCheckedByZero)

  useEffect(() => {
    if (value !== '') {
      const newValue = isCheckedByZero ? !value : !!value

      setIsChecked(newValue)
    }
  }, [isCheckedByZero, value])

  return (
    <>
      <Checkbox
        onChange={(e) => {
          const isCurrentChecked = e.target.checked
          const newValue = isCheckedByZero
            ? +!isCurrentChecked
            : +isCurrentChecked

          onChange(newValue)
          setIsChecked((currentValue) => !currentValue)
        }}
        checked={isChecked}
        style={{ margin: '5px 0 24px' }}
      >
        {children}
      </Checkbox>
      <InputNumber
        min={0}
        onChange={(currentValue) => {
          onChange(currentValue || '')
        }}
        onBlur={() => {
          const parsedValue =
            valueType === 'integer'
              ? parseInt(`${value}`, 10)
              : parseFloat(`${value}`)

          onChange(parsedValue || 0)
        }}
        value={+value}
        disabled={isCheckedByZero ? isChecked : !isChecked}
        style={{ display: 'block', width: '100%' }}
      />
    </>
  )
}
