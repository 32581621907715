import {
  IApplication,
  ICreateApplication,
  TUpdateApplication,
  IApplicationsResponse,
} from 'models/applications.model'
import {
  ApiService,
  IRequestOptions,
  IRequestParams,
  IResponseResult,
  RequestMethod,
} from '@gismart/ui.library/core/services/api'

export class ApplicationsApi {
  protected fetchApplicationsController: AbortController | undefined
  protected api: ApiService
  protected applicationsPath = 'apps'

  constructor(api: ApiService) {
    this.api = api
  }

  createApplication(
    entity: ICreateApplication,
  ): Promise<IResponseResult<ICreateApplication>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: entity,
    }

    return this.api.makeRequest<ICreateApplication>(
      this.applicationsPath,
      options,
    )
  }

  fetchApplication(
    id: string,
    params?: IRequestParams,
  ): Promise<IResponseResult<IApplication>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    if (params) {
      options.params = params
    }

    return this.api.makeRequest<IApplication>(
      `${this.applicationsPath}/${id}`,
      options,
    )
  }

  fetchApplications(
    params: IRequestParams,
  ): Promise<IResponseResult<IApplicationsResponse>> {
    if (this.fetchApplicationsController) {
      this.fetchApplicationsController.abort()
    }
    this.fetchApplicationsController = new AbortController()

    const options: IRequestOptions = {
      method: RequestMethod.GET,
      signal: this.fetchApplicationsController.signal,
      params,
    }

    return this.api.makeRequest<IApplicationsResponse>(
      this.applicationsPath,
      options,
    )
  }

  updateApplication(
    entity: TUpdateApplication,
  ): Promise<IResponseResult<TUpdateApplication>> {
    const options: IRequestOptions = {
      method: RequestMethod.PUT,
      body: entity,
    }

    return this.api.makeRequest<TUpdateApplication>(
      `${this.applicationsPath}/${entity.id}`,
      options,
    )
  }
}
