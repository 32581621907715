import styled from 'styled-components'
import { Color } from '@gismart/ui.library/core/constants'

export const Info = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  color: ${Color.TEXT_SECONDARY};
`

export const Item = styled.li`
  text-transform: capitalize;
  white-space: nowrap;
`

export const LowerCaseText = styled.span`
  text-transform: lowercase;
`

export const BoldText = styled.span`
  font-weight: 600;
`
