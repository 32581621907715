import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

export interface IProps {
  id: number | string
  item: any
  type: string
  index: number
  renderItem: (item: any) => React.ReactNode
  onMove: (dragIndex: number, hoverIndex: number) => void
}

export const Item: React.FC<IProps> = ({
  id,
  type,
  index,
  item,
  renderItem,
  onMove,
}) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: type,
    hover(element: any, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = element.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (ref.current as any).getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      element.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity, marginRight: '10px', cursor: 'move' }}>
      {renderItem(item)}
    </div>
  )
}
