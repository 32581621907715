import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
} from 'react-router-dom'
import { TAppDispatchThunk } from 'models/store.models'
import { NotFound } from 'pages'
import { Experiments } from 'modules/experiments'
import { fetchAppAction, resetAppAction } from '../redux/actions'

type TProps = TDispatchProps

const ApplicationComponent: React.FC<TProps> = ({ fetchApp, resetApp }) => {
  const { path, url } = useRouteMatch()
  const { applicationId }: Record<string, string> = useParams()

  useEffect(() => {
    fetchApp(applicationId)

    return () => {
      resetApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route
        path={path}
        exact
        render={() => <Redirect to={`${url}/experiments`} />}
      />
      <Route path={`${path}/experiments`} component={Experiments} />
      <Route component={NotFound} />
    </Switch>
  )
}

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  fetchApp: (id: string) => dispatch(fetchAppAction(id)),
  resetApp: () => dispatch(resetAppAction()),
})

type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const Application = connect(
  null,
  mapDispatchToProps,
)(ApplicationComponent)
