import { getFormFieldName } from 'modules/experiments/helpers/getFormFieldName'
import { ExperimentAppField } from 'modules/experiments/constants'
import { TrimmedInput } from 'components/TrimmedInput'
import React, { useCallback, useMemo, useState } from 'react'
import { antd } from '@gismart/ui.library/core'
import { getFormFieldValue } from 'modules/experiments/helpers/getFormFieldValue'
import { TFormFieldData } from '@gismart/ui.library/core/types'
import { DatePicker } from 'components/DatePicker'

const { Form, Checkbox, Input } = antd

interface IProps {
  isVariant?: boolean
  variantIndex?: number
  formStateFields: TFormFieldData[]
}

export const UpdateFormItems: React.FC<IProps> = ({
  isVariant,
  variantIndex,
  formStateFields,
}) => {
  const checkboxName = useMemo(
    () =>
      getFormFieldName(
        [ExperimentAppField.IS_UPDATE_SHOWN],
        isVariant,
        variantIndex,
      ),
    [isVariant, variantIndex],
  )
  const initialCheckboxValue = useMemo(
    () => getFormFieldValue(checkboxName, formStateFields),
    [checkboxName, formStateFields],
  )

  const [isDisabled, setIsDisabled] = useState(!initialCheckboxValue)

  const handleDisabling = useCallback((e) => {
    setIsDisabled(!e.target.checked)
  }, [])

  return (
    <>
      <Form.Item name={checkboxName} valuePropName="checked">
        <Checkbox onChange={handleDisabling}>Show</Checkbox>
      </Form.Item>
      <Form.Item
        name={getFormFieldName(
          [ExperimentAppField.UPDATE_DATE],
          isVariant,
          variantIndex,
        )}
        rules={[{ required: !isDisabled }]}
        validateStatus={isDisabled ? 'success' : undefined}
        help={!isDisabled && null}
      >
        <DatePicker style={{ width: '100%' }} disabled={isDisabled} />
      </Form.Item>
      <Form.Item
        name={getFormFieldName(
          [ExperimentAppField.UPDATE_VERSION],
          isVariant,
          variantIndex,
        )}
        rules={[{ required: !isDisabled }]}
        validateStatus={isDisabled ? 'success' : undefined}
        help={!isDisabled && null}
      >
        <TrimmedInput disabled={isDisabled} placeholder="Version" />
      </Form.Item>
      <Form.Item
        name={getFormFieldName(
          [ExperimentAppField.UPDATE_DESCRIPTION],
          isVariant,
          variantIndex,
        )}
      >
        <Input.TextArea
          rows={4}
          disabled={isDisabled}
          placeholder="Description"
        />
      </Form.Item>
    </>
  )
}
