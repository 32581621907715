import React, { useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { FilterWrapper, Title } from 'components/style'
import {
  CARDS_LIST_DEFAULT_GRID_CONFIG,
  DEFAULT_PAGINATION_FILTERS,
  PAGE_SIZE_OPTIONS,
} from 'root-constants'
import { getPageNumberById } from 'helpers/getPageNumberById'
import { getItemsByPage } from 'helpers/getItemsByPage'
import { useDispatch, useSelector } from 'react-redux'
import { ICustomLanding } from 'models/landings.custom.model'
import { getPaginationAfterChange } from 'helpers/getPaginationAfterChange'
import { useParams } from 'react-router-dom'
import { goTo } from 'route-history'
import { StyledAddButton, StyledListItem } from './style'
import {
  fetchCustomLandingsAction,
  resetCustomLandingsAction,
  setCustomLandingsSearchStringAction,
} from '../redux/actions'
import {
  selectCustomLandings,
  selectCustomLandingsCount,
  selectCustomLandingsSearchString,
} from '../redux/selects'
import { LandingsCard } from './LandingsCard'
import { LandingEditModal } from './LandingEditModal'

const debouncedFetchCustomLandings = debounce((dispatch) => {
  dispatch(fetchCustomLandingsAction())
}, 500)

const { Pagination, Input, Row, List, Breadcrumb } = antd

interface ILandingsContentProps {
  landingsContentLink: string
}

export const LandingsContent: React.FC<ILandingsContentProps> = ({
  landingsContentLink,
}) => {
  const dispatch = useDispatch()
  const landings = useSelector(selectCustomLandings)
  const landingsCount = useSelector(selectCustomLandingsCount)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_FILTERS)
  const searchString = useSelector(selectCustomLandingsSearchString)
  const { landingId } = useParams<{ landingId?: string }>()

  const isModalShow = useMemo(() => Boolean(landingId && landings.length > 0), [
    landingId,
    landings.length,
  ])

  useEffect(
    () => {
      debouncedFetchCustomLandings(dispatch)
      debouncedFetchCustomLandings.flush()

      return () => {
        dispatch(resetCustomLandingsAction())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (!landingId || landingId === 'new') {
      return
    }

    setPagination({
      page: getPageNumberById({
        id: landingId,
        allPagesItems: landings,
        limit: pagination.limit,
      }),
      limit: pagination.limit,
    })
  }, [landingId, landings, pagination.limit])

  const currentPageLandings = useMemo(
    () =>
      getItemsByPage<ICustomLanding>({
        page: pagination.page,
        allPagesItems: landings,
        limit: pagination.limit,
      }),
    [landings, pagination.limit, pagination.page],
  )

  const handlePaginationChange = useCallback(
    (updatedPage, updatedLimit) => {
      setPagination(
        getPaginationAfterChange({
          updatedPage,
          updatedLimit,
          currentLimit: pagination.limit,
        }),
      )
    },
    [pagination.limit],
  )

  const handleSearchChange = useCallback(
    (e) => {
      dispatch(setCustomLandingsSearchStringAction(e.target.value.trim() || ''))
      setPagination({
        page: 1,
        limit: pagination.limit,
      })
      debouncedFetchCustomLandings(dispatch)
    },
    [dispatch, pagination.limit],
  )
  return (
    <>
      <Title>
        <Breadcrumb>
          <Breadcrumb.Item>Landings</Breadcrumb.Item>
          <Breadcrumb.Item>Web Custom Landings</Breadcrumb.Item>
        </Breadcrumb>
      </Title>
      <FilterWrapper>
        <Input
          allowClear
          placeholder="Search by Title"
          value={searchString}
          onChange={handleSearchChange}
        />
      </FilterWrapper>
      <List
        grid={CARDS_LIST_DEFAULT_GRID_CONFIG}
        dataSource={['', ...currentPageLandings]}
        renderItem={(landing: ICustomLanding | string) => (
          <StyledListItem>
            {landing ? (
              <LandingsCard
                landing={landing as ICustomLanding}
                handleEdit={(id) => goTo(`${landingsContentLink}/${id}`)}
              />
            ) : (
              <StyledAddButton
                type="dashed"
                icon={<MaterialIcon type="plus" />}
                onClick={() => goTo(`${landingsContentLink}/new`)}
              >
                Add
              </StyledAddButton>
            )}
          </StyledListItem>
        )}
      />

      <Row justify="center">
        <Pagination
          showSizeChanger
          total={landingsCount}
          current={pagination.page}
          pageSize={pagination.limit}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onChange={handlePaginationChange}
        />
      </Row>

      <LandingEditModal
        isShown={isModalShow}
        handleCloseModal={() => {
          goTo(landingsContentLink)
        }}
        landingId={landingId}
        resetPaginationPage={() =>
          setPagination({ page: 1, limit: pagination.limit })
        }
      />
    </>
  )
}
