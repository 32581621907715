import { IRequestParams } from '@gismart/ui.library/core/services/api'
import { IExperimentsFilters } from '../redux/reducer'

export const getParamsFromFilters = ({
  page,
  limit,
  title,
  status,
}: IExperimentsFilters): IRequestParams => {
  const params: IRequestParams = { page, limit }

  if (status?.length) {
    params.status = status.join(',')
  }

  if (title) {
    params.title = title
  }

  return params
}
