import { applicationsApi, parserApi } from 'api'
import {
  setError,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { goTo } from 'route-history'
import { Action, ActionCreator, AnyAction } from 'redux'
import { IAction, TAppActionThunk } from 'models/store.models'
import {
  ICreateApplication,
  TUpdateApplication,
} from 'models/applications.model'
import {
  selectParsedAppFeaturedBanner,
  selectApplicationsFilters,
} from './selects'
import { IApplicationsFilters } from './reducer'
import { getParamsFromFilters } from '../helpers'

const MODULE_NAME = 'APPLICATIONS'

export const FETCH_APPS = `${MODULE_NAME}/FETCH_APPS`
export const FETCH_APP = `${MODULE_NAME}/FETCH_APP`
export const RESET_APP = `${MODULE_NAME}/RESET_APP`
export const UPDATE_APP = `${MODULE_NAME}/UPDATE_APP`
export const CREATE_APP = `${MODULE_NAME}/CREATE_APP`

const PARSE_NEW_APP = `${MODULE_NAME}/PARSE_NEW_APP`
export const SET_PARSED_APP = `${MODULE_NAME}/SET_PARSED_APP`
export const RESET_PARSED_APP = `${MODULE_NAME}/RESET_PARSED_APP`

export const UPDATE_FILTERS = `${MODULE_NAME}/UPDATE_FILTERS`
export const RESET_FILTERS = `${MODULE_NAME}/RESET_FILTERS`

export const parseNewAppAction = (link: string): TAppActionThunk<any> => async (
  dispatch,
) => {
  dispatch(startFetching(PARSE_NEW_APP))

  const response = await parserApi.parseApp({
    link,
    icon: true,
    screenshots: true,
  })

  if (response.success && response.data) {
    dispatch({
      type: SET_PARSED_APP,
      payload: response.data,
    })
  } else if (!response.success) {
    dispatch(setError('Application not found'))
  }

  dispatch(stopFetching(PARSE_NEW_APP))
}

export const resetParsedAppAction: ActionCreator<Action> = () => ({
  type: RESET_PARSED_APP,
})

export function fetchAppsAction(
  filters: IApplicationsFilters,
): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(FETCH_APPS))

    const params = getParamsFromFilters(filters)
    const response = await applicationsApi.fetchApplications(params)

    if (response.success && response.data) {
      const {
        data,
        meta: { count },
      } = response.data
      dispatch({
        type: FETCH_APPS,
        payload: {
          list: data,
          appsCount: count,
        },
      })
    } else if (!response.success) {
      // TODO: show error message
      // dispatch(showError(response.error))
    }

    dispatch(stopFetching(FETCH_APPS))
  }
}

export function resetAppAction(): AnyAction {
  return {
    type: RESET_APP,
  }
}

export function fetchAppAction(id: string): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(FETCH_APP))

    const response = await applicationsApi.fetchApplication(id)

    if (response.success && response.data) {
      dispatch({
        type: FETCH_APP,
        payload: response.data,
      })
    } else if (!response.success) {
      // TODO: show error message
      // dispatch(showError(response.error))
    }

    dispatch(stopFetching(FETCH_APP))
  }
}

export function createAppAction(
  data: Omit<ICreateApplication, 'store'>,
): TAppActionThunk<any> {
  return async (dispatch, getState) => {
    dispatch(startFetching(CREATE_APP))

    let resultData: ICreateApplication = {
      store: 'apple',
      ...data,
    }
    const featuredBanner = selectParsedAppFeaturedBanner(getState())

    if (featuredBanner) {
      resultData = {
        ...resultData,
        featuredBanner,
      }
    }

    const response = await applicationsApi.createApplication(resultData)

    if (response.success) {
      goTo('/applications')
    } else if (!response.success) {
      // TODO: show error message
      // dispatch(showError(response.error))
    }

    dispatch(stopFetching(CREATE_APP))
  }
}

export function updateAppAction(
  application: TUpdateApplication,
): TAppActionThunk<any> {
  return async (dispatch, getState) => {
    dispatch(startFetching(UPDATE_APP))

    const response = await applicationsApi.updateApplication(application)

    if (response.success) {
      const filters = selectApplicationsFilters(getState())
      dispatch(fetchAppsAction(filters))
    } else if (!response.success) {
      // TODO: show error message
      // dispatch(showError(response.error))
    }

    dispatch(stopFetching(UPDATE_APP))
  }
}

export function updateFiltersAction(
  filters: IApplicationsFilters,
): IAction<IApplicationsFilters> {
  return {
    type: UPDATE_FILTERS,
    payload: filters,
  }
}

export function resetFiltersAction(): IAction<never> {
  return {
    type: RESET_FILTERS,
  }
}
