import { IBreadcrumbRawItemConfig } from '@gismart/ui.library/core/components/Breadcrumb/types'

export const getBreadcrumbConfig = ({
  currentApplicationTitle,
  currentExperimentTitle,
}: {
  currentApplicationTitle: string
  currentExperimentTitle: string
}): Array<IBreadcrumbRawItemConfig> => [
  { path: '/', title: 'App Store A/B Tests', isLink: false },
  { path: '/applications', title: 'Applications' },
  {
    path: '/applications/:applicationId',
    title: currentApplicationTitle,
    isLink: false,
  },
  {
    path: '/applications/new',
    title: 'New Application',
  },
  {
    path: '/applications/:applicationId/experiments',
    title: 'Experiments',
  },
  {
    path: '/applications/:applicationId/experiments/:experimentId',
    title: currentExperimentTitle,
    isLink: false,
  },
  {
    path: '/applications/:applicationId/experiments/new',
    title: 'New Experiment',
  },
  {
    path: '/applications/:applicationId/experiments/:experimentId/statistic',
    title: 'Statistic',
  },
]
