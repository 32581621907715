import React, { useCallback, useEffect } from 'react'
import { antd } from '@gismart/ui.library/core'
import { TrimmedInput } from 'components/TrimmedInput'

const FIELD_NAME = 'pixelValue'

const { Form, Modal } = antd

interface IProps {
  label: string
  isVisible: boolean
  setIsVisible: (value: boolean) => void
  updateValue: (pixelId: string) => void
  value: string | undefined | null
  closeCallback?: () => void
}

export const FacebookPixelModal: React.FC<IProps> = ({
  label,
  value,
  updateValue,
  isVisible,
  setIsVisible,
  closeCallback,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({ [FIELD_NAME]: value || '' })
  }, [form, value])

  const handleOk = useCallback(async (): Promise<void> => {
    try {
      const values = await form.validateFields()

      setIsVisible(false)

      await updateValue(values[FIELD_NAME])
      closeCallback && closeCallback()
      form.resetFields()

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [form, updateValue, closeCallback, setIsVisible])

  const handleCancel = useCallback(() => {
    setIsVisible(false)
    closeCallback && closeCallback()
    form.resetFields()
  }, [closeCallback, form, setIsVisible])

  return (
    <Modal
      title="Facebook Pixel"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Submit"
    >
      <Form
        form={form}
        name="facebook-pixel-form"
        validateMessages={{ required: 'This field is required!' }}
      >
        <Form.Item
          label={label}
          name={FIELD_NAME}
          required
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TrimmedInput />
        </Form.Item>
      </Form>
    </Modal>
  )
}
