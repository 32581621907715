import React, { useEffect, useReducer, useState } from 'react'
import { goTo } from 'route-history'
import { antd } from '@gismart/ui.library/core'
import { Color } from '@gismart/ui.library/core/constants'
import { TFormFieldData } from '@gismart/ui.library/core/types'
import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { Draggable } from 'components/Draggable'
import { IRawParsedApp } from 'models/parser.model'
import { getFormFieldsForTesting } from 'modules/experiments/helpers/getFormFieldsForTesting'
import { getFormFieldsAfterChange } from 'modules/experiments/helpers/getFormFieldsAfterChange'
import { getThirdStepFormFields } from 'modules/experiments/helpers/getThirdStepFormFields'
import { getFirstStepFormFields } from 'modules/experiments/helpers/getFirstStepFormFields'
import { getFormFieldValue } from 'modules/experiments/helpers/getFormFieldValue'
import { getNewVariantIndex } from 'modules/experiments/helpers/getNewVariantIndex'
import { getFormFieldsWithoutVariantFieldsByUid } from 'modules/experiments/helpers/getFormFieldsWithoutVariantFieldsByUid'
import { getFormFieldsWithoutSecondStepFields } from 'modules/experiments/helpers/getFormFieldsWithoutSecondStepFields'
import { getFormFieldsWithoutThirdStepFields } from 'modules/experiments/helpers/getFormFieldsWithoutThirdStepFields'
import { getVariantsIndexesWithUids } from 'modules/experiments/helpers/getVariantsIndexesWithUids'
import { useBlockAppRouteTransition } from 'hooks/useBlockAppRouteTransition'
import { useBlockBrowserRouteTransition } from 'hooks/useBlockBrowserRouteTransition'
import { selectExperimentInitialData } from '../../redux/selects'
import { FirstStep } from './FirstStep'
import { SecondStep } from './SecondStep'
import { ThirdStep } from './ThirdStep'
import {
  createExperimentAction,
  parseAppAction,
  resetInitialDataAction,
  updateExperimentAction,
} from '../../redux/actions'
import {
  AppLanguage,
  ExperimentMetaField,
  FIELDS_FOR_TEST_OPTIONS_SELECT,
} from '../../constants'

const { Form, Steps } = antd

interface IState {
  fields: TFormFieldData[]
  currentStep: number
  isFirstStepDone: boolean
  isAppParsed: boolean
  areAllFieldsForTestSelected: boolean
}

interface IAction {
  type: ActionType
  payload?: any
}

enum ActionType {
  SET_ALL_FIELDS_FOR_TEST_SELECTED,
  ADD_VARIANT,
  REMOVE_VARIANT,
  SET_FIRST_STEP_INITIAL_FIELDS,
  SET_FIELDS_BY_CHANGE,
  SET_IS_PARSED_APP,
  GO_TO_FIRST_STEP,
  GO_TO_SECOND_STEP,
  GO_TO_THIRD_STEP,
}

type TProps = TStateProps &
  TDispatchProps & {
    backLink: string
    isNewExperiment?: boolean
  }

const ExperimentEditingComponent: React.FC<TProps> = ({
  isNewExperiment = false,
  backLink,
  initialData,
  parseApp,
  resetInitialData,
  createExperiment,
  updateExperiment,
}) => {
  const [form] = Form.useForm()
  const [isTransitionBlocked, setIsTransitionBlocked] = useState(false)

  useBlockBrowserRouteTransition(isTransitionBlocked)
  const unblockRouteTransition = useBlockAppRouteTransition(isTransitionBlocked)

  const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case ActionType.SET_ALL_FIELDS_FOR_TEST_SELECTED:
        return {
          ...state,
          areAllFieldsForTestSelected: action.payload,
        }
      case ActionType.ADD_VARIANT:
        return {
          ...state,
          fields: [
            ...state.fields,
            ...getFormFieldsForTesting({
              rawApp: initialData?.application as IRawParsedApp,
              fieldsForVariantTest: getFormFieldValue(
                [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
                state.fields,
              ),
              isVariant: true,
              isNewVariant: true,
              variantIndex: getNewVariantIndex(state.fields),
            }),
          ],
        }
      case ActionType.REMOVE_VARIANT:
        return {
          ...state,
          fields: getFormFieldsWithoutVariantFieldsByUid(
            action.payload,
            state.fields,
          ),
        }
      case ActionType.SET_FIRST_STEP_INITIAL_FIELDS:
        return {
          ...state,
          fields: [...action.payload],
        }
      case ActionType.SET_FIELDS_BY_CHANGE:
        return {
          ...state,
          fields: getFormFieldsAfterChange(action.payload, state.fields),
        }
      case ActionType.SET_IS_PARSED_APP:
        return {
          ...state,
          isAppParsed: action.payload,
        }
      case ActionType.GO_TO_FIRST_STEP:
        return {
          ...state,
          currentStep: 0,
        }
      case ActionType.GO_TO_SECOND_STEP:
        return {
          ...state,
          currentStep: 1,
          isFirstStepDone: true,
          fields: [
            ...getFormFieldsWithoutSecondStepFields(state.fields),
            ...getFormFieldsForTesting({
              rawApp: initialData?.application as IRawParsedApp,
              fieldsForVariantTest: getFormFieldValue(
                [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
                state.fields,
              ),
              currentFormFields: state.fields as {
                name: string | string[]
                value: any
              }[],
            }),
          ],
        }
      case ActionType.GO_TO_THIRD_STEP:
        return {
          ...state,
          currentStep: 2,
          isFirstStepDone: true,
          fields: [
            ...getFormFieldsWithoutThirdStepFields(state.fields),
            ...getThirdStepFormFields({
              rawApp: initialData?.application as IRawParsedApp,
              currentFormFields: state.fields as {
                name: string[] | string
                value: any
              }[],
              fieldsForVariantTest: getFormFieldValue(
                [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
                state.fields,
              ),
              variants: initialData?.variants,
            }),
          ],
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    fields: [
      {
        name: [ExperimentMetaField.PARSED_APP_LANGUAGE],
        value: AppLanguage.EN,
      },
    ],
    currentStep: 0,
    isFirstStepDone: false,
    isAppParsed: !isNewExperiment,
    areAllFieldsForTestSelected: false,
  })

  // Reset fields after closing ExperimentEditing component
  useEffect(
    () => () => {
      resetInitialData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    // Remove second step if all fields were selected
    const selectedTestFieldsLength =
      getFormFieldValue(
        [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
        state.fields,
      )?.length || 0

    dispatch({
      type: ActionType.SET_ALL_FIELDS_FOR_TEST_SELECTED,
      payload:
        selectedTestFieldsLength === FIELDS_FOR_TEST_OPTIONS_SELECT.length,
    })
  }, [state.fields])

  useEffect(() => {
    // Get fields for first step
    // but disable updating after parse new experiment
    if (
      (isNewExperiment && !state.isAppParsed && initialData) ||
      (!isNewExperiment && state.isAppParsed && initialData)
    ) {
      dispatch({
        type: ActionType.SET_FIRST_STEP_INITIAL_FIELDS,
        payload: getFirstStepFormFields(initialData),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  // Go to the second step only after complete parsing
  useEffect(() => {
    if (
      state.currentStep === 0 &&
      !state.isFirstStepDone &&
      isNewExperiment &&
      initialData?.application
    ) {
      dispatch({
        type: state.areAllFieldsForTestSelected
          ? ActionType.GO_TO_THIRD_STEP
          : ActionType.GO_TO_SECOND_STEP,
      })
    }
  }, [
    isNewExperiment,
    initialData,
    state.currentStep,
    state.isFirstStepDone,
    state.areAllFieldsForTestSelected,
  ])

  return (
    <Draggable.Provider>
      <Steps
        size="small"
        progressDot={!isNewExperiment}
        current={state.currentStep}
        style={{ backgroundColor: Color.WHITE, padding: 20, marginBottom: 24 }}
      >
        <Steps.Step title="Experiment details" />
        {!state.areAllFieldsForTestSelected && (
          <Steps.Step title="Application page" />
        )}
        <Steps.Step title="Variants" />
      </Steps>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        form={form}
        fields={state.fields}
        validateMessages={{ required: 'This field is required!' }}
        onValuesChange={() => {
          !isTransitionBlocked && setIsTransitionBlocked(true)
        }}
        onFieldsChange={(changedFields) => {
          dispatch({
            type: ActionType.SET_FIELDS_BY_CHANGE,
            payload: changedFields,
          })
        }}
        style={{
          backgroundColor: Color.WHITE,
          padding: '24px 20px 0',
        }}
      >
        {state.currentStep === 0 && (
          <FirstStep
            form={form}
            isLanguageSelectShown={isNewExperiment && !initialData?.application}
            isNeededValidationByCommonError={
              isNewExperiment && state.isAppParsed
            }
            onContinue={() => {
              if (
                isNewExperiment &&
                !state.isFirstStepDone &&
                !initialData?.application
              ) {
                parseApp(
                  getFormFieldValue(
                    [ExperimentMetaField.PARSED_APP_LANGUAGE],
                    state.fields,
                  ),
                )
                dispatch({ type: ActionType.SET_IS_PARSED_APP, payload: true })
                return
              }

              dispatch({
                type: state.areAllFieldsForTestSelected
                  ? ActionType.GO_TO_THIRD_STEP
                  : ActionType.GO_TO_SECOND_STEP,
              })
            }}
            onCancel={() => {
              unblockRouteTransition && unblockRouteTransition()
              goTo(backLink)
            }}
          />
        )}
        {state.currentStep === 1 && (
          <SecondStep
            form={form}
            formStateFields={state.fields}
            fieldsForVariantTest={getFormFieldValue(
              [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
              state.fields,
            )}
            onContinue={() => {
              dispatch({ type: ActionType.GO_TO_THIRD_STEP })
            }}
            onCancel={() => {
              dispatch({ type: ActionType.GO_TO_FIRST_STEP })
            }}
          />
        )}
        {state.currentStep === 2 && (
          <ThirdStep
            form={form}
            formStateFields={state.fields}
            fieldsForVariantTest={getFormFieldValue(
              [ExperimentMetaField.FIELDS_FOR_VARIANT_TEST],
              state.fields,
            )}
            variantsIndexesWithUids={getVariantsIndexesWithUids(state.fields)}
            isNewExperiment={isNewExperiment}
            onAddVariant={() => {
              dispatch({ type: ActionType.ADD_VARIANT })
            }}
            onRemoveVariant={(variantUid) => {
              dispatch({
                type: ActionType.REMOVE_VARIANT,
                payload: variantUid,
              })
            }}
            onCancel={() => {
              dispatch({
                type: state.areAllFieldsForTestSelected
                  ? ActionType.GO_TO_FIRST_STEP
                  : ActionType.GO_TO_SECOND_STEP,
              })
            }}
            onSubmit={() => {
              const fields = state.fields as {
                name: string[] | string
                value: any
              }[]

              unblockRouteTransition && unblockRouteTransition()

              isNewExperiment
                ? createExperiment(fields)
                : updateExperiment(fields)
            }}
          />
        )}
      </Form>
    </Draggable.Provider>
  )
}

const mapStateToProps = (state: IAppState) => ({
  initialData: selectExperimentInitialData(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  parseApp: (language: AppLanguage) => dispatch(parseAppAction(language)),
  resetInitialData: () => dispatch(resetInitialDataAction()),
  createExperiment: (formFields: TFormFieldData[]) =>
    dispatch(createExperimentAction(formFields)),
  updateExperiment: (formFields: TFormFieldData[]) =>
    dispatch(updateExperimentAction({ formFields })),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const ExperimentEditing = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExperimentEditingComponent)
