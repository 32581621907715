import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import {
  selectApplications,
  selectAppsCount,
  selectApplicationsFilters,
} from '../../redux/selects'
import {
  fetchAppsAction,
  updateFiltersAction,
  resetFiltersAction,
} from '../../redux/actions'
import { IApplicationsFilters } from '../../redux/reducer'

import { ApplicationsWrapperComponent } from './Component'

const mapStateToProps = (state: IAppState) => ({
  applications: selectApplications(state),
  appsCount: selectAppsCount(state),
  filters: selectApplicationsFilters(state),
})

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  fetchApps: (params: IApplicationsFilters) =>
    dispatch(fetchAppsAction(params)),
  updateFilters: (params: IApplicationsFilters) =>
    dispatch(updateFiltersAction(params)),
  resetFilters: () => dispatch(resetFiltersAction()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const ApplicationsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationsWrapperComponent)
