import React, { useState } from 'react'
import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import {
  fetchExperimentPixelEventAction,
  resetInitialDataAction,
  updateExperimentAction,
  SET_PIXEL_EVENT_WAS_FETCHED,
} from 'modules/experiments/redux/actions'
import {
  selectExperimentPixelEvent,
  selectExperimentPixelEventStatus,
} from 'modules/experiments/redux/selects'
import { FacebookPixelModal } from 'components/FacebookPixelModal'

const { Button } = antd

type TProps = TStateProps &
  TDispatchProps & {
    id: any
  }

const PixelEventButtonWithModalComponent: React.FC<TProps> = ({
  id,
  pixelEvent,
  isPixelEventFetched,
  fetchExperimentPixelEvent,
  updateExperimentPixelEvent,
  resetExperimentData,
  resetExperimentPixelEventStatus,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      <Button
        icon={<MaterialIcon type="facebook" />}
        onClick={() => {
          setIsVisible(true)
          fetchExperimentPixelEvent(id)
        }}
      >
        Pixel Event
      </Button>
      <FacebookPixelModal
        key={`${id}-facebook`}
        isVisible={isVisible && isPixelEventFetched}
        setIsVisible={setIsVisible}
        label="Pixel event"
        value={pixelEvent}
        updateValue={updateExperimentPixelEvent}
        closeCallback={() => {
          resetExperimentPixelEventStatus()
          resetExperimentData()
        }}
      />
    </>
  )
}

const mapStateToProps = (state: IAppState) => ({
  pixelEvent: selectExperimentPixelEvent(state),
  isPixelEventFetched: selectExperimentPixelEventStatus(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  fetchExperimentPixelEvent: (id: number) =>
    dispatch(fetchExperimentPixelEventAction(id)),
  updateExperimentPixelEvent: (pixelEvent: string) =>
    dispatch(updateExperimentAction({ pixelEvent })),
  resetExperimentData: () => dispatch(resetInitialDataAction()),
  resetExperimentPixelEventStatus: () =>
    dispatch({
      type: SET_PIXEL_EVENT_WAS_FETCHED,
      payload: false,
    }),
})

type TStateProps = ReturnType<typeof mapStateToProps>
type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const PixelEventButtonWithModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PixelEventButtonWithModalComponent)
