import React, { CSSProperties } from 'react'
import { antd } from '@gismart/ui.library/core'
import { AddButton } from './AddButton'
import { StyledList } from './style'

const { List: AntdList } = antd

const defaultRowKey = (item) => (item ? item.id : 'addBtn')

export interface IProps extends antd.ListProps<any> {
  data?: any[]
  wrapperStyle?: CSSProperties
  rowKey?: ((item: any) => string) | string
  renderActions?: (item: any) => React.ReactNodeArray
  renderItem?: (item: any) => React.ReactNode
  renderAddButton?: () => React.ReactNode
}

interface IList extends React.FC<IProps> {
  Item: {
    Meta: (props) => React.ReactElement
  }
  AddButton: React.FC<any>
}

export const List: IList = ({
  data = [],
  renderItem = (item: any) => item,
  renderActions = () => undefined,
  wrapperStyle,
  renderAddButton = null,
  rowKey = null,
  ...props
}) => (
  <StyledList
    {...props}
    withAddBtn={!!renderAddButton}
    dataSource={renderAddButton ? [null, ...data] : data}
    rowKey={rowKey || defaultRowKey}
    renderItem={(item: any) => (
      <AntdList.Item actions={item && renderActions(item)} style={wrapperStyle}>
        {item && renderItem(item)}
        {!item && renderAddButton && renderAddButton()}
      </AntdList.Item>
    )}
  />
)

List.Item = {
  Meta: AntdList.Item.Meta as (props) => React.ReactElement,
}

List.AddButton = AddButton
