import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { antd } from '@gismart/ui.library/core'

const { Button } = antd

export const draggableStyle = {
  display: 'flex',
  flexWrap: 'wrap' as any,
}

interface IWrapperProps extends HTMLAttributes<HTMLDivElement> {
  readonly bottomLine: boolean
}

export const Wrapper = styled.div`
  ${(props: IWrapperProps) =>
    props.bottomLine &&
    css`
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #d9d9d9;
    `}
`

export const Title = styled.div`
  font-size: 20px;
`

export const Content = styled.div`
  display: flex;
`

export const ItemWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  overflow: hidden;

  &:hover > button {
    visibility: visible;
  }
`
export const RemoveButton = styled(Button)`
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 100;
  visibility: hidden;
`
