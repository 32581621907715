import React, { CSSProperties, MouseEventHandler } from 'react'
import { antd } from '@gismart/ui.library/core'

const { Card: AntdCard, Avatar } = antd
const { Meta } = AntdCard

export interface IProps {
  imgUrl?: string
  title?: string
  onClick?: MouseEventHandler
  description?: React.ReactNode
  imgStyle?: CSSProperties
  children?: React.ReactNode
  header?: React.ReactNode
  actions?: React.ReactNodeArray
  bodyStyle?: CSSProperties
}

export const Card: React.FC<IProps> = ({
  imgUrl = '',
  imgStyle,
  title = '',
  onClick,
  description = '',
  children,
  header = '',
  ...props
}) => (
  <AntdCard onClick={onClick} hoverable={!!onClick} title={header} {...props}>
    {children || (
      <Meta
        avatar={imgUrl && <Avatar style={imgStyle} src={imgUrl} />}
        title={title}
        description={description}
      />
    )}
  </AntdCard>
)
