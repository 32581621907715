import { LOGIN_BUTTON_WRAPPER_ID } from '@gismart/ui.library/core/components'
import { authApi } from 'api'
import { TAppActionThunk, TAppDispatchThunk } from 'models/store.models'
import { IRawUser, IUser } from 'models/user.model'
import { goTo } from 'route-history'

const MODULE_NAME = 'AUTH'

export const SET_USER = `${MODULE_NAME}/SET_USER`
export const LOGIN = `${MODULE_NAME}/LOGIN`
export const LOGOUT = `${MODULE_NAME}/LOGOUT`

function setUserAction(user: IRawUser) {
  return {
    type: SET_USER,
    payload: user,
  }
}

export const fetchUserAction = (): TAppActionThunk<IUser> => async (
  dispatch: TAppDispatchThunk<IUser>,
): Promise<void> => {
  const response = await authApi.me()

  if (!response.success || !response.data) {
    goTo('/login')
    return
  }

  dispatch(setUserAction(response.data.data))
}

declare const google: any
export const loginAction = (): any => async (
  dispatch: TAppDispatchThunk<IRawUser>,
) => {
  google.accounts.id.initialize({
    client_id: (document.querySelector(
      'meta[name="google_client_id"]',
    ) as HTMLMetaElement).content,
    cancel_on_tap_outside: true,
    callback: async ({ credential }) => {
      try {
        const response = await authApi.login({ token: credential })

        if (response.success && response.data) {
          dispatch(setUserAction(response.data))
          goTo('/')
        } else {
          goTo('/login')
        }
      } catch (err) {
        console.error(`LOGIN FAILED: ${JSON.stringify(err)}`)
      }
    },
  })
  google.accounts.id.renderButton(
    document.getElementById(LOGIN_BUTTON_WRAPPER_ID),
    { text: 'continue_with', locale: 'en_US' },
  )
  google.accounts.id.prompt()
}

export const logoutAction = (): TAppActionThunk<never> => async (
  dispatch: TAppDispatchThunk<never>,
): Promise<void> => {
  const response = await authApi.logout()

  if (!response.success) {
    console.error(`LOGOUT FAILED`)
    return
  }

  google.accounts.id.disableAutoSelect()
  dispatch({ type: LOGOUT })
  goTo('/login')
}
