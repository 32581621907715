import React from 'react'
import { useUploadMedia } from 'hooks/useUploadMedia'
import { ImgUploader } from 'components/Uploader'
import { IImagePreview } from 'models/landings.google.model'

interface IImagePreviewItemProps {
  item: IImagePreview
  onChange: (value: {
    uploadedImage: IImagePreview
    replacedImage: IImagePreview
  }) => void
}

export const ImagePreviewItem: React.FC<IImagePreviewItemProps> = ({
  item,
  onChange,
}) => {
  const saveUploadedImage = useUploadMedia((uploadedImage) => {
    onChange({
      uploadedImage,
      replacedImage: item,
    })
  })

  return (
    <ImgUploader
      withPreview
      showEditButton
      value={item}
      onChange={saveUploadedImage}
    />
  )
}
