import React from 'react'
import { antd } from '@gismart/ui.library/core'
import { TInputProps, TTextAreaProps } from '@gismart/ui.library/core/types'

const { Input } = antd

function handleBlur<T>(
  e: React.FocusEvent<T>,
  onChange?: (e: React.ChangeEvent<T>) => void,
  onBlur?: (e: React.FocusEvent<T>) => void,
) {
  const target = e.target as any

  target.value = target.value.trim()
  onChange && onChange(e)
  onBlur && onBlur(e)
}

interface ITrimmedInput extends React.FC<TInputProps> {
  TextArea: React.FC<TTextAreaProps>
}

const TrimmedInput: ITrimmedInput = (props) => (
  <Input
    {...props}
    onBlur={(e) =>
      handleBlur<HTMLInputElement>(e, props.onChange, props.onBlur)
    }
  />
)
const TrimmedInputTextAreaComponent: React.FC<TTextAreaProps> = (props) => (
  <Input.TextArea
    {...props}
    onBlur={(e) =>
      handleBlur<HTMLTextAreaElement>(e, props.onChange, props.onBlur)
    }
  />
)

TrimmedInput.TextArea = TrimmedInputTextAreaComponent

export { TrimmedInput }
