import React from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { antd } from '@gismart/ui.library/core'
import { Breadcrumb as BreadcrumbFromUIKit } from '@gismart/ui.library/core/components'
import { selectAppCurrentTitle } from 'modules/applications/redux/selects'
import { selectExperimentsCurrentTitle } from 'modules/experiments/redux/selects'
import { getBreadcrumbConfig } from 'helpers/getBreadcrumbConfig'
import { ButtonWithPixelModal } from './ButtonWithPixelIdModal'

const { Row } = antd

export const Breadcrumb: React.FC = () => {
  const currentApplicationTitle = useSelector(selectAppCurrentTitle)
  const currentExperimentTitle = useSelector(selectExperimentsCurrentTitle)
  const { path: pathname, isExact } = useRouteMatch()
  const params = useParams()

  return isExact ? (
    <Row justify="space-between" align="middle" wrap={false}>
      <BreadcrumbFromUIKit
        pathname={pathname}
        params={params}
        config={getBreadcrumbConfig({
          currentApplicationTitle,
          currentExperimentTitle,
        })}
        Link={Link}
        style={{
          padding: '16px 0',
          minWidth: 0,
        }}
      />
      {pathname.includes('/applications') && <ButtonWithPixelModal />}
    </Row>
  ) : null
}
