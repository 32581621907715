import React from 'react'
import { Select } from '@gismart/ui.library/core/components'
import {
  IOption,
  ISelectProps,
} from '@gismart/ui.library/core/components/Select'

interface IProps extends Omit<ISelectProps, 'options' | 'onChange'> {
  options: IOption[]
  onChange?: (values: string[]) => void
}

export const FormItemSelect: React.FC<IProps> = ({
  options,
  onChange,
  ...props
}) => (
  <Select
    options={options.map(({ value, label }) => ({
      key: value,
      value: label,
    }))}
    listItemHeight={30}
    listHeight={300}
    onSelectChange={onChange}
    {...props}
  />
)
