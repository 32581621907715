import { antd } from '@gismart/ui.library/core'
import { FormItemAttrGridVariant } from 'components/FormItemAttrGridVariant'
import { TrimmedInput } from 'components/TrimmedInput'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { ICustomLandingFormValues } from 'models/landings.custom.model'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IS_EQUAL_PRIORITY_FIELD_NAME } from 'root-constants'
import { selectIsFetching } from 'root-redux/selects/common'
import { TITLE_FIELD_NAME } from '../constants'
import { getCustomLandingFormValues } from '../helpers'
import {
  createCustomLandingAction,
  updateCustomLandingAction,
} from '../redux/actions'
import { selectCustomLandings } from '../redux/selects'
import { FormItemFileUpload } from './FormItemFileUpload'
import { StyledModal, StyledPreview } from './style'

const { Form, Checkbox } = antd

interface IProps {
  isShown: boolean
  handleCloseModal: () => void
  landingId: string | undefined
  resetPaginationPage: () => void
}

export const LandingEditModal: React.FC<IProps> = ({
  isShown,
  landingId,
  handleCloseModal,
  resetPaginationPage,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const landings = useSelector(selectCustomLandings)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [isFbPixelChecked, setIsFbPixelChecked] = useState(false)
  const [isSnapPixelChecked, setIsSnapPixelChecked] = useState(false)
  const [isTikTokPixelChecked, setIsTikTokPixelChecked] = useState(false)

  const editedLanding = useMemo(
    () => landings.find((landing) => landing.id === Number(landingId)),
    [landingId, landings],
  )
  const isCreateMode = useMemo(() => !editedLanding, [editedLanding])
  const initialLandingFormValues: ICustomLandingFormValues = useMemo(
    () => getCustomLandingFormValues(editedLanding),
    [editedLanding],
  )
  const resetedLandingFormValues = useMemo(
    () => getCustomLandingFormValues(),
    [],
  )
  const previewLink = useMemo(() => {
    if (!editedLanding?.variants || !editedLanding?.link) return ''

    const existedUtmSource = Object.entries(editedLanding.variants).find(
      ([, value]) => value,
    )?.[1].source

    return `${editedLanding.link}?utm_source=${existedUtmSource}`
  }, [editedLanding])
  const isVariantRequired = useMemo(
    () => !isFbPixelChecked && !isSnapPixelChecked && !isTikTokPixelChecked,
    [isFbPixelChecked, isSnapPixelChecked, isTikTokPixelChecked],
  )
  const isFbPixelCheckedInitial = useMemo(
    () => !!initialLandingFormValues.variants.fb,
    [initialLandingFormValues.variants.fb],
  )
  const isSnapPixelCheckedInitial = useMemo(
    () => !!initialLandingFormValues.variants.snap,
    [initialLandingFormValues.variants.snap],
  )
  const isTikTokPixelCheckedInitial = useMemo(
    () => !!initialLandingFormValues.variants.tiktok,
    [initialLandingFormValues.variants.tiktok],
  )

  useEffect(() => {
    form.setFieldsValue(initialLandingFormValues)
    setIsFbPixelChecked(isFbPixelCheckedInitial)
    setIsSnapPixelChecked(isSnapPixelCheckedInitial)
    setIsTikTokPixelChecked(isTikTokPixelCheckedInitial)
  }, [
    initialLandingFormValues,
    form,
    isFbPixelCheckedInitial,
    isSnapPixelCheckedInitial,
    isTikTokPixelCheckedInitial,
  ])

  const handleCancel = useCallback(() => {
    handleCloseModal()
    form.setFieldsValue(resetedLandingFormValues)
    setIsFormChanged(false)
    setIsFbPixelChecked(!!resetedLandingFormValues.variants.fb)
    setIsSnapPixelChecked(!!resetedLandingFormValues.variants.snap)
    setIsTikTokPixelChecked(!!resetedLandingFormValues.variants.tiktok)

    if (!isCreateMode) {
      return
    }
    resetPaginationPage()
  }, [
    form,
    handleCloseModal,
    isCreateMode,
    resetPaginationPage,
    resetedLandingFormValues,
  ])

  const handleOk = useCallback(async () => {
    try {
      const formValues = await form.validateFields()

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { template, ...values } = formValues

      const variants = {
        fb: isFbPixelChecked ? values.variants.fb : null,
        snap: isSnapPixelChecked ? values.variants.snap : null,
        tiktok: isTikTokPixelChecked ? values.variants.tiktok : null,
      }

      if (isCreateMode) {
        dispatch(
          createCustomLandingAction({ ...values, variants }, handleCancel),
        )
        return
      }

      const valuesDiff: Partial<ICustomLandingFormValues> = Object.fromEntries(
        differenceWith(
          Object.entries(values),
          Object.entries(initialLandingFormValues),
          isEqual,
        ),
      )

      dispatch(
        updateCustomLandingAction(
          { id: +(landingId as string), ...valuesDiff, variants },
          handleCancel,
        ),
      )
    } catch (error) {
      //
    }
  }, [
    dispatch,
    form,
    handleCancel,
    initialLandingFormValues,
    isCreateMode,
    isFbPixelChecked,
    isSnapPixelChecked,
    isTikTokPixelChecked,
    landingId,
  ])

  const handleValuesChange = useCallback(
    (values: ICustomLandingFormValues) => {
      const isFormUpdated = !isEqual(values, initialLandingFormValues)

      setIsFormChanged(isFormUpdated)
    },
    [initialLandingFormValues],
  )

  return (
    <StyledModal
      title={isCreateMode ? 'Create Landing' : 'Edit Landing'}
      visible={isShown}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isFetching}
      okText="Save"
      okButtonProps={{
        disabled:
          !isFormChanged &&
          isFbPixelChecked === isFbPixelCheckedInitial &&
          isSnapPixelChecked === isSnapPixelCheckedInitial &&
          isTikTokPixelChecked === isTikTokPixelCheckedInitial,
      }}
      destroyOnClose
      width="800px"
    >
      <Form
        form={form}
        name="edit-custom-landing"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 21 }}
        validateMessages={{ required: 'This field is required!' }}
        initialValues={resetedLandingFormValues}
        onValuesChange={(_, values) => {
          handleValuesChange(values)
        }}
      >
        <Form.Item
          label="Title"
          name={TITLE_FIELD_NAME}
          required
          rules={[{ required: true }]}
        >
          <TrimmedInput disabled={isFetching} />
        </Form.Item>
        <FormItemAttrGridVariant
          fieldName="fb"
          form={form}
          label="Facebook"
          checkboxLabel="Use Facebook Pixel"
          isChecked={isFbPixelChecked}
          isSourceRequired
          isRequired={isVariantRequired}
          handleCheckboxChange={(value: boolean) => {
            setIsFbPixelChecked(value)
          }}
        />
        <FormItemAttrGridVariant
          fieldName="snap"
          form={form}
          label="Snapchat"
          checkboxLabel="Use Snapchat Pixel"
          isChecked={isSnapPixelChecked}
          isRequired={isVariantRequired}
          isSourceRequired
          handleCheckboxChange={(value: boolean) => {
            setIsSnapPixelChecked(value)
          }}
        />
        <FormItemAttrGridVariant
          fieldName="tiktok"
          form={form}
          label="TikTok"
          checkboxLabel="Use TikTok Pixel"
          isChecked={isTikTokPixelChecked}
          isRequired={isVariantRequired}
          isSourceRequired
          handleCheckboxChange={(value: boolean) => {
            setIsTikTokPixelChecked(value)
          }}
        />
        <Form.Item
          label="Equal Priority"
          name={IS_EQUAL_PRIORITY_FIELD_NAME}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <FormItemFileUpload form={form} isCreateMode={isCreateMode} />
        {previewLink && (
          <Form.Item label=" " colon={false}>
            <StyledPreview title="preview" src={previewLink} />
          </Form.Item>
        )}
      </Form>
    </StyledModal>
  )
}
