import { IRequestParams } from '@gismart/ui.library/core/services/api'
import {
  LOCAL_STORAGE_TAG_NAME,
  DEFAULT_PAGINATION_FILTERS,
} from 'root-constants'
import { DEFAULT_TAG_KEY } from './constants'
import { IApplicationsFilters } from './redux/reducer'

export const isTagSaved = (): boolean =>
  !!localStorage.getItem(LOCAL_STORAGE_TAG_NAME)

export const getTag = (): string => {
  const tagJSON = localStorage.getItem(LOCAL_STORAGE_TAG_NAME)

  if (tagJSON) {
    return JSON.parse(tagJSON)
  }

  return DEFAULT_TAG_KEY
}

export const saveTag = (tag: string): void => {
  localStorage.setItem(LOCAL_STORAGE_TAG_NAME, JSON.stringify(tag))
}

export const removeTag = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_TAG_NAME)
}

export const getParamsFromFilters = ({
  page,
  limit,
  title,
  tag,
}: IApplicationsFilters): IRequestParams => {
  const params: IRequestParams = {
    page,
    limit,
  }

  if (tag && tag !== DEFAULT_TAG_KEY) {
    params.tag = tag
  }

  if (title) {
    params.title = title
  }

  return params
}

export const getDefaultFilters = (): IApplicationsFilters => ({
  ...DEFAULT_PAGINATION_FILTERS,
  tag: getTag(),
})
