import {
  ApiService,
  IResponseResult,
  IRequestOptions,
  RequestMethod,
} from '@gismart/ui.library/core/services/api'
import { AppImageOrientation, AppPreviewFileType } from 'root-constants'

interface IFile {
  fileName: string
  file: any
  fileType: AppPreviewFileType
  orientation: AppImageOrientation
}
interface IFileResponse {
  uploadUrl: string
  id: number
  url: string
}

export class FileApi {
  protected api: ApiService
  protected path = 'files'

  constructor(api: ApiService) {
    this.api = api
  }

  create({
    fileName,
    file,
    orientation,
    fileType,
  }: IFile): Promise<IResponseResult<{ id: number; url: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: { fileName, orientation, fileType },
    }

    return this.api
      .makeRequest<IFileResponse>(this.path, options)
      .then((response) => {
        if (response.success && response.data) {
          const { uploadUrl, id, url } = response.data

          return fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
              'x-amz-acl': 'public-read',
            },
          })
            .then(() => ({ ...response, data: { id, url } }))
            .catch((error) => ({
              success: false,
              error,
            }))
        }

        return Promise.reject(response)
      })
  }
}
