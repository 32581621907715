import styled from 'styled-components'
import { AddButton } from 'components/AddButton'
import { Color } from '@gismart/ui.library/core/constants'

export const StyledAddButton = styled(AddButton)`
  height: 161px;
`

export const InfoArea = styled.div`
  color: ${Color.TEXT};
`

export const InfoItem = styled.span`
  padding-right: 5px;
`

export const Number = styled.span`
  padding-left: 5px;
  font-weight: 700;
`

export const EditTagButton = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border: none;
  background: transparent;

  &::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 0;
    bottom: -12px;
    right: 0;
  }
  .anticon {
    margin: 0 6px;
  }
`
