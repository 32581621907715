import {
  selectExperimentPixelEvent,
  selectExperimentsList,
  selectExperimentsFilters,
  selectExperimentsCount,
} from 'modules/experiments/redux/selects'
import { connect } from 'react-redux'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { IExperimentsFilters } from 'modules/experiments/redux/reducer'
import {
  fetchExperimentsAction,
  publishExperimentAction,
  finishExperimentAction,
  resetExperimentsAction,
  fetchExperimentAction,
  updateExperimentAction,
  resetInitialDataAction,
  updateFiltersAction,
  resetFiltersAction,
} from '../../redux/actions'

import { ExperimentsListComponent } from './Component'

const mapStateToProps = (state: IAppState) => ({
  experiments: selectExperimentsList(state),
  pixelEvent: selectExperimentPixelEvent(state),
  filters: selectExperimentsFilters(state),
  experimentsCount: selectExperimentsCount(state),
})

const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  fetchExperiments: (id: number, params: IExperimentsFilters) =>
    dispatch(fetchExperimentsAction(id, params)),
  resetExperiments: () => dispatch(resetExperimentsAction()),
  publishExperiment: (id: number) => dispatch(publishExperimentAction(id)),
  finishExperiment: (id: number) =>
    dispatch(finishExperimentAction({ id, isInvokedFromList: true })),
  fetchExperiment: (id: number) => dispatch(fetchExperimentAction(id)),
  updateExperiment: (pixelEvent: string) =>
    dispatch(updateExperimentAction({ pixelEvent })),
  resetExperimentData: () => dispatch(resetInitialDataAction()),
  updateFilters: (filters: IExperimentsFilters) =>
    dispatch(updateFiltersAction(filters)),
  resetFilters: () => dispatch(resetFiltersAction()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const ExperimentsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExperimentsListComponent)
