import { TFormFieldData } from '@gismart/ui.library/core/types'
import { ExperimentAppField } from '../constants'

export const getFormFieldsWithoutSecondStepFields = (
  fields: TFormFieldData[],
): TFormFieldData[] =>
  fields.filter((field) => {
    const isSecondStepField = Object.values(ExperimentAppField).includes(
      field.name[0],
    )

    return !isSecondStepField
  })
