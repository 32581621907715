import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { antd } from '@gismart/ui.library/core'
import { selectIsFetching } from 'root-redux/selects/common'
import isEqual from 'lodash/isEqual'
import { IEditGoogleLandingFormValues } from 'models/landings.google.model'
import { TrimmedInput } from 'components/TrimmedInput'
import { selectGoogleLandings } from '../redux/selects'
import { getGoogleLandingFormValues } from '../helpers/getEditLandingFormValues'
import {
  createGoogleLandingAction,
  updateGoogleLandingAction,
} from '../redux/actions'
import { GoogleLandingEditFieldName } from '../constants'
import { StyledModal } from './style'
import { IconUploader } from './IconUploader'
import { ImagePreviewGroup } from './ImagePreviewGroup'

const { Form } = antd

interface IProps {
  isShown: boolean
  handleCloseModal: () => void
  landingId: string | undefined
  resetPaginationPage: () => void
}

export const LandingEditModal: React.FC<IProps> = ({
  isShown,
  landingId,
  handleCloseModal,
  resetPaginationPage,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const googleLandings = useSelector(selectGoogleLandings)
  const [isFormChanged, setIsFormChanged] = useState(false)

  const editedLanding = useMemo(
    () => googleLandings.find((landing) => landing.id === Number(landingId)),
    [landingId, googleLandings],
  )
  const isCreateMode = useMemo(() => !editedLanding, [editedLanding])
  const initialLandingFormValues: IEditGoogleLandingFormValues = useMemo(
    () => getGoogleLandingFormValues(editedLanding),
    [editedLanding],
  )
  const resetedLandingFormValues: IEditGoogleLandingFormValues = useMemo(
    () => getGoogleLandingFormValues(),
    [],
  )

  useEffect(() => {
    form.setFieldsValue(initialLandingFormValues)
  }, [initialLandingFormValues, form])

  const handleCancel = useCallback(() => {
    handleCloseModal()
    setIsFormChanged(false)
    form.resetFields()
    if (!isCreateMode) {
      return
    }
    resetPaginationPage()
  }, [form, handleCloseModal, isCreateMode, resetPaginationPage])

  const handleOk = useCallback(async () => {
    const values = await form.validateFields()

    isCreateMode
      ? dispatch(createGoogleLandingAction(values, handleCancel))
      : dispatch(
          updateGoogleLandingAction(
            { id: +(landingId as string), ...values },
            handleCancel,
          ),
        )
  }, [dispatch, form, handleCancel, isCreateMode, landingId])

  const handleValuesChange = useCallback(
    (values: IEditGoogleLandingFormValues) => {
      const isFormFilled = Object.values(values).every((value) => value.length)
      const isFormUpdated = !isEqual(values, initialLandingFormValues)

      setIsFormChanged(isFormFilled && isFormUpdated)
    },
    [initialLandingFormValues],
  )

  return (
    <StyledModal
      title={isCreateMode ? 'Create Landing' : 'Edit Landing'}
      visible={isShown}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isFetching}
      okText="Save"
      okButtonProps={{
        disabled: !isFormChanged,
      }}
      destroyOnClose
      width="800px"
    >
      <Form
        form={form}
        name="edit-google-landing"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 21 }}
        validateMessages={{ required: 'This field is required!' }}
        initialValues={resetedLandingFormValues}
        onValuesChange={(_, values: IEditGoogleLandingFormValues) =>
          handleValuesChange(values)
        }
      >
        <Form.Item
          name={GoogleLandingEditFieldName.STORE_LINK}
          label="Store link"
          rules={[{ required: true }]}
        >
          <TrimmedInput disabled={isFetching} placeholder="Url" />
        </Form.Item>
        <Form.Item
          name={GoogleLandingEditFieldName.PIXEL_ID}
          label="Pixel ID"
          rules={[{ required: true }]}
        >
          <TrimmedInput
            disabled={isFetching}
            placeholder="Pixel ID"
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          name={GoogleLandingEditFieldName.ICON}
          label="Icon"
          rules={[{ required: true }]}
        >
          <IconUploader />
        </Form.Item>
        <Form.Item
          name={GoogleLandingEditFieldName.TITLE}
          label="Title"
          rules={[{ required: true }]}
        >
          <TrimmedInput
            disabled={isFetching}
            placeholder="App title"
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          name={GoogleLandingEditFieldName.DESCRIPTION}
          label="Description"
          rules={[{ required: true }]}
        >
          <TrimmedInput.TextArea
            rows={5}
            disabled={isFetching}
            placeholder="App description"
          />
        </Form.Item>
        <Form.Item
          name={GoogleLandingEditFieldName.IMAGE_PREVIEWS}
          label="Image previews"
          rules={[{ required: true }]}
        >
          <ImagePreviewGroup />
        </Form.Item>
      </Form>
    </StyledModal>
  )
}
