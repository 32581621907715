import styled, { css } from 'styled-components'
import { antd } from '@gismart/ui.library/core'
import { Video } from './Video'
import { Uploader } from './Uploader'

const { Button } = antd

export const UploaderWrapper = styled.div`
  display: flex;
`

type TPreview = {
  orientation: string
}

const Preview = `
  width: 150px;
  height: auto;
  align-self: center;

  ${(props) =>
    props.orientation === 'portrait' &&
    css`
      width: auto;
      height: 250px;
    `}
`

export const PreviewImg = styled.img<TPreview>`
  ${Preview}
`

export const PreviewVideo = styled(Video)<TPreview>`
  ${Preview}
`

export const PreviewPlaceholder = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

export const StyledEditButtonUpload = styled(Uploader)`
  > div {
    width: auto !important;
    height: auto !important;
    border: none !important;
    background: transparent !important;
    position: absolute;
    z-index: 9;
    top: 5px;
    left: 5px;
  }
`

export const StyledAddButtonUpload = styled(Uploader)`
  > div {
    width: auto !important;
    height: 100% !important;
    margin: 0 !important;
  }
`

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: start;

  &:hover button {
    visibility: visible;
  }
`

export const EditButton = styled(Button)`
  visibility: hidden;
`

export const StyledLoadingIndicator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`
