import React from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'models/store.models'
import { Spinner as SpinnerFromUIKit } from '@gismart/ui.library/core/components'
import { selectIsFetching } from 'root-redux/selects/common'

type TProps = TStateProps

const SpinnerComponent: React.FC<TProps> = ({ isFetching }) =>
  isFetching ? <SpinnerFromUIKit isFullScreen /> : null

const mapStateToProps = (state: IAppState) => ({
  isFetching: selectIsFetching(state),
})

type TStateProps = ReturnType<typeof mapStateToProps>

export const Spinner = connect(mapStateToProps)(SpinnerComponent)
