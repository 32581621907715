import { useEffect } from 'react'

export const useBlockBrowserRouteTransition = (
  isTransitionBlocked: boolean,
): void => {
  const beforeUnloadCallback = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    if (isTransitionBlocked) {
      window.addEventListener('beforeunload', beforeUnloadCallback)
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [isTransitionBlocked])
}
