import { Action, ActionCreator } from 'redux'
import { TFormFieldData } from '@gismart/ui.library/core/types'
import { experimentsApi, parserApi } from 'api'
import {
  setError,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.models'
import { goTo } from 'route-history'
import {
  selectAppCurrentId,
  selectAppCurrentStoreLink,
  selectCurrentApp,
} from 'modules/applications/redux/selects'
import { updateAppAction } from 'modules/applications/redux/actions'
import { IRawParsedApp } from 'models/parser.model'
import {
  IChangeExperimentPayload,
  IRawExperiment,
} from 'models/experiments.model'
import { IApplication } from 'models/applications.model'
import { getStatisticItemFromRaw } from 'helpers/statistic'
import { AppLanguage, StatisticName } from '../constants'
import { getRawDataFromForm } from '../helpers/getRawDataFromForm'
import { getParamsFromFilters } from '../helpers/getParamsFromFilters'
import {
  selectExperimentId,
  selectExperimentInitialData,
  selectExperimentsFilters,
} from './selects'
import { IExperimentsFilters } from './reducer'

const MODULE_NAME = 'EXPERIMENTS'

const PARSE_APP = `${MODULE_NAME}/PARSE_APP`
export const SET_INITIAL_DATA_APP = `${MODULE_NAME}/SET_INITIAL_DATA_APP`

const FETCH_EXPERIMENTS = `${MODULE_NAME}/FETCH_EXPERIMENTS`
export const SET_EXPERIMENTS = `${MODULE_NAME}/SET_EXPERIMENTS`
export const RESET_EXPERIMENTS = `${MODULE_NAME}/RESET_EXPERIMENTS`
const FETCH_EXPERIMENT = `${MODULE_NAME}/FETCH_EXPERIMENT`
const FETCH_PIXEL_EVENT = `${MODULE_NAME}/FETCH_PIXEL_EVENT`
export const SET_PIXEL_EVENT_WAS_FETCHED = `${MODULE_NAME}/SET_PIXEL_EVENT_WAS_FETCHED`
export const SET_INITIAL_DATA = `${MODULE_NAME}/SET_INITIAL_DATA`
export const RESET_INITIAL_DATA = `${MODULE_NAME}/RESET_INITIAL_DATA`
const CREATE_EXPERIMENT = `${MODULE_NAME}/CREATE_EXPERIMENT`
const UPDATE_EXPERIMENT = `${MODULE_NAME}/UPDATE_EXPERIMENT`
const PUBLISH_EXPERIMENT = `${MODULE_NAME}/PUBLISH_EXPERIMENT`
const FINISH_EXPERIMENT = `${MODULE_NAME}/FINISH_EXPERIMENT`

export const INIT_DEFAULT_VARIANT = `${MODULE_NAME}/INIT_DEFAULT_VARIANT`
export const SAVE_VARIANTS_DATA = `${MODULE_NAME}/SAVE_VARIANTS_DATA`

export const FETCH_STATISTIC = `${MODULE_NAME}/FETCH_STATISTIC`
export const SET_CUSTOM_STATISTIC = `${MODULE_NAME}/SET_CUSTOM_STATISTIC`
export const RESET_CUSTOM_STATISTIC = `${MODULE_NAME}/RESET_CUSTOM_STATISTIC`

export const UPDATE_FILTERS = `${MODULE_NAME}/UPDATE_FILTERS`
export const RESET_FILTERS = `${MODULE_NAME}/RESET_FILTERS`

export const fetchExperimentsAction = (
  id: number,
  filters: IExperimentsFilters,
): any => async (dispatch: TAppDispatchThunk<any>) => {
  dispatch(startFetching(FETCH_EXPERIMENTS))

  const params = getParamsFromFilters(filters)
  const response = await experimentsApi.fetchExperiments(id, params)

  if (response.success && response.data) {
    const { data, meta } = response.data

    dispatch({
      type: SET_EXPERIMENTS,
      payload: {
        list: data,
        experimentsCount: meta?.count,
      },
    })
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(FETCH_EXPERIMENTS))
}

export const resetExperimentsAction: ActionCreator<Action> = () => ({
  type: RESET_EXPERIMENTS,
})

export const fetchExperimentAction = (id: number): any => async (
  dispatch: TAppDispatchThunk<any>,
) => {
  dispatch(startFetching(FETCH_EXPERIMENT))

  const response = await experimentsApi.fetchExperiment(id)

  if (response.success && response.data) {
    dispatch({
      type: SET_INITIAL_DATA,
      payload: response.data,
    })
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(FETCH_EXPERIMENT))
}

export const fetchExperimentPixelEventAction = (id: number): any => async (
  dispatch: TAppDispatchThunk<any>,
) => {
  dispatch(startFetching(FETCH_PIXEL_EVENT))

  await dispatch(fetchExperimentAction(id))
  dispatch({
    type: SET_PIXEL_EVENT_WAS_FETCHED,
    payload: true,
  })

  dispatch(stopFetching(FETCH_PIXEL_EVENT))
}

export const resetInitialDataAction: ActionCreator<Action> = () => ({
  type: RESET_INITIAL_DATA,
})

export const parseAppAction = (language: AppLanguage): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
) => {
  const state = getState()
  const link = selectAppCurrentStoreLink(state)
  const currentApp = selectCurrentApp(state)

  dispatch(startFetching(PARSE_APP))

  const response = await parserApi.parseApp({
    link,
    language,
    icon: true,
    screenshots: true,
  })

  if (response.success && response.data) {
    const parsedApp = response.data

    dispatch({
      type: SET_INITIAL_DATA_APP,
      payload: parsedApp,
    })

    const isFeaturedBannerChanged =
      parsedApp.featuredBanner !== currentApp?.featuredBanner

    if (isFeaturedBannerChanged) {
      dispatch(
        updateAppAction({
          ...(currentApp as IApplication),
          featuredBanner: parsedApp.featuredBanner || '',
        }),
      )
    }
  } else if (!response.success) {
    const errorName = await response.data?.errors?.name?.[0]

    if (errorName === 'wrong_language') {
      dispatch(
        setError(
          `The app doesn't contain a translation to a selected language`,
        ),
      )
    } else {
      // TODO: show error message (common)
    }
  }

  dispatch(stopFetching(PARSE_APP))
}

export const fetchStatisticAction = (id: number): any => async (
  dispatch: TAppDispatchThunk<any>,
) => {
  dispatch(startFetching(FETCH_STATISTIC))

  const response = await experimentsApi.getCustomStatistic(id)

  if (response.success && response.data) {
    dispatch({
      type: SET_CUSTOM_STATISTIC,
      payload: getStatisticItemFromRaw(response.data, StatisticName.CUSTOM),
    })
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(FETCH_STATISTIC))
}

export const resetCustomStatisticAction: ActionCreator<Action> = () => ({
  type: RESET_CUSTOM_STATISTIC,
})

export const createExperimentAction = (
  formFields: TFormFieldData[],
): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
) => {
  const state = getState()
  const applicationId = selectAppCurrentId(state)
  const experimentInitialData = selectExperimentInitialData(state)

  dispatch(startFetching(CREATE_EXPERIMENT))

  const response = await experimentsApi.createExperiment({
    ...getRawDataFromForm(
      experimentInitialData?.application as IRawParsedApp,
      formFields,
    ),
    applicationId,
    page: 'app',
  })

  if (response.success) {
    goTo(`/applications/${applicationId}/experiments`)
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(CREATE_EXPERIMENT))
}

export const updateExperimentAction = ({
  formFields,
  pixelEvent,
}: {
  formFields?: TFormFieldData[]
  pixelEvent?: string
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
) => {
  const state = getState()
  const applicationId = selectAppCurrentId(state)
  const experimentInitialData = selectExperimentInitialData(state)
  const experimentId = selectExperimentId(state)

  dispatch(startFetching(UPDATE_EXPERIMENT))

  let payload: IChangeExperimentPayload = {
    ...(experimentInitialData as IRawExperiment),
  }

  if (formFields) {
    payload = {
      ...payload,
      ...getRawDataFromForm(
        experimentInitialData?.application as IRawParsedApp,
        formFields,
      ),
    }
  }

  if (pixelEvent) {
    payload = {
      ...payload,
      pixelEvent,
    }
  }

  const response = await experimentsApi.updateExperiment(experimentId, payload)

  if (response.success) {
    goTo(`/applications/${applicationId}/experiments`)
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(UPDATE_EXPERIMENT))
}

export const publishExperimentAction = (id: number): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
) => {
  const state = getState()
  const applicationId = selectAppCurrentId(state)

  dispatch(startFetching(PUBLISH_EXPERIMENT))

  const response = await experimentsApi.publishExperiment(id)

  if (response.success) {
    const filters = selectExperimentsFilters(getState())
    dispatch(fetchExperimentsAction(applicationId, filters))
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(PUBLISH_EXPERIMENT))
}

export const finishExperimentAction = ({
  id,
  isInvokedFromList = false,
}: {
  id: number
  isInvokedFromList?: boolean
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
) => {
  const state = getState()
  const applicationId = selectAppCurrentId(state)

  dispatch(startFetching(FINISH_EXPERIMENT))

  const response = await experimentsApi.finishExperiment(id)

  if (response.success) {
    if (isInvokedFromList) {
      const filters = selectExperimentsFilters(getState())
      dispatch(fetchExperimentsAction(applicationId, filters))
    } else {
      dispatch(fetchExperimentAction(id))
    }
  } else if (!response.success) {
    // TODO: show error message
    // dispatch(showError(response.error))
  }

  dispatch(stopFetching(FINISH_EXPERIMENT))
}

export function updateFiltersAction(
  filters: IExperimentsFilters,
): IAction<IExperimentsFilters> {
  return {
    type: UPDATE_FILTERS,
    payload: filters,
  }
}

export function resetFiltersAction(): IAction<never> {
  return {
    type: RESET_FILTERS,
  }
}
