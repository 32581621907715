import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Select } from '@gismart/ui.library/core/components'
import { antd } from '@gismart/ui.library/core'
import { IAppState, TAppDispatchThunk } from 'models/store.models'
import { goTo } from 'route-history'
import { ImgUploader } from 'components/Uploader'
import { createAppAction } from 'modules/applications/redux/actions'
import { selectParsedApp } from 'modules/applications/redux/selects'
import { TrimmedInput } from 'components/TrimmedInput'
import { ICreateApplication } from 'models/applications.model'
import { CATEGORIES } from './constants'
import { TAGS_OPTIONS } from '../../constants'

const { Button, Form } = antd

type TProps = TStateProps & TDispatchProps

export const ApplicationInfoComponent: React.FC<TProps> = ({
  parsedApp,
  createApp,
}) => {
  const [form] = Form.useForm()

  const submitCallback = useCallback(
    async (e) => {
      e.preventDefault()

      try {
        const values = await form.validateFields()

        await createApp({
          ...values,
          tags: [values.tags],
        })
      } catch (error) {
        // error
      }
    },
    [createApp, form],
  )

  const onCancel = useCallback(() => {
    goTo('/applications')
  }, [])

  return (
    <Form
      form={form}
      name="new-app-second-step"
      validateMessages={{ required: 'This field is required!' }}
      labelCol={{ span: 4 }}
    >
      <Form.Item
        label="Title"
        name="title"
        required
        initialValue={parsedApp?.title || ''}
        rules={[{ required: true }]}
      >
        <TrimmedInput placeholder="Application title" />
      </Form.Item>
      <Form.Item
        label="Store link"
        name="storeLink"
        required
        initialValue={parsedApp?.storeLink || ''}
        rules={[{ required: true }]}
      >
        <TrimmedInput placeholder="Store link" />
      </Form.Item>
      <Form.Item
        label="Icon"
        name="icon"
        required
        initialValue={parsedApp?.icon}
        rules={[{ required: true }]}
      >
        <ImgUploader withPreview showEditButton />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category"
        required
        initialValue={parsedApp?.category || ''}
        rules={[{ required: true }]}
      >
        <Select
          options={CATEGORIES}
          onSelectChange={(value) => form.setFieldsValue({ category: value })}
          showSearch
        />
      </Form.Item>
      <Form.Item label="Tag" name="tags" required rules={[{ required: true }]}>
        <Select
          options={TAGS_OPTIONS}
          onSelectChange={(value) => form.setFieldsValue({ tags: value })}
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} style={{ marginRight: '8px' }}>
          Cancel
        </Button>
        <Button type="primary" onClick={submitCallback}>
          Add application
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = (state: IAppState) => ({
  parsedApp: selectParsedApp(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  createApp: (data: Omit<ICreateApplication, 'store'>) =>
    dispatch(createAppAction(data)),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const ApplicationInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationInfoComponent)
