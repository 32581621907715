import React, { useCallback } from 'react'
import { antd } from '@gismart/ui.library/core'
import { SortableGroup } from 'components/SortableGroup'
import { uid } from '@gismart/ui.library/core/services'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { IParsedAppVideoPreview } from 'models/parser.model'
import { VideoUploader, ImgUploader } from 'components/Uploader'
import { useUploadMedia } from 'hooks/useUploadMedia'
import { Color } from '@gismart/ui.library/core/constants'
import { AppImageOrientation, AppPreviewFileType } from 'root-constants'
import { AppPhone } from '../../constants'

const { Button } = antd

const newItem = (phoneType: AppPhone): IParsedAppVideoPreview => ({
  video: {
    fileType: AppPreviewFileType.VIDEO,
    orientation: AppImageOrientation.UNKNOWN,
    phoneType: AppPhone.IPHONE_X,
    url: '',
  },
  stopScreen: {
    fileType: AppPreviewFileType.IMAGE,
    orientation: AppImageOrientation.UNKNOWN,
    phoneType: AppPhone.IPHONE_X,
    url: '',
  },
  phoneType,
  id: uid(),
})

const MAX_VIDEO_NUMBER = 3

interface IVideoItemProps {
  item: IParsedAppVideoPreview
  phoneType: AppPhone
  onChange: (value: IParsedAppVideoPreview) => void
}

interface IVideoPreviewGroupProps {
  phoneType: AppPhone
  value?: IParsedAppVideoPreview[]
  onChange?: (value: IParsedAppVideoPreview[]) => void
  id?: string
}

const VideoItem: React.FC<IVideoItemProps> = ({
  item,
  onChange,
  phoneType,
}) => {
  const saveUploadedImage = useUploadMedia((uploadedImage) =>
    onChange({
      ...item,
      stopScreen: { ...uploadedImage, phoneType },
    }),
  )
  const saveUploadedVideo = useUploadMedia((uploadedVideo) =>
    onChange({
      ...item,
      video: { ...uploadedVideo, phoneType },
    }),
  )

  const isOnlyVideoUploaded = ({ stopScreen, video }) =>
    video.url && !stopScreen.url

  const isOnlyImageUploaded = ({ stopScreen, video }) =>
    stopScreen.url && !video.url

  return (
    <div style={{ padding: '5px 30px 5px 5px' }}>
      <ImgUploader
        withPreview
        showEditButton
        value={item.stopScreen}
        btnText="Image"
        onChange={saveUploadedImage}
        style={{
          marginBottom: '10px',
          ...(isOnlyVideoUploaded(item) && {
            border: `1px dashed ${Color.ERROR}`,
          }),
        }}
      />
      <VideoUploader
        withPreview
        showEditButton
        value={item.video}
        btnText="Video"
        onChange={saveUploadedVideo}
        style={{
          ...(isOnlyImageUploaded(item) && {
            border: `1px dashed ${Color.ERROR}`,
          }),
        }}
      />
    </div>
  )
}

export const VideoPreviewGroup: React.FC<IVideoPreviewGroupProps> = ({
  value = [],
  onChange = () => undefined,
  phoneType,
  id,
}) => {
  const addItem = useCallback(() => onChange([...value, newItem(phoneType)]), [
    onChange,
    phoneType,
    value,
  ])

  const updateItem = useCallback(
    (updatedItem) =>
      onChange(
        value.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem
          }
          return item
        }),
      ),
    [onChange, value],
  )

  return (
    <SortableGroup
      id={id}
      withoutContext
      items={value || []}
      onMove={onChange}
      onRemove={onChange}
      type="video"
      renderItemContent={(item) => (
        <VideoItem
          item={item}
          onChange={(updatedItem) => updateItem(updatedItem)}
          phoneType={phoneType}
        />
      )}
      addComponent={
        value.length < MAX_VIDEO_NUMBER && (
          <Button
            onClick={addItem}
            icon={<MaterialIcon type="plus" />}
            style={{ height: 'auto', borderStyle: 'dashed' }}
          />
        )
      }
    />
  )
}
