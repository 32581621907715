import React, { useState, useEffect } from 'react'
import { antd } from '@gismart/ui.library/core'
import { ApplicationInfo } from './ApplicationInfo'
import { ComponentWrapper } from './style'
import { TDispatchProps, TStateProps } from './Container'
import { ApplicationLinkInput } from './ApplicationLinkInput'

const LINK_PAGE = 'link'
const DESCRIPTION_PAGE = 'description'

const { Row, Col, Card, Button } = antd

type TProps = TStateProps & TDispatchProps

export const NewApplicationComponent: React.FC<TProps> = ({
  parsedApp,
  resetParsedApp,
}) => {
  const [currentPage, setCurrentPage] = useState(LINK_PAGE)

  useEffect(
    () => () => {
      resetParsedApp()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (parsedApp) {
      setCurrentPage(DESCRIPTION_PAGE)
    }
  }, [parsedApp])

  return (
    <ComponentWrapper>
      <Row>
        <h2>New application</h2>
      </Row>
      {currentPage === LINK_PAGE ? (
        <Row gutter={16}>
          <Col span={12}>
            <Card bodyStyle={{ paddingBottom: 0 }}>
              <ApplicationLinkInput />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Button block onClick={() => setCurrentPage(DESCRIPTION_PAGE)}>
                Fill manually
              </Button>
            </Card>
          </Col>
        </Row>
      ) : (
        <ApplicationInfo />
      )}
    </ComponentWrapper>
  )
}
