import React, { useCallback } from 'react'
import { SortableGroup } from 'components/SortableGroup'
import { ImgUploader } from 'components/Uploader'
import { useUploadMedia } from 'hooks/useUploadMedia'
import { IImagePreview } from 'models/landings.google.model'
import { convertUrlToImgPreview } from '../helpers/convertUrlToImgPreview'
import { ImagePreviewItem } from './ImagePreviewItem'

const MAX_SCREENSHOT_NUMBER = 10

interface IImagePreviewGroupProps {
  value?: string[]
  onChange?: (value: string[]) => void
}

export const ImagePreviewGroup: React.FC<IImagePreviewGroupProps> = ({
  onChange = () => undefined,
  value = [],
}) => {
  const imagePreviews = value.map(convertUrlToImgPreview)

  const saveUploadedImage = useUploadMedia((uploadedImage) =>
    onChange([...imagePreviews, uploadedImage].map(({ url }) => url)),
  )

  const updateItem = useCallback(
    ({ uploadedImage, replacedImage }) =>
      onChange(
        imagePreviews.map((item) =>
          item.id === replacedImage.id ? uploadedImage.url : item.url,
        ),
      ),
    [onChange, imagePreviews],
  )

  const handleChange = useCallback(
    (updatedValues: IImagePreview[]) => {
      onChange(updatedValues.map(({ url }) => url))
    },
    [onChange],
  )

  return (
    <SortableGroup
      key="id"
      id="id"
      items={imagePreviews || []}
      onMove={handleChange}
      onRemove={handleChange}
      type="image"
      renderItemContent={(item) => (
        <ImagePreviewItem
          item={item}
          onChange={(uploadResult) => updateItem(uploadResult)}
        />
      )}
      addComponent={
        imagePreviews.length < MAX_SCREENSHOT_NUMBER && (
          <ImgUploader onChange={saveUploadedImage} />
        )
      }
    />
  )
}
