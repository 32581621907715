import { RESET_PIXEL_ID, SET_PIXEL_ID } from 'root-redux/actions/service'
import { AnyAction } from 'redux'

export interface IServiceState {
  pixelId: string | undefined | null
}

const initState: IServiceState = {
  pixelId: null,
}

export function serviceReducer(
  state: IServiceState = initState,
  { type, payload = null }: AnyAction,
): IServiceState {
  switch (type) {
    case SET_PIXEL_ID: {
      return { ...state, pixelId: payload }
    }
    case RESET_PIXEL_ID: {
      return { ...state, pixelId: null }
    }
    default:
      return state
  }
}
