import { AppImageOrientation } from 'root-constants'

export function defineOrientation(
  width: number,
  height: number,
): AppImageOrientation {
  if (!width || !height) {
    return AppImageOrientation.UNKNOWN
  }

  if (width > height) {
    return AppImageOrientation.LANDSCAPE
  }

  if (width < height) {
    return AppImageOrientation.PORTRAIT
  }

  return AppImageOrientation.SQUARE
}
