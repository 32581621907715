import { antd } from '@gismart/ui.library/core'
import { googleLandingsApi } from 'api'
import { startFetching, stopFetching } from 'root-redux/actions/common'
import { IAction, TAppActionThunk } from 'models/store.models'
import {
  ICreateGoogleLanding,
  IUpdateGoogleLanding,
} from 'models/landings.google.model'
import { selectGoogleLandingsSearchString } from './selects'

const { message } = antd

const MODULE_NAME = 'GOOGLE_LANDINGS'

export const SET_LANDINGS = `${MODULE_NAME}/SET_LANDINGS`
export const RESET_LANDINGS = `${MODULE_NAME}/RESET_LANDINGS`
export const SET_SEARCH_STRING = `${MODULE_NAME}/SET_SEARCH_STRING`
const FETCH_LANDINGS = `${MODULE_NAME}/FETCH_LANDINGS`
const CREATE_LANDING = `${MODULE_NAME}/CREATE_LANDING`
const UPDATE_LANDING = `${MODULE_NAME}/UPDATE_LANDING`

export const resetGoogleLandingsAction = (): IAction<never> => ({
  type: RESET_LANDINGS,
})

export const setGoogleLandingsSearchStringAction = (
  payload: string,
): IAction<string> => ({
  type: SET_SEARCH_STRING,
  payload,
})

export function fetchGoogleLandingsAction(
  isShouldReset = false,
): TAppActionThunk<any> {
  return async (dispatch, getState) => {
    const state = getState()
    const currentSearchString = selectGoogleLandingsSearchString(state)
    const searchString = isShouldReset ? '' : currentSearchString

    dispatch(startFetching(FETCH_LANDINGS))

    const response = await googleLandingsApi.fetchLandings(searchString)

    if (response.success && response.data) {
      dispatch({
        type: SET_LANDINGS,
        payload: {
          landings: response.data.data,
          landingsCount: response.data.meta?.count,
        },
      })

      if (isShouldReset) {
        dispatch(setGoogleLandingsSearchStringAction(searchString))
      }
    } else {
      message.error('Something went wrong!')
    }

    dispatch(stopFetching(FETCH_LANDINGS))
  }
}

export function createGoogleLandingAction(
  payload: ICreateGoogleLanding,
  succesfulCallback?: () => void,
): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(CREATE_LANDING))

    const response = await googleLandingsApi.createLanding(payload)

    if (response.success && response.data) {
      dispatch(fetchGoogleLandingsAction(true))
      succesfulCallback && succesfulCallback()
      message.success('Landing was created!')
    } else {
      message.error('Something went wrong!')
    }

    dispatch(stopFetching(CREATE_LANDING))
  }
}

export function updateGoogleLandingAction(
  payload: IUpdateGoogleLanding,
  succesfulCallback?: () => void,
): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(UPDATE_LANDING))

    const response = await googleLandingsApi.updateLanding(payload)

    if (response.success && response.data) {
      dispatch(fetchGoogleLandingsAction())
      succesfulCallback && succesfulCallback()
      message.success('Landing was updated!')
    } else {
      message.error('Something went wrong!')
    }

    dispatch(stopFetching(UPDATE_LANDING))
  }
}
