import { antd } from '@gismart/ui.library/core'
import { MaterialIcon } from '@gismart/ui.library/core/components'
import { Color } from '@gismart/ui.library/core/constants'
import { CopyableText } from 'components/CopyableText'
import { ExtendedText } from 'components/ExtendedText'
import { StyledListCard } from 'components/style'
import { getPreviewLinksFromVariants } from 'helpers/getPreviewLinksFromVariants'
import { ICustomLanding } from 'models/landings.custom.model'
import React, { useMemo } from 'react'

const { Row, Divider, Button } = antd

type TProps = {
  landing: ICustomLanding
  handleEdit: (id: number) => void
}

export const LandingsCard: React.FC<TProps> = ({
  landing: { id, title, variants, link },
  handleEdit,
}) => {
  const oneLinkExtendedValues = useMemo(
    () =>
      Object.entries(variants)
        .filter(([, value]) => value)
        .map(([key, { oneLink }]) => ({
          key,
          value: oneLink,
          href: oneLink,
        })),
    [variants],
  )
  const pixelIdExtendedValues = useMemo(
    () =>
      Object.entries(variants)
        .filter(([, value]) => value)
        .map(([key, { pixelId }]) => ({
          key,
          value: pixelId,
        })),
    [variants],
  )
  const previewExtendedValues = useMemo(
    () =>
      getPreviewLinksFromVariants({
        variants,
        previewLink: link,
      }),
    [variants, link],
  )
  const existingVariantsValues = useMemo(
    () => Object.values(variants).filter(Boolean),
    [variants],
  )

  return (
    <StyledListCard.Card
      hoverable
      actions={[
        <Button type="link" onClick={() => handleEdit(id)}>
          <MaterialIcon type="cog" color={Color.PRIMARY} />
          <span>Edit Landing</span>
        </Button>,
      ]}
    >
      <Row wrap={false} align="middle">
        <StyledListCard.Title ellipsis={{ tooltip: true }}>
          {title}
        </StyledListCard.Title>
      </Row>
      <Divider />
      <Row wrap={false}>
        <StyledListCard.Label>One Link:</StyledListCard.Label>
        {existingVariantsValues.length < 2 ? (
          <CopyableText href={existingVariantsValues[0]?.oneLink} />
        ) : (
          <ExtendedText values={oneLinkExtendedValues}>
            <CopyableText />
          </ExtendedText>
        )}
      </Row>
      <Row wrap={false}>
        <StyledListCard.Label>Pixel ID:</StyledListCard.Label>
        {existingVariantsValues.length < 2 ? (
          <CopyableText isCopyable>
            {existingVariantsValues[0]?.pixelId}
          </CopyableText>
        ) : (
          <ExtendedText values={pixelIdExtendedValues}>
            <CopyableText />
          </ExtendedText>
        )}
      </Row>
      <Row wrap={false}>
        <StyledListCard.Label underline>Preview Link:</StyledListCard.Label>
        {existingVariantsValues.length < 2 ? (
          <CopyableText href={previewExtendedValues[0].href} />
        ) : (
          <ExtendedText values={previewExtendedValues}>
            <CopyableText />
          </ExtendedText>
        )}
      </Row>
    </StyledListCard.Card>
  )
}
