import React, { useEffect, useCallback } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { antd } from '@gismart/ui.library/core'
import { PAGE_SIZE_OPTIONS } from 'root-constants'
import { goTo } from 'route-history'
import { AddButton } from 'components/AddButton'
import { getPaginationAfterChange } from 'helpers/getPaginationAfterChange'
import { ActionButtons } from './ActionButtons'
import { ExperimentListItem } from './ExperimentListItem'
import { Filters } from './Filters'
import { TStateProps, TDispatchProps } from './Container'
import { StyledList } from './style'

const { Empty, Pagination } = antd

type TProps = TStateProps & TDispatchProps

export const ExperimentsListComponent: React.FC<TProps> = ({
  experiments,
  filters,
  experimentsCount,
  fetchExperiments,
  resetExperiments,
  publishExperiment,
  finishExperiment,
  updateFilters,
  resetFilters,
}) => {
  const { url } = useRouteMatch()
  const { applicationId }: Record<string, string> = useParams()
  // const [filters, setFilters] = useState({})

  useEffect(() => {
    fetchExperiments(+applicationId, filters)
  }, [applicationId, fetchExperiments, filters])

  useEffect(
    () => () => {
      resetExperiments()
      resetFilters()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleAdd = useCallback(() => {
    goTo(`${url}/new`)
  }, [url])

  const handleEdit = useCallback(
    (id) => {
      goTo(`${url}/${id}`)
    },
    [url],
  )

  const handleViewStatistic = useCallback(
    (id) => {
      goTo(`${url}/${id}/statistic`)
    },
    [url],
  )

  const handleFilter = useCallback(
    (newFilters) => {
      updateFilters({
        ...filters,
        ...newFilters,
        page: 1,
      })
    },
    [filters, updateFilters],
  )

  const handlePaginationChange = useCallback(
    (updatedPage, updatedLimit) => {
      updateFilters({
        ...filters,
        ...getPaginationAfterChange({
          updatedPage,
          updatedLimit,
          currentLimit: filters.limit,
        }),
      })
    },
    [filters, updateFilters],
  )
  return (
    <>
      <Filters onFilter={handleFilter} />
      <StyledList
        data={experiments}
        renderActions={(experiment) => [
          <ActionButtons
            experiment={experiment}
            onEdit={handleEdit}
            onViewStatistic={handleViewStatistic}
            onFinish={finishExperiment}
            onPublish={publishExperiment}
          />,
        ]}
        renderItem={(experiment) => (
          <ExperimentListItem experiment={experiment} />
        )}
        renderAddButton={() => <AddButton onClick={handleAdd} />}
      />
      {experiments.length ? (
        <Pagination
          showSizeChanger
          total={experimentsCount}
          current={filters.page}
          pageSize={filters.limit}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onChange={handlePaginationChange}
          style={{
            textAlign: 'center',
            padding: '20px',
          }}
        />
      ) : (
        <Empty style={{ paddingBottom: '25px' }} />
      )}
    </>
  )
}
